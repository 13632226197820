import { ApplicationRef, Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;

declare let LANG: any;
declare let LG: any;

@Component({
  selector: 'app-confirmacion-reserva',
  templateUrl: './confirmacion-reserva.component.html',
  styleUrls: ['./confirmacion-reserva.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmacionReservaComponent implements OnInit {
  public estilo:string = (this.globals.GetQueryString('estilo') ? this.globals.GetQueryString('estilo') : '');
  public bgcolor:string = (this.globals.GetQueryString('bgcolor') ? this.globals.GetQueryString('bgcolor') : '');
  public LG:any = LG;
  public data:any = {};
  public redsysparameters:string = '';
  public redsysorderid:string = '';
  public orderid:string = '';
  public token:string = '';
  public CompraBono:boolean = false;

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Confirmación de reserva');
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.2)',
      imageResizeFactor: 0.5,
      text: 'Cargando datos ...'
    });

    LANG = this.globals.GetQueryString('lang');
    if (!LANG || LANG == '') LANG = 'es';

    //Redsys
    this.redsysparameters = this.globals.GetQueryString('Ds_MerchantParameters');
    if (this.redsysparameters && this.redsysparameters != '') {
      this.data.isredsys = true;
      let params = JSON.parse(atob(this.redsysparameters));
      this.data.redsys_order = params.Ds_Order;
      this.globals.Api('/pub-redsys-ok', {ds_merchant_parameters: this.globals.GetQueryString('Ds_MerchantParameters')}).subscribe(data => {
        if (!data || data.error) {
          $.LoadingOverlay("hide", true);
          this.ngZone.run(() => this.router.navigateByUrl('/reserva-error?Ds_MerchantParameters='+this.globals.GetQueryString('Ds_MerchantParameters'))).then();
          return;
        }
        this.CargarDatos();
      }, error => {
        $.LoadingOverlay("hide", true);
        this.ngZone.run(() => this.router.navigateByUrl('/reserva-error?Ds_MerchantParameters='+this.globals.GetQueryString('Ds_MerchantParameters'))).then();
      });
    }
    this.redsysorderid = this.globals.GetQueryString('redsysorderid');
    if (this.redsysorderid && this.redsysorderid != '') {
      this.data.isredsys = true;
      this.globals.Api('/pub-redsys-ok-orderid', {orderid: this.redsysorderid}).subscribe(data => {
        if (!data || data.error) {
          $.LoadingOverlay("hide", true);
          this.ngZone.run(() => this.router.navigateByUrl('/reserva-error?redsysorderid='+this.redsysorderid)).then();
          return;
        }
        this.CargarDatos();
      }, error => {
        $.LoadingOverlay("hide", true);
        this.ngZone.run(() => this.router.navigateByUrl('/reserva-error?redsysorderid='+this.redsysorderid)).then();
      });
    }
    //Paypal
    this.orderid = this.globals.GetQueryString('orderid');
    if (this.orderid && this.orderid != '') {
      this.data.paypal_order = this.orderid;
      this.globals.Api('/pub-paypal-ok', {orderid: this.orderid}).subscribe(data => {
        // console.log(data);
        if (!data || data.error) {
          $.LoadingOverlay("hide", true);
          this.ngZone.run(() => this.router.navigateByUrl('/reserva-error?orderid='+this.orderid)).then();
          return;
        }
        this.CargarDatos();
      }, error => {
        $.LoadingOverlay("hide", true);
        this.ngZone.run(() => this.router.navigateByUrl('/reserva-error?orderid='+this.orderid)).then();
      });
    }
    //Token
    this.token = this.globals.GetQueryString('token');
    if (this.token && this.token != '') {
      this.CargarDatos();
    }
    this.IframePostMessages();
    window.onresize = () => {
      this.IframePostMessages();
    };
    setTimeout(() => {
      function inIframe() {
        try {return window.self !== window.top; } catch (e) {return true;}
      }
      if (inIframe()) {
        var styleSheet = document.createElement("style");
        let css = `body {overflow:hidden}`;
        css = css.replace(/(\r\n|\n|\r)/gm, "");
        styleSheet.innerText = css;
        document.body.appendChild(styleSheet);
      }
      if (this.estilo == 'oscuro') {
        document.body.classList.add('oscuro');
      }
      if (this.bgcolor && this.bgcolor != '') {
        var styleSheet = document.createElement("style");
        let css = `
          body {--color-bg: #${this.bgcolor};}
          .response-reserva { background-color: var(--color-bg); }
        `;
        css = css.replace(/(\r\n|\n|\r)/gm, "");
        styleSheet.innerText = css;
        document.body.appendChild(styleSheet);
      }
    }, 100);
  }

  IframePostMessages() {
    setTimeout(() => {
      window.parent.postMessage({
        url: window.location.href,
        height: $('.response-reserva').height()
      }, '*');
      window.postMessage({
        url: window.location.href,
        height: $('.response-reserva').height()
      }, '*');
    }, 100);
  }
  CargarDatos() {
    let tabla = '/pub-get-reserva';
    if (this.data.redsys_order && this.data.redsys_order.indexOf('B-') != -1) tabla = '/pub-get-reserva-bono';
    if (this.redsysorderid && this.redsysorderid.indexOf('B-') != -1) tabla = '/pub-get-reserva-bono';
    if (this.orderid && this.orderid.indexOf('B-') != -1) tabla = '/pub-get-reserva-bono';
    if (this.token && this.token.indexOf('B-') != -1) tabla = '/pub-get-reserva-bono';
    if (tabla == '/pub-get-reserva-bono') this.CompraBono = true;
    if (this.redsysparameters && this.redsysparameters != '') {
      this.globals.Api(tabla, {redsys_order: this.data.redsys_order}).subscribe(data => {
        $.LoadingOverlay("hide", true);
        if (!data) return;
        this.data = data;
        this.data.isredsys = true;
        this.appref.tick();
        if (!this.globals.cliente) this.globals.cliente = {id: data.id_cliente};
        this.globals.CargarColorCliente();
        this.IframePostMessages();
      }, error => {
        $.LoadingOverlay("hide", true);
      });
    }
    if (this.redsysorderid && this.redsysorderid != '') {
      this.globals.Api(tabla, {redsys_order: this.redsysorderid}).subscribe(data => {
        $.LoadingOverlay("hide", true);
        if (!data) return;
        this.data = data;
        this.data.isredsys = false;
        this.appref.tick();
        if (!this.globals.cliente) this.globals.cliente = {id: data.id_cliente};
        this.globals.CargarColorCliente();
        this.IframePostMessages();
      }, error => {
        $.LoadingOverlay("hide", true);
      });
    }
    if (this.orderid && this.orderid != '') {
      this.globals.Api(tabla, {paypal_order: this.data.paypal_order}).subscribe(data => {
        $.LoadingOverlay("hide", true);
        if (!data) return;
        this.data = data;
        this.data.isredsys = false;
        this.appref.tick();
        if (!this.globals.cliente) this.globals.cliente = {id: data.id_cliente};
        this.globals.CargarColorCliente();
        this.IframePostMessages();
      }, error => {
        $.LoadingOverlay("hide", true);
      });
    }
    if (this.token && this.token != '') {
      this.globals.Api(tabla, {reserva_token: this.token}).subscribe(data => {
        $.LoadingOverlay("hide", true);
        if (!data) return;
        this.data = data;
        this.data.isredsys = false;
        this.appref.tick();
        if (!this.globals.cliente) this.globals.cliente = {id: data.id_cliente};
        this.globals.CargarColorCliente();
        this.IframePostMessages();
      }, error => {
        $.LoadingOverlay("hide", true);
      });
    }
  }

}
