import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

declare let $: any;
declare let swal: any;
declare let moment: any;
declare let config: any;
declare let XLSX: any;
declare let jsPDF: any;

@Injectable()
export class Globals {
    public entornos:any = {
        dev: 'https://dev-spgplanner.pecesgordos.es/api',
        prod: 'https://spgplanner.pecesgordos.es/api'
    };
    public entornosurl:any = [
        'https://dev-spgplanner.pecesgordos.es/api',
        'https://spgplanner.pecesgordos.es/api'
    ];
    public api_url: string = (
        // this.entornosurl.find((el:any) => el.indexOf(window.location.host) != -1) ?
        // this.entornosurl.find((el:any) => el.indexOf(window.location.host) != -1) :
        this.entornos.dev
    );
    public token: string = '';
    public me:any = {};
    public passData:any = {};
    public config:any = {};
    public idioma:string = 'es';
    public cliente:any = null;
    public cmensajeswhatsapp:number = 0;
    public historico:any = null;

    constructor(private http: HttpClient) { }

    public options() : any {
        let headers:HttpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json;charset=utf-8')
        .set('Accept', 'application/json');
        return {headers: headers};
    }
    public Api(method:string, parameters:any = null) : Observable<any> {
        if (this.token && this.token != '') {
            if (parameters) parameters.token = this.token;
            if (!parameters) parameters = {token: this.token};
        }
        if (this.cliente && this.cliente.id) {
            if (parameters && !parameters.id_cliente) parameters.id_cliente = this.cliente.id;
            if (!parameters) parameters = {id_cliente: this.cliente.id};
        }
        parameters = JSON.stringify(parameters);
        return this.http.post(this.api_url+method, parameters, this.options());
    }
    public ApiDownload(method:string, parameters:any = null) : Observable<any> {
        if (this.token && this.token != '') {
            if (parameters) parameters.token = this.token;
            if (!parameters) parameters = {token: this.token};
        }
        parameters = JSON.stringify(parameters);
        let headers:HttpHeaders = new HttpHeaders()
        .set('Accept', 'application/json');
        return this.http.post(this.api_url+method, parameters, {headers: headers, responseType: 'blob'});
    }
    public Literal(key:string) : string {
        let value:string = '';
        if (window['literales'] && window['literales'][this.idioma]) {
            value = window['literales'][this.idioma][key];
        }
        return value;
    }

    public ValidateEmail(email:string) : boolean {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    public ValidatePassword(pass:string) : boolean {
        var letter = /[a-zA-Z]/;
        var number = /[0-9]/;
        var reg8caracteres = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/; //Minimum eight characters, at least one letter and one number
        var reg6caracteres = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/; //Minimum eight characters, at least one letter and one number
        var ok:boolean = true;
        if (pass.length < 4 || !reg6caracteres.test(pass)) ok = false;
        return ok;
    }
    public PostedDate(date:any) {
		var fecha = date.toString().split(' ')[0];
		var hora = date.toString().split(' ')[1];

		//var y = parseInt(fecha.split('/')[2]);
		//var m = parseInt(fecha.split('/')[1])-1;
		//var d = parseInt(fecha.split('/')[0]);
		var y = parseInt(fecha.split('-')[0]);
		var m = parseInt(fecha.split('-')[1])-1;
		var d = parseInt(fecha.split('-')[2]);
		var h = parseInt(hora.split(':')[0]);
		var min = parseInt(hora.split(':')[1]);
		var s = parseInt(hora.split(':')[2]);
		var formatedDate = new Date(y, m, d, h, min, s);
        var seconds = Math.floor((new Date() as any - (formatedDate as any)) / 1000);

        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {return interval + " años";}
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {return interval + " meses";}
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {return interval + " días";}
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {return interval + " horas";}
        interval = Math.floor(seconds / 60);
        if (interval > 1) {return interval + " minutos";}
        return Math.floor(seconds) + " segundos";
    }
    public PostedDateToday(fecha:any, fecha_default:any = '') {
        let today = moment().format('YYYY-MM-DD');
        fecha = moment(fecha);
        if (fecha.isSame(today)) {
            return 'HOY';
        }
        today = moment(today);
        let tomorrow = moment(today.add(1, 'day')).format('YYYY-MM-DD');
        if (fecha.isSame(tomorrow)) {
            return 'MAÑANA';
        }
        return fecha_default;
    }
    public DateString(date:string) {
        // return new Date(date).toLocaleDateString();
        return moment(date).format('DD/MM/YYYY');
    }
    public DateStringEN(date:string) {
        // return new Date(date).toLocaleDateString();
        return moment(date).format('YYYY-MM-DD');
    }
    public DateTimeString(date:string) {
        // return new Date(date).toLocaleTimeString();
        return moment(date).format('DD/MM/YYYY hh:mm:ss');
    }
    public isValidDate(date:string) {
        let matches = /^(\d+)[-\/](\d+)[-\/](\d+)$/.exec(date);
        if (matches == null) return false;
        let d = parseInt(matches[1]);
        let m = parseInt(matches[2]);
        let y = parseInt(matches[3]);
        // if (y.toString().length < 4) {
        //     y += 2000;
        // }
        if (y > 2100 || y < 1900) return false;
        let composedDate = new Date(y+'/'+m+'/'+d);
        return composedDate.getDate() == d && composedDate.getMonth()+1 == m && composedDate.getFullYear() == y;
    }
    public FloatES(valor, decimals = 2) {
        if (!valor) return '';
        valor = valor.toString().replace(',', '.');
        // return (parseFloat(valor) % 1 != 0 ? parseFloat(valor).toFixed(2).replace('.', ',') : parseFloat(valor).toString());
        if (parseFloat(valor) % 1 == 0) {
            valor = parseFloat(valor).toString();
        } else {
            valor = parseFloat(valor).toFixed(decimals).replace('.', ',');
        }
        if (valor.indexOf(',') == -1) {
            valor = valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else {
            valor = valor.split(',')[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+','+valor.split(',')[1];
        }
        return valor;
    }
    public GeneratePassword() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        retVal.toString().toUpperCase();
        return retVal;
    }
    public GenerateRandomString(length:number = 50) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    public CleanedString(cadena, spaceChar = '_') {
        var specialChars = "!@#$^&%*()+=-[]\/{}|:<>?,.";
        for (var i = 0; i < specialChars.length; i++) {
            cadena= cadena.replace(new RegExp("\\" + specialChars[i], 'gi'), '');
        }
        cadena = cadena.toLowerCase();
        cadena = cadena.replace(/ /g,spaceChar);
        cadena = cadena.replace(/á/gi,"a");
        cadena = cadena.replace(/é/gi,"e");
        cadena = cadena.replace(/í/gi,"i");
        cadena = cadena.replace(/ó/gi,"o");
        cadena = cadena.replace(/ú/gi,"u");
        cadena = cadena.replace(/ñ/gi,"n");
        cadena = cadena.replace(/º/gi,"");
        cadena = cadena.replace(/ª/gi,"");
        return cadena;
    }
    public TruncateString(cadena, length = 4) {
        return cadena.split(" ").splice(0, length).join(" ")+' ...';
    }
    public FileInput(accept:string = null, callback:any = null, error:any = null) : HTMLInputElement {
		var input = document.createElement('input');
		input.type = 'file';
		if (accept != null) input.accept = accept;
		input.onchange = event => {
            // if (accept != 'image/*') {
            // }
            if (input.files[0].size / 1024 / 1024 > 8) {
                input.value = '';
                swal('Tamaño excedido!', 'El tamaño para los archivos seleccionados debe ser inferior a 2 MB', 'warning');
                if (error != null) error();
                return;
            }
			let image = URL.createObjectURL(input.files[0]);
            event['imagen'] = image;
            if (callback != null) callback(event);
		}
		return input;
    }
    public Base64Image(input:HTMLInputElement, callback:any, maxsize:number = 800) : void {
		if (input == null) return;
		if (callback == null) return;
		if (input.files.length == 0) {
            callback(null);
            return;
        }

        var calculateAspectRatioFit = function(srcWidth, srcHeight, maxWidth, maxHeight) {
            var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
            return {
                ratio: ratio,
                width: srcWidth * ratio,
                height: srcHeight * ratio
            };
        };

		var FR = new FileReader();
		FR.onload = e => {
            let canvas = document.createElement("canvas");
            var context = canvas.getContext("2d");
            let randstring = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            let img = $("<img id='temp-img-base64-"+randstring+"'>");
            img.bind('load', function() {
                var size = calculateAspectRatioFit(this.width, this.height, maxsize, maxsize);
                canvas.width = size.width;
                canvas.height = size.height;
                context.scale(size.ratio, size.ratio);
                context.drawImage(this, 0, 0);
                // console.log(canvas.toDataURL());
                callback(canvas.toDataURL('image/jpeg', .85));
            });
            img.attr("src", FR.result);
		    //callback(FR.result);
		};
		FR.readAsDataURL(input.files[0]);
    }
    public Base64(input:HTMLInputElement, callback:any) : void {
		if (input == null) return;
		if (callback == null) return;
		if (input.files.length == 0) {
            callback(null);
            return;
        }
		var FR = new FileReader();
		FR.onload = e => {
		    callback(FR.result);
		};
		FR.readAsDataURL(input.files[0]);
    }
    public GridOrderJS(callback:any = null) {
        $(document).ready(() => {
            $('th[order]').each(function() {
                let th = $(this);
                th.addClass('cursor');
                th.html(`<div style="white-space:nowrap;">${th.html()} <i style="opacity:0.4" class="fa fa-chevron-up"></i></div>`);
                th[0].onclick = function() {
                    $('th[order!="'+$(this).attr('order')+'"]').each(function() {
                        $(this).attr('dir', 'asc');
                        if ($(this).find('i').length > 0) $(this).find('i')[0].className = 'fa fa-chevron-up';
                    });

                    let th = $(this);
                    if (th.attr('dir') == null || th.attr('dir') == 'asc') {th.attr('dir', 'desc');th.find('i')[0].className = 'fa fa-chevron-down';}
                    else {th.attr('dir', 'asc');th.find('i')[0].className = 'fa fa-chevron-up';}
                    let order = th.attr('order');
                    let dir = th.attr('dir');
                    if (callback) callback(order, dir);
                };
            });
        });
    }
    public CopyClipboard(str:string) {
      var el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
    public GetQueryString(name:string) {
        let url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    public SaveData(object:any, key:string = window.location.pathname) {
        localStorage.setItem(key, JSON.stringify(object));
    }
    public GetData(key:string = window.location.pathname) : any {
        let data = localStorage.getItem(key);
        if (data) data = JSON.parse(data);
        return data;
    }
    public RandomStr(len, arr) {
        let ans = '';
        for (let i = len; i > 0; i--) {
            ans +=
                arr[(Math.floor(Math.random() * arr.length))];
        }
        // console.log(ans);
        return ans;
    }

    public GetModulo(slug:string = '') {
        if (this.cliente) {
            if (!this.cliente.modulos) return;
            if (!this.cliente.modulos.length) return;
            return this.cliente.modulos.find(el => el.slug == slug);
        } else {
            if (!this.me) return;
            if (!this.me.modulos) return;
            if (!this.me.modulos.length) return;
            return this.me.modulos.find(el => el.slug == slug);
        }
    }
    public StripHTML(cadena:any) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = cadena;
        return tmp.textContent || tmp.innerText || "";
    }

    public ExportarDataExcel(titulo:string = 'datos', data:any, columnas:any = null) {
        if (!data) return;
        let wb = XLSX.utils.book_new();
        wb.Props = {
          Title: titulo.toString().toLowerCase(),
          Subject: titulo.toString().toLowerCase(),
          Author: "SPG Planner"
        };
        let hoja = titulo.toString().toLowerCase().substr(0, 20);
        wb.SheetNames.push(hoja);
        let ws_data:any = [];
        let cols:any = [];
        if (!columnas) ws_data.push( Object.keys(data[0]) );
        if (columnas) {
            for (let item of columnas) { cols.push(item.nombre); }
            ws_data.push(cols);
        }
        for (let item of data) {
            let row = [];
            for (var prop in item) { row.push(item[prop]); }
            ws_data.push(row);
        }

        var ws = XLSX.utils.aoa_to_sheet(ws_data);
        var wscols = [];
        if (columnas) for (let item of columnas) { wscols.push({wch: item.width}); }
        ws['!cols'] = wscols;
        wb.Sheets[hoja] = ws;
        var wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
        function s2ab(s:any) {
          var buf = new ArrayBuffer(s.length);
          var view = new Uint8Array(buf);
          for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        }
        // saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), exportName+'.xlsx');
        var blob = new Blob([s2ab(wbout)], {type: "octet/stream"});
        var url = window.URL.createObjectURL(blob);
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href",     url);
        downloadAnchorNode.setAttribute("download", titulo.toString().toLowerCase() + ".xlsx");
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        window.URL.revokeObjectURL(url);
        downloadAnchorNode.remove();
    }
    public ExportarDataCSV(titulo:string = 'datos', data:any, columnas:any = null) {
        let array:any = [Object.keys(data[0])].concat(data);
        array = array.map((it:any) => {
            return Object.values(it).toString()
        }).join('\n');

        function s2ab(s:any) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        var blob = new Blob([s2ab(array)], {type: "octet/stream"});
        var url = window.URL.createObjectURL(blob);
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href",     url);
        downloadAnchorNode.setAttribute("download", titulo.toString().toLowerCase() + ".csv");
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        window.URL.revokeObjectURL(url);
        downloadAnchorNode.remove();
    }
    public ExportarDataPDF(titulo:string = 'datos', imprimir:boolean = false, data:any = null) {
        function ArrToTable(data:any) {
            const keys = Object.keys(data[0]);
            // Build the table header
            const header = `<thead><tr>` + keys
            .map(key => `<th>${key}</th>`)
            .join('') + `</thead></tr>`;

            // Build the table body
            const body = `<tbody>` + data
            .map((row:any) => `<tr>${Object.values(row)
            .map(cell => `<td>${cell}</td>`)
            .join('')}</tr>`
            ).join('');

            // Build the final table
            const html = `
            ${header}
            ${body}
            `;
            let table = document.createElement('table');
            table.innerHTML = html;
            return table;
        }
        let doc = new jsPDF();
        let elem = document.querySelector('.table');
        if (data) {
            elem = ArrToTable(data);
        }
        let res = doc.autoTableHtmlToJson(elem);
        doc.autoTable(res.columns, res.data);
        if (imprimir) {
            doc.autoPrint();
            window.open(doc.output('bloburl'), '_blank');
        } else {
            doc.save(titulo.toString().toLowerCase()+'.pdf');
        }
    }
    public GetIDCliente() : string {
        let id_cliente:string = '';
        if (this.me && this.me.id_cliente) {
            id_cliente = this.me.id_cliente;
        }
        if (this.cliente && this.cliente.id) {
            id_cliente = this.cliente.id;
        }
        return id_cliente;
    }
    public GetLogoCliente() : string {
        let logo:string = '';
        if (this.me && this.me.cliente_logo) {
            logo = this.me.cliente_logo;
        }
        if (this.cliente && this.cliente.imagen) {
            logo = this.cliente.imagen;
        }
        if (!logo || logo == '' || logo == '0') logo = `https://pecesgordos.es/wp-content/uploads/2023/04/logo-pge-horizontal.png`;
        return logo;
    }
    public GetNombreCliente() : string {
        let nombre:string = '';
        if (!this.cliente) {
            if (this.config) nombre = this.config.nombre;
        }
        if (this.cliente) {
            if (this.cliente.config) nombre = this.cliente.config.nombre;
        }
        return nombre;
    }
    public GetConfigCliente() : any {
        let config:any = {};
        if (!this.cliente) {
            if (this.config) config = this.config;
        }
        if (this.cliente) {
            if (this.cliente.config) config = this.cliente.config;
        }
        return config;
    }
    public CargarColorCliente(id:any = null) : any {
        if (!id) id = this.GetIDCliente();
        if (!id || id == '') {
            if (document.head.querySelector('#esquema-cliente')) {
                document.head.querySelector('#esquema-cliente').remove();
            }
            return;
        }
        this.Api('/css-cliente', {id_cliente: id}).subscribe(data => {
            if (document.head.querySelector('#esquema-cliente')) {
                document.head.querySelector('#esquema-cliente').remove();
            }
            if (!data || !data.css || data.css == '') return;
            const style = document.createElement('style');
            style.id = 'esquema-cliente';
            style.textContent = data.css;
            document.head.append(style);
        });
    }
    public firstDateOfMonth(date = new Date()){
      return new Date(date.getFullYear(), date.getMonth(), 1);
    }
    public lastDateOfMonth(date = new Date()){
      return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
}