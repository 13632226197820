import { Component, OnInit, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';
import { Html5Qrcode } from 'src/assets/js/html5-qrcode-master/src';
// // To use Html5QrcodeScanner (more info below)
// import {Html5QrcodeScanner} from 'src\assets\js\html5-qrcode-master\minified\html5-qrcode.min.js';

// // To use Html5Qrcode (more info below)
// import {Html5Qrcode} from "html5-qrcode";

declare let $: any;
declare let swal: any;
declare let moment: any;

@Component({
  selector: 'app-backend-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  public version = '1.0.0';
  public aforo = [];
  public mesesAforo:any = [];
  public historicoReservas:any = [];
  public actividades:any = [];
  public idCalendario:any;
  public primeraCargaCalendario = true;
  public sesiones:any = [];
  public aforoDia:any = [];
  public idUsuario:any;
  public evento:any = {};
  public eventos:any = [];
  public dataReserva:any = {
    id_evento:    '',
    fecha:        '',
    sesion:       '',
    adultos:      0,
    ninios:       0,
    niniosGratis: 0,
    nombre:       '',
    apellidos:    '',
    email:        '',
    telefono:     '',
    precio:       0,
    tipoPago:     '',
    cli_provincia: 'Elige provincia'
  };
  public precioAdulto:any;
  public precioNinio:any;
  public precioCalculoAdulto:any = 0;
  public precioCalculoNinio:any = 0;
  public plazasDisponibles:any = 50;
  public colaboradoresHeader:any;
  public loadEventosComp:boolean = false;

  public html5QrCode:any;
  public num_reservas_dias_check = 0;

  public url_nueva_reserva:any = '';

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public sanitizer: DomSanitizer,
    public globals: Globals
    ) {
    $.PrimerosPasos = () => {
      $('.onboard-modal.show-on-load').modal('show');
      if ($('.onboard-modal .onboard-slider-container').length) {
        $('.onboard-modal .onboard-slider-container').slick({
          dots: true,
          infinite: false,
          adaptiveHeight: true,
          touchMove: true,
          swipe: true,
          slidesToShow: 1,
          prevArrow: '<button type="button" data-role="none" class="slick-prev">Anterior</button>',
          nextArrow: '<button type="button" data-role="none" class="slick-next">Siguiente</button>',
        });
        $('.onboard-modal').on('shown.bs.modal', function (e) {
          $('.onboard-modal .onboard-slider-container').slick('setPosition');
        });
      }
      this.globals.SaveData(true, 'onboarding');
    };
    $.NuevaReservaGeneral = (fecha:any = null) => {
      // $('#date-reservar-modal-reservas-hoy').val(moment().format('YYYY-MM-DD'));
      // if (fecha) $('#date-reservar-modal-reservas-hoy').val(moment(fecha).format('YYYY-MM-DD'));
      // $('#modal-reservas-hoy').modal('show');
      // this.loadEventos(fecha);
      if (this.globals.GetIDCliente()) {
        let param = '?modaladmin=true';
        if (this.globals.me.id_rol == '4'){
          param = '?modalcolaborador=true&idcolaborador='+this.globals.me.id;
        }
        if (fecha) param += '&fecha=' + fecha;
        this.url_nueva_reserva = this.sanitizer.bypassSecurityTrustResourceUrl(
          './nueva-reserva/' + this.globals.GetIDCliente() + param
        );
        $('#modal-nueva-reserva').modal('show');
      }
    };
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        window.parent.postMessage({
          url: window.location.href,
          height: $(window).height()
        }, '*');
        window.postMessage({
          url: window.location.href,
          height: $(window).height()
        }, '*');
      }
    });
    window.parent.postMessage({
      url: window.location.href,
      height: $(window).height()
    }, '*');
    window.postMessage({
      url: window.location.href,
      height: $(window).height()
    }, '*');

    switch(this.globals.me.id_rol) {
      case 4:{
        $(document).ready(() => {
          if (!this.globals.GetData('onboarding')) {
            $.PrimerosPasos();
          }
        });
        this.dataReserva.id_colaborador = this.globals.me.id;
        this.dataReserva.tipo = 'Partner';
      } break;
    };

    window.addEventListener('message', function(event) {
      if (!event.data) return;
      if (typeof event.data == 'string') return;
      if (!event.data.height) event.data.height = 0;
      $('#modal-nueva-reserva .modal-body').css('height', (event.data.height + 10)+'px');
    });
  }

  ngOnInit(): void {
    $.checkpermission();
    //this.checkVersion();
    $('body').on('click', function (e) {
      if ($(e.target).hasClass('barra-evento-calendario')) return;
      if ($(e.target).hasClass('evento-calendario')) return;
      if ($(e.target).hasClass('evento-listado')) return;
      if ($(e.target).parents('.barra-evento-calendario').length) return;
      if ($(e.target).parents('.evento-calendario').length) return;
      if ($(e.target).parents('.evento-listado').length) return;
      if ($(e.target).parents('.popover.in').length === 0) {
        $('.popover').popover('hide');
        if ($.calevents) for (let item of $.calevents) item.data.popover = false;
      }
    });
    if(!this.globals.cliente && this.globals.me.id_rol == 0){
      clearInterval(this.globals.historico);
      return;
    }
    this.globals.Api('/ranking-colaboradores').subscribe(data => {
      if (!data) return;
      this.colaboradoresHeader = data;
    });
    this.globals.CargarColorCliente();
    $(document).off('focus', '*[contenteditable]').on('focus', '*[contenteditable]', (e:any) => {
      window.setTimeout(() => {
        let sel:any, range:any;
        if (window.getSelection && document.createRange) {
            range = document.createRange();
            range.selectNodeContents(e.target);
            sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if ((<any>document.body).createTextRange) {
            range = (<any>document.body).createTextRange();
            range.moveToElementText(e.target);
            range.select();
        }
      }, 1);
    });
  }
  Logout() {
    if ($.Logout) $.Logout();
  }
  callCameraQr(){
    Html5Qrcode.getCameras().then(devices => {
      /**
       * devices would be an array of objects of type:
       * { id: "id", label: "label" }
       */
      if (devices && devices.length) {
        var cameraId = devices[0].id;
        // .. use this to start scanning.
        this.html5QrCode = new Html5Qrcode(/* element id */ "reader");
        $('#close-lector-qr').show();
        window.scrollTo(0, 0);
        $('body').addClass('stop-scrolling');

        this.html5QrCode.start(
          { facingMode: "environment"
          },
          {
            fps: 60,    // Optional, frame per seconds for qr code scanning
            qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
          },
          (decodedText, decodedResult) => {
            // do something when code is read
              // let dataQr = decodedText.split(':');
              // if(dataQr.length != 2){
              //   swal('Error', 'QR no válido', 'error');
              // }else{

              // }
              this.globals.Api('/check-reserva-qr', {token_reserva: decodedText}).subscribe(data => {
                if (!data || data.error) {
                  swal('Error', 'Reserva ya validada', 'error');return;
                }
                swal('Correcto','Reserva válida para '+data.value.cli_nombre+' '+data.value.cli_apellidos,'success');
              }, error => {
                swal('Error', 'Se ha producido un error conectando con el servidor', 'error');
              });
              this.html5QrCode.stop().then((ignore) => {
                // QR Code scanning is stopped.
              }).catch((err) => {
                // Stop failed, handle it.
              });
              $('body').removeClass('stop-scrolling');
              $('#close-lector-qr').hide();
          },
          (errorMessage) => {
            // parse error, ignore it.
          })
        .catch((err) => {
          // Start failed, handle it.
        });
      }else{
        swal('Error', 'Este dispositivo no tiene cámara', 'error');
      }
    }).catch(err => {
      // handle err
        swal('Error', 'Este dispositivo no tiene cámara', 'error');
    });

  }
  cerrarLector(){
    this.html5QrCode.stop().then((ignore) => {
      // QR Code scanning is stopped.
    }).catch((err) => {
      // Stop failed, handle it.
      swal('Error', 'Error al detener el lector', 'error');
    });
    $('#close-lector-qr').hide();
    $('body').removeClass('stop-scrolling');
  }
  ClickReservaExterna() {
    if (window.location.pathname.indexOf('eventos') != -1) {
      this.globals.Api('/eve-eventos', {limit: 1}).subscribe(data => {
        if (!data || !data.length) return;
        let url:string = '/nueva-reserva-evento/' + this.globals.GetIDCliente() + '/' + data[0].id;
        let a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.click();
      });
      return;
    }
    if (window.location.pathname.indexOf('actividades') != -1) {
      this.globals.Api('/eve-actividades', {limit: 1}).subscribe(data => {
        if (!data || !data.length) return;
        let url:string = '/nueva-reserva/' + this.globals.GetIDCliente() + '/' + data[0].id;
        let a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.click();
      });
      return;
    }
    let url:string = '/nueva-reserva/' + this.globals.GetIDCliente();
    let a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();
  }

  ComprobarGuardarReserva(){
    if(
      this.dataReserva.fecha == null || this.dataReserva.fecha == '' || this.dataReserva.fecha == '0000-00-00' ||
      this.dataReserva.sesion == null || this.dataReserva.sesion == '' ||
      this.dataReserva.adulto == 0 || this.dataReserva.precio == 0 ||
      this.dataReserva.nombre == '' || this.dataReserva.apellidos == '' || this.dataReserva.email == '' || this.dataReserva.telefono == ''
    ){
      swal('Error', 'Rellena todos los datos', 'error');return;
      //setInterval
    }
    let aforoCheck = (this.dataReserva.adultos + this.dataReserva.ninios + this.dataReserva.niniosGratis);
    let that = this;
    // this.globals.Api('/comprobar-aforo',{idEvento: this.idCalendario, aforoCheck: aforoCheck, dia: this.dataReserva.fecha, sesion: this.dataReserva.sesion}).subscribe(data => {
    //   // console.log(data);
    //   if (!data || data.error) {
    //     swal('Error', 'Error al validar el aforo', 'error');return;
    //   }
    //   if(data.results == 0){
    //     swal('Error', 'Error al validar el aforo, revísalo en el calendario', 'warning');return;
    //   }
    //   that.GuardarReserva();
    // });
    this.GuardarReserva();
  }
  GuardarReserva(){
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.7)',
      imageResizeFactor: 0.5,
      text: 'Guardando reserva ...'
    });
    this.dataReserva.cli_tipo_pago = $('input[type="radio"][name="pago"]:checked').val();
    this.dataReserva.cli_tipo_pago_slug = $('input[type="radio"][name="pago"]:checked').attr('id');
    this.dataReserva.tipoPago = $('input[type="radio"][name="pago"]:checked').attr('id');
    this.dataReserva.pagos = [{
      cli_tipo_pago: this.dataReserva.cli_tipo_pago,
      cli_tipo_pago_slug: this.dataReserva.cli_tipo_pago_slug
    }];
    this.globals.Api('/pub-reservar-actividad',this.dataReserva).subscribe(data => {
      // console.log(data);
      if (!data || data.error) {
        $.LoadingOverlay("hide", true);
        swal('Error', 'Error al hacer la reserva', 'error');return;
      }
      $.LoadingOverlay("hide", true);
      swal('Guardado', '¡Reserva hecha con éxito!', 'success');
      $('#modal-reservas-hoy').modal('hide');
      $.CargarReservasFecha(this.dataReserva.fecha);
      this.cerrarModalCalendario();
    });
  }
  cerrarModalCalendario(){
    this.dataReserva = {
      id_evento:    '',
      fecha:        '',
      sesion:       '',
      adultos:      0,
      ninios:       0,
      niniosGratis: 0,
      nombre:       '',
      apellidos:    '',
      email:        '',
      telefono:     '',
      precio:       0,
      tipoPago:     1
    };
    $('#pago-en-mano').click();
    this.sesiones = [];
    this.AnteriorModal();
  }
  AnteriorModal(){
    $('#paso-1-modal-reservas-hoy').fadeIn();
    $('#paso-2-modal-reservas-hoy').hide();
  }
  SiguienteModal(){
    // console.log('Click siguiente');
    switch(this.globals.me.id_rol) {
      case 4:{
        this.dataReserva.id_colaborador = this.globals.me.id;
        this.dataReserva.tipo = 'Partner';
      } break;
    };
    if(this.num_reservas_dias_check == 0){
      let contTotalPlazas = this.dataReserva.adultos + this.dataReserva.ninios;
      if(this.evento.aforo_minimo > contTotalPlazas){
        swal('¡Atención!', 'Debes de seleccionar un mínimo de '+this.evento.aforo_minimo+' plazas para esta sesión', 'warning');
        return;
      }
    }
    var radiosSeleccion = $('.radios-sesion');
    var checkRadio = false;
    for(var i = 0; i < radiosSeleccion.length; i++){
      if($(radiosSeleccion[i]).is(':checked')){
        checkRadio = true;
      }
    }
    if(!checkRadio){
      swal('¡Atención!', 'Debe seleccionar una sesión', 'warning');
      return;
    }
    if(this.dataReserva.adultos == 0){
      swal('¡Atención!', 'Los niños menores deben de ir acompañados al menos de un adulto', 'warning');
      return;
    }
    let aforoCheck = (this.dataReserva.adultos + this.dataReserva.ninios + this.dataReserva.niniosGratis);
    // this.globals.Api('/comprobar-aforo',{idEvento: this.idCalendario, aforoCheck: aforoCheck, dia: this.dataReserva.fecha, sesion: this.dataReserva.sesion}).subscribe(data => {
    //   // console.log(data);
    //   if (!data || data.error) {
    //     swal('Error', 'Error al validar el aforo', 'error');return;
    //   }
    //   if(data.results == 0){
    //     swal('Error', 'Error al validar el aforo, revísalo en el calendario', 'warning');return;
    //   }
    //   $('#paso-1-modal-reservas-hoy').hide();
    //   $('#paso-2-modal-reservas-hoy').fadeIn();
    // });

    $('#paso-1-modal-reservas-hoy').hide();
    $('#paso-2-modal-reservas-hoy').fadeIn();
  }
  calcularPrecioModal(){
    this.precioCalculoAdulto = this.dataReserva.adultos * this.precioAdulto;
    this.precioCalculoNinio = this.dataReserva.ninios * this.precioNinio;
    this.dataReserva.precio = this.precioCalculoAdulto + this.precioCalculoNinio;
  }
  SelectReservaProveedorHeader(event){
    //console.log(event);
    if($('#select-colaboradores-reserva-admin-hoy').val() != 'admin'){
      this.dataReserva.id_colaborador = $('#select-colaboradores-reserva-admin-hoy').val();
      this.dataReserva.tipo = 'Partner';
    }else{
      this.dataReserva.id_colaborador = null;
      this.dataReserva.tipo = 'Admin';
    }
  }
  SeleccionarActividadGen(){
    this.dataReserva.id_evento = $('#select-actividad-reservar-gen').val();
  }
  loadEventos(date){
    this.dataReserva.id_colaborador = null;
    this.dataReserva.tipo = 'Admin';
    let params = {
      fecha: date,
      fecha_format: this.globals.DateString(new Date(date).toDateString()),
      fecha_format_en: this.globals.DateStringEN(new Date(date).toDateString())
    };
    this.dataReserva.fecha = params.fecha_format_en;
    this.globals.Api('/eve-eventos').subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar datos evento', 'error');return;
      }
      this.eventos = data;
      $('#select-actividad-reservar-gen').off('change');
      let that = this;
      $('#select-actividad-reservar-gen').change(function(){
        let idEventoSelect = $(this).val();
        that.dataReserva.ninios = 0;
        that.dataReserva.adultos = 0;
        that.precioCalculoNinio = 0;
        that.precioCalculoAdulto = 0;
        that.calcularPrecioModal();
        $('#custom-menos-adulto').addClass('disabled-custom-input');
        $('#custom-menos-ninio').addClass('disabled-custom-input');
        if(idEventoSelect == 'default') return;
        that.globals.Api('/eve-eventos',{id: idEventoSelect}).subscribe(data => {
          if (!data || data.error) {
            swal('Error', 'Error al cargar datos evento', 'error');return;
          }
          that.evento = data[0];
          if(that.evento.vehiculo){
            // Cálculo disponibilidad condicionante vehículos
            that.sesiones = null;
            that.globals.Api('/eve-eventos-sesiones-vehiculos-v2',{id: that.evento.id, fecha: params.fecha_format_en}).subscribe(data => {
              if (!data || data.error) {
                swal('Error', 'Error al cargar datos sesiones', 'error');return;
              }
              if(data[1].length > 0){
                // console.log(data[1]);
                that.sesiones = data[1];
                that.precioAdulto = data[1][0].precio_adultos;
                that.precioNinio = data[1][0].precio_ninios;

              }else{
                // console.log(data[0]);
                that.sesiones = data[0];
                that.precioAdulto = that.evento.precio;
                that.precioNinio = that.evento.precio_ninios;
              }
              var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
              var d = new Date(params.fecha_format_en);
              var dayName = days[d.getDay()];
              that.globals.Api('/check-dia-disponible-v2',{id: that.evento.id, dia: dayName, fecha: params.fecha_format_en}).subscribe(data => {
                if (!data || data.error) {
                  swal('Error', 'Error al cargar datos sesiones', 'error');return;
                }
                if(data[0].count == 0 && data[0].dias_especial_disponible == 0){
                  that.sesiones = null;
                }else{
                  // console.log(that.sesiones);
                  for(let i = 0; i <= that.sesiones.length; i++){
                    // console.log(that.sesiones[i].sesion);
                    let sesion = that.sesiones[i].sesion;
                    // console.log(sesion);
                    that.globals.Api('/eve-eventos-check-vehiculos-v2',{id: that.evento.id, fecha: params.fecha_format_en, sesion: sesion}).subscribe(data => {
                      if (!data || data.error) {
                        swal('Error', 'Error al verificar vehículos', 'error');return;
                      }
                      // console.log(data);
                      let plazasDisponibles = 0;
                      data.forEach(vehiculo => {
                        plazasDisponibles += vehiculo.plazas_disponibles;
                      });
                      // console.log(plazasDisponibles);
                      if(that.sesiones[i].aforo_restante > plazasDisponibles){
                        that.sesiones[i].aforo_restante = plazasDisponibles;
                      }
                      that.sesiones[i].aforo_restante = that.sesiones[i].aforo_restante + that.sesiones[i].plazas_adicionales;
                      if(that.sesiones[i].aforo_restante < 0) that.sesiones[i].aforo_restante = 0;
                    });
                  }
                }
              });
            });
          }else{
            // Cálculo disponibilidad normal sin vehículos
            that.globals.Api('/eve-eventos-sesiones-v2',{id: that.evento.id, fecha: params.fecha_format_en}).subscribe(data => {
              if (!data || data.error) {
                swal('Error', 'Error al cargar datos sesiones', 'error');return;
              }
              if(data[1].length > 0){
                // console.log(data[1]);
                that.sesiones = data[1];
                that.precioAdulto = data[1][0].precio_adultos;
                that.precioNinio = data[1][0].precio_ninios;
                console.log(data[1][0]);
                that.calcularPrecioModal();
              }else{
                // console.log(data[0]);
                that.sesiones = data[0];
                that.precioAdulto = that.evento.precio;
                that.precioNinio = that.evento.precio_ninios;
                that.calcularPrecioModal();
              }
              var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
              var d = new Date(params.fecha_format_en);
              var dayName = days[d.getDay()];
              that.globals.Api('/check-dia-disponible-v2',{id: that.evento.id, dia: dayName, fecha: params.fecha_format_en}).subscribe(data => {
                if (!data || data.error) {
                  swal('Error', 'Error al cargar datos sesiones', 'error');return;
                }
                if(data[0].count == 0 && data[0].dias_especial_disponible == 0){
                  that.sesiones = null;
                }else{
                  for(let i = 0; i <= that.sesiones.length; i++){
                    if(that.sesiones[i].plazas_adicionales){
                      // console.log('if');
                    }else{
                      that.sesiones[i].plazas_adicionales = 0;
                    }
                    that.sesiones[i].aforo_restante = that.sesiones[i].aforo_restante + that.sesiones[i].plazas_adicionales;
                    if(that.sesiones[i].aforo_restante < 0) that.sesiones[i].aforo_restante = 0;
                  }
                }
              });
            });
          }
        });
      });
      setTimeout(() => {
        if(this.loadEventosComp)return;
        $('#custom-menos-adulto-gen').click(function(){
        if(!$(this).hasClass('disabled-custom-input')){
          that.dataReserva.adultos--;
          if(that.dataReserva.adultos == 0){
            $(this).addClass('disabled-custom-input');
          }
          that.calcularPrecioModal();
          if(that.plazasDisponibles > (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
            $('#custom-mas-adulto-gen').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gen').removeClass('disabled-custom-input');
            $('#custom-mas-ninio-gratis-gen').removeClass('disabled-custom-input');
          }
        }
      });
        $('#custom-mas-adulto-gen').click(function(){
          if(!$(this).hasClass('disabled-custom-input')){
            that.dataReserva.adultos++;
            $('#custom-menos-adulto-gen').removeClass('disabled-custom-input');
            that.calcularPrecioModal();
            if(that.plazasDisponibles <= (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
              $(this).addClass('disabled-custom-input');
              $('#custom-mas-ninio-gen').addClass('disabled-custom-input');
              $('#custom-mas-ninio-gratis-gen').addClass('disabled-custom-input');
            }else{
              $(this).removeClass('disabled-custom-input');
              $('#custom-mas-ninio-gen').removeClass('disabled-custom-input');
              $('#custom-mas-ninio-gratis-gen').removeClass('disabled-custom-input');
            }
          }
        });
        $(document).on('click', '#custom-menos-ninio-gen', function(event) {
          if(!$(event.target).hasClass('disabled-custom-input')){
            that.dataReserva.ninios--;
            if(that.dataReserva.ninios == 0){
              $(event.target).addClass('disabled-custom-input');
            }
            that.calcularPrecioModal();
            if(that.plazasDisponibles > (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
              $('#custom-mas-adulto').removeClass('disabled-custom-input');
              $('#custom-mas-ninio').removeClass('disabled-custom-input');
              $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
            }
          }
        });
        $(document).on('click', '#custom-mas-ninio-gen', function(event) {
          if(!$(event.target).hasClass('disabled-custom-input')){
            that.dataReserva.ninios++;
            $('#custom-menos-ninio').removeClass('disabled-custom-input');
            that.calcularPrecioModal();
            if(that.plazasDisponibles <= (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
              $(event.target).addClass('disabled-custom-input');
              $('#custom-mas-adulto').addClass('disabled-custom-input');
              $('#custom-mas-ninio-gratis').addClass('disabled-custom-input');
            }else{
              $(event.target).removeClass('disabled-custom-input');
              $('#custom-mas-ninio-gratis').removeClass('disabled-custom-input');
              $('#custom-mas-adulto').removeClass('disabled-custom-input');
            }
          }
        });
        $('#custom-menos-ninio-gratis-gen').click(function(){
          if(!$(this).hasClass('disabled-custom-input')){
            that.dataReserva.niniosGratis--;
            if(that.dataReserva.niniosGratis == 0){
              $(this).addClass('disabled-custom-input');
            }
            if(that.plazasDisponibles > (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
              $('#custom-mas-adulto-gen').removeClass('disabled-custom-input');
              $('#custom-mas-ninio-gen').removeClass('disabled-custom-input');
              $('#custom-mas-ninio-gratis-gen').removeClass('disabled-custom-input');
            }
          }
        });
        $('#custom-mas-ninio-gratis-gen').click(function(){
          if(!$(this).hasClass('disabled-custom-input')){
            that.dataReserva.niniosGratis++;
            $('#custom-menos-ninio-gratis-gen').removeClass('disabled-custom-input');
            if(that.plazasDisponibles <= (that.dataReserva.adultos + that.dataReserva.ninios + that.dataReserva.niniosGratis)){
              $(this).addClass('disabled-custom-input');
              $('#custom-mas-ninio-gen').addClass('disabled-custom-input');
              $('#custom-mas-adulto-gen').addClass('disabled-custom-input');
            }else{
              $(this).removeClass('disabled-custom-input');
              $('#custom-mas-ninio-gen').removeClass('disabled-custom-input');
              $('#custom-mas-adulto-gen').removeClass('disabled-custom-input');
            }
          }
        });
        this.loadEventosComp = true;
      }, 1000);
    });
  }
  setSesionCheckGen(sesion, aforo_restante){
    this.globals.Api('/pub-check-hay-reservas',{id_evento: this.dataReserva.id_evento, sesion: sesion, fecha: this.dataReserva.fecha}).subscribe(data => {
      if(!data || data == ''){
        return;
      }
      // console.log(data);
      this.num_reservas_dias_check = data.num_reservas;
    });
    this.dataReserva.sesion = sesion;
    this.plazasDisponibles = parseInt(aforo_restante);
    if(this.plazasDisponibles < (this.dataReserva.adultos + this.dataReserva.ninios + this.dataReserva.niniosGratis)){
      this.dataReserva.adultos = 0;
      this.dataReserva.ninios = 0;
      this.dataReserva.niniosGratis = 0;
      this.precioCalculoAdulto = 0;
      this.precioCalculoNinio = 0;
      this.calcularPrecioModal();
      $('#custom-mas-adulto-gen').removeClass('disabled-custom-input');
      $('#custom-mas-ninio-gen').removeClass('disabled-custom-input');
      $('#custom-mas-ninio-gratis-gen').removeClass('disabled-custom-input');
      $('#custom-menos-adulto-gen').addClass('disabled-custom-input');
      $('#custom-menos-ninio-gen').addClass('disabled-custom-input');
      $('#custom-menos-ninio-gratis-gen').addClass('disabled-custom-input');
    }else{
      $('#custom-mas-adulto-gen').removeClass('disabled-custom-input');
      $('#custom-mas-ninio-gen').removeClass('disabled-custom-input');
      $('#custom-mas-ninio-gratis-gen').removeClass('disabled-custom-input');
    }
    if(this.plazasDisponibles == 0){
      $('#custom-mas-adulto-gen').addClass('disabled-custom-input');
      $('#custom-mas-ninio-gen').addClass('disabled-custom-input');
      $('#custom-mas-ninio-gratis-gen').addClass('disabled-custom-input');
      $('#custom-menos-adulto-gen').addClass('disabled-custom-input');
      $('#custom-menos-ninio-gen').addClass('disabled-custom-input');
      $('#custom-menos-ninio-gratis-gen').addClass('disabled-custom-input');
    }
  }
  checkVersion(){
    // console.log('check-version');
    this.globals.Api('/check-version').subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al checkear version', 'error');return;
      }
      let version = data.version;
      if(!localStorage.getItem('version')){
        // console.log('no existe');
        localStorage.setItem('version', JSON.stringify(version));
      }else{
        // console.log('existe');
        if(localStorage.getItem('version') != '"'+version+'"'){
          // console.log('no coincide');
          // console.log(localStorage.getItem('version'));
          // console.log(this.version);
          swal({
            title: "¡Se acaba de actualizar el panel! Debes iniciar sesión de nuevo",
            type: "warning",
            showCancelButton: false,
            confirmButtonClass: "btn-primary",
            confirmButtonText: "Ok",
            cancelButtonText: false,
            closeOnConfirm: true
          },() => {
            $.ajax({
              url: "",
              context: document.body,
              success: function(s,x){

                  $('html[manifest=saveappoffline.appcache]').attr('content', '');
                      $(this).html(s);
              }
            });
            localStorage.clear();
            window.location.reload();
          });
        }
      }
    });
  }
  setTipoPago(idEstado: any){
    this.dataReserva.id_estado = idEstado;
    // console.log(this.dataReserva.id_estado);
  }
  CerrarVistaCliente() {
    this.globals.cliente = null;
    clearInterval(this.globals.historico);
    localStorage.removeItem('cliente-activo');
    localStorage.removeItem('config');
    this.globals.Api('/configuracion-public', {id_cliente: (this.globals.cliente ? this.globals.cliente.id: null)}).subscribe(data => {
      if (!data || data.error) return;
      this.globals.config = data;
      localStorage.setItem('config', JSON.stringify(this.globals.config));
      $.CargarConfiguracionGlobal();
    });
    this.ngZone.run(() => this.router.navigateByUrl('/clientes-spa')).then();
  }

}
