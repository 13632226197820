import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './backend/login/login.component';
import { DashboardSuperadminComponent } from './backend/dashboard-superadmin/dashboard-superadmin.component';
import { DashboardComponent } from './backend/dashboard/dashboard.component';
import { UsuariosComponent } from './backend/usuarios/usuarios.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Globals } from './classes/globals.class';
import { MenuComponent } from './backend/controls/menu/menu.component';
import { HeaderComponent } from './backend/controls/header/header.component';
import { ConfigAppComponent } from './backend/config-app/config-app.component';
import { EditUsuarioComponent } from './backend/usuarios/edit-usuario/edit-usuario.component';
import { ActividadesComponent } from './backend/actividades/actividades.component';
import { EventosComponent } from './backend/eventos/eventos.component';
import { ConfiguracionComponent } from './backend/configuracion/configuracion.component';
import { ReservasComponent } from './backend/reservas/reservas.component';
import { MiPerfilComponent } from './backend/mi-perfil/mi-perfil.component';
import { EditActividadComponent } from './backend/actividades/edit-actividad/edit-actividad.component';
import { EditEventoComponent } from './backend/eventos/edit-evento/edit-evento.component';
import { EditReservaComponent } from './backend/reservas/edit-reserva/edit-reserva.component';
import { TemporadasComponent } from './backend/temporadas/temporadas.component';
import { ModalReservaComponent } from './backend/controls/modal-reserva/modal-reserva.component';
import { ConfirmacionReservaComponent } from './frontend/confirmacion-reserva/confirmacion-reserva.component';
import { ReservaCanceladaComponent } from './frontend/reserva-cancelada/reserva-cancelada.component';
import { ReservaErrorComponent } from './frontend/reserva-error/reserva-error.component';
import { CuponesComponent } from './backend/cupones/cupones.component';
import { EditCuponComponent } from './backend/cupones/edit-cupon/edit-cupon.component';
import { ConfirmacionReseniaComponent } from './frontend/confirmacion-resenia/confirmacion-resenia.component';
import { MensajesComponent } from './backend/mensajes/mensajes.component';
import { NuevaReservaActividadComponent } from './frontend/nueva-reserva-actividad/nueva-reserva-actividad.component';
import { VehiculosComponent } from './backend/vehiculos/vehiculos.component';
import { EditVehiculoComponent } from './backend/vehiculos/edit-vehiculo/edit-vehiculo.component';
import { PagoAnticipoComponent } from './frontend/pago-anticipo/pago-anticipo.component';
import { BonosRegalosComponent } from './backend/bonos-regalos/bonos-regalos.component';
import { EditBonoRegaloComponent } from './backend/bonos-regalos/edit-bono-regalo/edit-bono-regalo.component';
import { ClientesSpaComponent } from './backend/superadmin/clientes-spa/clientes-spa.component';
import { EditClienteSpaComponent } from './backend/superadmin/clientes-spa/edit-cliente-spa/edit-cliente-spa.component';
import { NuevaReservaComponent } from './frontend/nueva-reserva/nueva-reserva.component';
import { FooterComponent } from './backend/controls/footer/footer.component';
import { NuevaReservaEventoComponent } from './frontend/nueva-reserva-evento/nueva-reserva-evento.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardSuperadminComponent,
    DashboardComponent,
    MenuComponent,
    HeaderComponent,
    FooterComponent,
    ConfigAppComponent,
    UsuariosComponent,
    EditUsuarioComponent,
    ActividadesComponent,
    EventosComponent,
    ConfiguracionComponent,
    ReservasComponent,
    MiPerfilComponent,
    EditActividadComponent,
    EditEventoComponent,
    EditReservaComponent,
    TemporadasComponent,
    ModalReservaComponent,
    NuevaReservaComponent,
    ConfirmacionReservaComponent,
    ReservaCanceladaComponent,
    ReservaErrorComponent,
    CuponesComponent,
    EditCuponComponent,
    ConfirmacionReseniaComponent,
    MensajesComponent,
    NuevaReservaActividadComponent,
    NuevaReservaEventoComponent,
    VehiculosComponent,
    EditVehiculoComponent,
    PagoAnticipoComponent,
    BonosRegalosComponent,
    EditBonoRegaloComponent,
    ClientesSpaComponent,
    EditClienteSpaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [Globals],
  bootstrap: [AppComponent]
})
export class AppModule { }

