<div id="content-iframe-frontend">
    <!-- <div class="modal inmodal fade" id="modal-reservar-calendar" tabindex="-1" role="dialog"> -->
    <div id="content-paso-1" class="">
        <div id="content-buttons-reserva-regalo-movil" class="col-lg-12 col-sm-12 col-xs-12 margin-bottom-10 hide-tablet-desktop" style="margin-top: 20px;">
            <div class="row">
                <div class="col-lg-4 col-sm-4 col-xs-4">
                    <button id="btn-reservar-movil" class="selected btn btn-reserva-regalo" (click)="setReservaRegaloCanjear('reservar')"><i class="fa fa-ticket"></i> Reservar</button>
                </div>
                <div class="col-lg-4 col-sm-4 col-xs-4">
                    <button id="btn-regalar-movil" class="btn btn-reserva-regalo" (click)="setReservaRegaloCanjear('regalar')"><i class="fa fa-gift"></i> Regalar</button>
                </div>
                <div class="col-lg-4 col-sm-4 col-xs-4">
                    <button id="btn-canjear-movil" class="btn btn-reserva-regalo" (click)="setReservaRegaloCanjear('canjear')"><i class="fa fa-retweet"></i> Canjear</button>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-xs-12">
            <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop label-calendar-title" *ngIf="reservar || canjear"><i class="fa fa-calendar"></i> {{LG('Selecciona fecha')}}</label>
            <hr class="separador-general hide-tablet-desktop custom-form-reservar" *ngIf="reservar || canjear">
            <div class="row display-flex width-fit-auto hide-movil">
                <!-- <div class="col-lg-3"><h3 class="title-paso">1. Fecha y entradas</h3></div>
                <div class="col-lg-3"><h3 class="title-paso">2. Tus datos</h3></div>
                <div class="col-lg-3"><h3 class="title-paso">3. Reserva completada</h3></div> -->
                <h3 class="title-paso active">1. {{LG('Elige fecha')}}</h3>
                <h3 class="title-paso">2. {{LG('Actividad y plazas')}}</h3>
                <h3 class="title-paso">3. {{LG('Tus datos')}}</h3>
                <h3 class="title-paso">4. {{LG('Pagar')}}</h3>
            </div>
            <div id="content-calendar-data" class="row">
                <div class="col-lg-12">
                    <div id="content-buttons-reserva-regalo" class="col-lg-12 col-sm-12 col-xs-12 margin-bottom-10" *ngIf="bono_regalo">
                        <div class="row">
                            <div class="col-lg-4 col-sm-4 col-xs-4 hide-movil">
                                <button id="btn-reservar" class="selected btn-reserva-regalo" (click)="setReservaRegaloCanjear('reservar')"><i class="fa fa-ticket"></i> Reservar</button>
                            </div>
                            <div class="col-lg-4 col-sm-4 col-xs-4 hide-movil">
                                <button id="btn-regalar" class="btn-reserva-regalo" (click)="setReservaRegaloCanjear('regalar')"><i class="fa fa-gift"></i> Regalar</button>
                            </div>
                            <div class="col-lg-4 col-sm-4 col-xs-4 hide-movil">
                                <button id="btn-canjear" class="btn-reserva-regalo" (click)="setReservaRegaloCanjear('canjear')"><i class="fa fa-retweet"></i> Canjear bono</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-xs-12">
                    <div id="calendar" class="calendario calendario-dashboard calendario-front"></div>
                    <div id="content-legend" style="display: flex; margin-top: 18px;    width: 370px;" *ngIf="reservar || canjear">
                        <div class="legend" style="width: 100px; display: flex;">
                            <div style="width: 10px; background-color: #5da84e; border-radius: 50px; height: 10px;border: solid 1px white;"></div>
                            <div class="legend-text">Disponibles</div>
                        </div>
                        <div class="legend" style="width: 160px; display: flex;">
                            <div style="width: 10px; background-color: #e5b437; border-radius: 50px; height: 10px;border: solid 1px white;"></div>
                            <div class="legend-text">Poca disponibilidad</div>
                        </div>
                        <div class="legend" style="width: 100px; display: flex;">
                            <div style="width: 10px; background-color: #a30404; border-radius: 50px; height: 10px;border: solid 1px white;"></div>
                            <div class="legend-text">Agotadas</div>
                        </div>
                    </div>
                    <div id="content-imagen-regalo" class="row" *ngIf="regalar">
                        <div class="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                            <div id="background-ticket-reservar">
                                <img src="assets/ticket-bono-formulario.svg" alt="">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                            <p id="text-descripcion-regalo">Para regalar una actividad, no hace falta elegir una fecha, la fecha de la actividad se podrá elegir una vez se canjea el bono regalo</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer col-md-12 col-xs-12 padding-top-0">
            <!-- <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button> -->
            <button type="button" class="btn btn-primary" id="btn-siguiente" *ngIf="regalar" (click)="SiguienteModalPlazas()">{{LG('Siguiente')}} <i class="fa fa-arrow-right"></i></button>
        </div>
    </div>
    <div id="content-paso-2-plazas" class="" style="display: none;">
        <div class="row display-flex width-fit-auto hide-movil">
            <!-- <div class="col-lg-3"><h3 class="title-paso">1. Fecha y entradas</h3></div>
            <div class="col-lg-3"><h3 class="title-paso">2. Tus datos</h3></div>
            <div class="col-lg-3"><h3 class="title-paso">3. Reserva completada</h3></div> -->
            <h3 class="title-paso anterior">1. {{LG('Elige fecha')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
            <h3 class="title-paso active">2. {{LG('Actividad y plazas')}}</h3>
            <h3 class="title-paso">3. {{LG('Tus datos')}}</h3>
            <h3 class="title-paso">4. {{LG('Pagar')}}</h3>
        </div>
        <div class="clearfix"></div>
        <div id="content-entradas" class="col-lg-12 col-sm-12 col-xs-12">
            <div class="form-group row">
                <div class="col-lg-12 col-sm-12 col-xs-12 hide-tablet-desktop">
                    <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop label-calendar-title" *ngIf="reservar"><i class="fa fa-ticket"></i> {{LG('Actividad y plazas')}}</label>
                    <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop label-calendar-title" *ngIf="regalar"><i class="fa fa-ticket"></i> Actividad y plazas</label>
                    <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop label-calendar-title" *ngIf="canjear"><i class="fa fa-ticket"></i> Canjea el bono regalo</label>
                    <hr class="separador-general hide-tablet-desktop custom-form-reservar paso-2-hr">
                </div>
                <div class="col-lg-6 col-sm-6 col-xs-12">
                    <div class="row">
                        <div id="content-date-reserva" *ngIf="!regalar" class="display-flex flex-wrap col-lg-12 col-sm-12 col-xs-12">
                            <label class="col-lg-6 col-sm-6 col-xs-12 col-form-label label-modal-title hide-movil"><i class="icofont-calendar"></i> Fecha elegida</label>
                            <label class="col-lg-6 col-sm-12 col-xs-12 col-form-label hide-tablet-desktop" ><i class="icofont-direction-sign"></i> Fecha elegida</label>
                            <hr class="separador-general hide-tablet-desktop custom-form-reservar">
                            <div class="col-lg-6 col-sm-6 col-xs-12">
                                <input id="date-reservar" type="date" class="form-control" maxlength="5" style="width: fit-content;" disabled>
                            </div>
                        </div>
                        <div id="content-select-actividad" class="margin-top-10 display-flex col-lg-12 col-sm-12 col-xs-12" *ngIf="reservar || regalar">
                            <label class="col-lg-4 col-sm-12 col-xs-12 col-form-label hide-tablet-desktop" ><i class="icofont-direction-sign"></i> Elige actividad</label>
                            <hr class="separador-general hide-tablet-desktop custom-form-reservar">
                            <label class="col-lg-6 col-sm-6 col-xs-4 col-form-label label-modal-title hide-movil"><i class="icofont-direction-sign"></i> Elige actividad</label>
                            <div class="col-lg-6 col-sm-6 col-xs-8 p-r-0">
                                <select id="select-actividad-reservar" class="form-control" (change)="SeleccionarActividad()" (click)="CheckActividadSelect()">
                                    <option value="default">Elige una actividad</option>
                                    <option value="{{actividad.id}}" *ngFor="let actividad of actividades">{{actividad.nombre}}</option>
                                </select>
                            </div>
                        </div>
                        <div id="content-canjear" class="margin-top-10 col-lg-12 col-sm-12 col-xs-12" *ngIf="canjear">
                            <div class="form-group display-flex m-t-10 flex-wrap" style="align-items:center;">
                                <label class="col-lg-4 col-sm-12 col-xs-12 col-form-label hide-tablet-desktop" ><i class="icofont-direction-sign"></i> {{LG('Introduce tu código')}}</label>
                                <hr class="separador-general hide-tablet-desktop custom-form-reservar">
                                <label class="col-lg-3 col-sm-4 col-xs-4 hide-movil" style="flex-shrink: 0;margin:0;margin-right:20px;"><i class="fa fa fa-ticket"></i> {{LG('Introduce tu código')}}<span class="text-danger">*</span></label>
                                <input type="text" class="form-control inline-block col-lg-8 col-sm-8 col-xs-8" placeholder="{{LG('Introduce tu código')}}"
                                    [(ngModel)]="dataCanjearBono.codigo"
                                    maxlength="20"
                                    (input)="InputCodigoCanjear()"
                                    >
                            </div>
                            <div class="m-t-10" *ngIf="dataCanjearBono.codigo && dataCanjearBono.codigo.length > 0 && !dataCanjearBono.bono">
                                {{LG('Código no válido')}}
                            </div>
                        </div>
                        <div class="margin-top-10 display-flex col-lg-12 col-sm-12 col-xs-12" id="content-radio-reserva"
                            *ngIf="reservar || dataCanjearBono.selsesion" style="flex-wrap: wrap;">
                            <label class="col-lg-3 col-sm-3 col-form-label label-modal-title hide-tablet-desktop" ><i class="icofont-clock-time"></i> Elige una sesión</label>
                            <label class="col-lg-12 col-sm-12 col-xs-12 col-form-label label-modal-title hide-movil" ><i class="icofont-clock-time"></i> {{LG('Sesiones')}}</label>
                            <hr class="separador-general hide-tablet-desktop custom-form-reservar">
                            <ng-container *ngIf="sesiones && sesiones.length == 0 && !actividad.id">
                                <div class="col-sm-2 content-radio-sesion display-flex aviso-sesiones">
                                    *Elija una actividad para ver las sesiones
                                </div>
                            </ng-container>
                            <ng-container *ngIf="sesiones && sesiones.length == 0 && actividad.id">
                                <div class="content-radio-sesion display-flex aviso-sesiones" style="color: red; font-weight: 700;">
                                    *No hay sesiones disponibles para este día
                                </div>
                            </ng-container>
                            <div class="col-sm-2 content-radio-sesion display-flex p-r-0" *ngFor="let sesion of sesiones">
                                <input id="{{sesion.sesion}}" type="radio" class="form-control radios-sesion" value="{{sesion.sesion}}-{{sesion.aforo}}" (click)="setSesionCheck(sesion.sesion,sesion.aforo_restante,sesion.id)" name="sesion" style="width: fit-content; height: fit-content; margin-right: 5px;"><label for="{{sesion.sesion}}">{{sesion.sesion}}h</label>
                            </div>
                            <div *ngIf="dataCanjearBono && dataCanjearBono.mensajesesiones" class="content-radio-sesion aviso-sesiones" style="color: red; font-weight: 700;">
                                {{dataCanjearBono.mensajesesiones}}
                            </div>
                        </div>
                        <ng-container *ngIf="regalar">
                            <div style="margin-top: 15px;" class="col-lg-12 col-sm-12 col-xs-12"></div>
                        </ng-container>
                        <div class="margin-top-10 display-flex col-lg-12 col-sm-12 col-xs-12" id="content-radio-asientos" style="flex-wrap: wrap;">
                            <ng-container *ngIf="asientos.length > 0">
                                <label class="col-lg-3 col-sm-3 col-xs-12 col-form-label label-modal-title hide-tablet-desktop" ><i class="icofont-ticket"></i> Asientos</label>
                                <label class="col-lg-12 col-sm-12 col-xs-4 col-form-label label-modal-title hide-movil" ><i class="icofont-ticket"></i> Asientos</label>
                                <hr class="separador-general hide-tablet-desktop custom-form-reservar">
                                <div class="col-lg-12 col-sm-12 col-xs-12 row display-flex flex-wrap">
                                    <div class="display-flex p-l-15 content-radio-asiento p-r-0 {{asiento.activo ? 'active' : ''}}" *ngFor="let asiento of asientos">
                                        <input id="{{asiento.id}}" type="radio" class="form-control radios-asientos" value="{{asiento.id}}" (click)="setAsientoCheck(asiento)" name="asiento" style="width: fit-content; height: fit-content; margin-right: 10px;"><label for="{{asiento.id}}">{{asiento.nombre}}</label>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-xs-12">
                    <div class="row">
                        <div class="col-lg-12 col-sm-12 col-xs-12" id="content-radio-personas" *ngIf="reservar || regalar">
                            <ng-container *ngIf="tarifas.length == 0 && asientos.length == 0">
                                <div class="col-sm-2 content-radio-sesion display-flex aviso-sesiones">
                                    *Elija sesion para ver las tarifas
                                </div>
                            </ng-container>
                            <ng-container *ngIf="tarifas.length == 0 && asientos.length != 0">
                                <div class="col-sm-2 content-radio-sesion display-flex aviso-sesiones">
                                    *Elija sesion y asiento para ver las tarifas
                                </div>
                            </ng-container>
                            <ng-container *ngIf="tarifas.length > 0">
                                <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop" ><i class="fa fa-ticket"></i> Entradas</label>
                                <hr class="separador-general hide-tablet-desktop custom-form-reservar">
                                <div class="col-lg-12 margin-bottom-10" *ngFor="let tarifa of tarifas">
                                    <div class="row">
                                        <div class="col-lg-3 col-sm-6 col-xs-5 display-flex flex-wrap">
                                            <label class="col-form-label label-modal-title" ><i class="icofont-user-alt-1"></i> {{tarifa.nombre}}</label><br class="hide-movil">
                                            <ng-container *ngFor="let precio of tarifa.precio">
                                                <span class="precio-persona hide-movil" *ngIf="precio.sesion == sesionSelect">{{precio.precio}}€ <span *ngIf="plazasDisponibles == 0" style="font-size: 14px; color: red;">(Agotado)</span></span>
                                            </ng-container>
                                        </div>
                                        <div class="col-lg-3 col-sm-4 col-xs-3 no-padding-movil">
                                            <div class="display-flex">
                                                <ng-container *ngFor="let precio2 of tarifa.precio">
                                                    <i *ngIf="precio2.sesion == sesionSelect" id="menos-{{tarifa.id}}" class="icofont-minus-circle custom-menos disabled-custom-input custom-menos-icon" (click)="resCantidadTarifa(tarifa,precio2.precio)"></i>
                                                    <input *ngIf="precio2.sesion == sesionSelect" class="form-control custom-input-number" type="text" maxlength="2" [(ngModel)]="tarifa.cantidad" disabled>
                                                    <i *ngIf="precio2.sesion == sesionSelect" class="icofont-plus-circle custom-mas custom-mas-icon" (click)="sumCantidadTarifa(tarifa,precio2.precio)"></i>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-lg-1 col-sm-2 col-xs-1">
                                            <div class="row">
                                                <p class="precio-calculo precio-calulo-adulto">{{tarifa.total}}€</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="col-lg-12" *ngIf="permitirDescuentos && tarifas.length > 0">
                                <div class="row">
                                    <label class="col-lg-12">¿Hacer descuento?</label>
                                    <div class="col-lg-12">
                                        <select class="form-control" [(ngModel)]="tipoDescuento" (change)="calcularPrecioModal()">
                                            <option value="porcentaje">Porcentaje</option>
                                            <option value="fijo">Fijo en €</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-12">
                                        <input class="form-control" type="number" [(ngModel)]="descuento" (change)="calcularPrecioModal()">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">
                                    <div class="col-lg-2 col-xs-2">
                                    </div>
                                    <div class="col-lg-2 col-xs-2">
                                    </div>
                                    <div class="col-lg-8 col-xs-8 p-r-0">
                                        <p class="precio-calculo-total">{{LG('Total')}}: {{dataReserva.precio}} €</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="content-canjear-resumen" class="col-lg-12 col-sm-12 col-xs-12" *ngIf="canjear">
                    <hr class="separador-general hide-tablet-desktop custom-form-reservar">
                    <ul class="list-group" *ngIf="dataCanjearBono.bono">
                        <li class="list-group-item">
                            <span class="inline-block" style="width:100px;">{{LG('Actividad')}}: </span>
                            <b>{{dataCanjearBono.bono.actividad}}</b>
                        </li>
                        <li class="list-group-item">
                            <span class="inline-block" style="width:100px;">{{LG('Adultos')}}: </span>
                            <b>{{dataCanjearBono.bono.adultos}}</b>
                        </li>
                        <li class="list-group-item">
                            <span class="inline-block" style="width:100px;">{{LG('Niños')}}: </span>
                            <b>{{dataCanjearBono.bono.ninios}}</b>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="modal-footer col-md-12 col-xs-12">
            <!-- <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button> -->
            <button type="button" class="btn btn-white" id="btn-anterior" (click)="AnteriorModalInicial()"><i class="fa fa-arrow-left"></i> {{LG('Volver')}}</button>
            <button type="button" class="btn btn-primary" id="btn-siguiente" *ngIf="reservar" (click)="SiguienteModal()">{{LG('Siguiente')}} <i class="fa fa-arrow-right"></i></button>
            <button type="button" class="btn btn-primary" id="btn-siguiente" *ngIf="regalar" (click)="SiguienteModalRegalar()">{{LG('Siguiente')}} <i class="fa fa-arrow-right"></i></button>
            <button type="button" class="btn btn-primary" id="btn-siguiente" *ngIf="canjear" (click)="SiguienteModalCanjear()">{{LG('Siguiente')}} <i class="fa fa-arrow-right"></i></button>
        </div>
    </div>
    <div id="content-paso-2" class="" style="display: none;">
        <div class="col-md-12 col-xs-12">
            <div class="row display-flex width-fit-auto hide-movil">
                <!-- <div class="col-lg-3"><h3 class="title-paso">1. Fecha y entradas</h3></div>
                <div class="col-lg-3"><h3 class="title-paso">2. Tus datos</h3></div>
                <div class="col-lg-3"><h3 class="title-paso">3. Reserva completada</h3></div> -->
                <h3 class="title-paso anterior">1. {{LG('Elige fecha')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                <h3 class="title-paso anterior">2. {{LG('Actividad y plazas')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                <h3 class="title-paso active">3. {{LG('Tus datos')}}</h3>
                <h3 class="title-paso">4. {{LG('Pagar')}}</h3>
            </div>
            <div id="content-resumen-form" class="">
                <div id="content-datos" class="">
                    <h4 class="hide-movil hide">{{LG('Datos_cliente')}}</h4>
                    <h4 class="hide-tablet-desktop" style="margin-bottom: 5px;" *ngIf="reservar || canjear"><i class="fa fa-pencil"></i> {{LG('Datos_rellena_cliente')}}</h4>
                    <h4 style="margin-bottom: 5px;" *ngIf="regalar"><i class="fa fa-pencil"></i> Rellena los datos del destinatario</h4>
                    <hr class="separador-general hide-tablet-desktop custom-form-reservar" style="margin-left: 0px !important;">
                    <div class="form-group datos-personales row m-b-5">
                        <div class="col-lg-6 col-sm-6 col-xs-12 m-b-10">
                            <label class="hide">{{LG('Nombre')}}<span class="text-danger">*</span></label>
                            <input class="form-control" type="text" placeholder="{{LG('Nombre')}}" [(ngModel)]="dataReserva.nombre">
                        </div>
                        <div class="col-lg-6 col-sm-6 col-xs-12 m-b-10">
                            <label class="hide">{{LG('Apellidos')}}<span class="text-danger">*</span></label>
                            <input class="form-control" type="text" placeholder="{{LG('Apellidos')}}" [(ngModel)]="dataReserva.apellidos">
                        </div>
                        <div class="col-lg-6 col-sm-6 col-xs-12 m-b-10">
                            <label class="hide">{{LG('Teléfono')}}<span class="text-danger">*</span></label>
                            <input class="form-control" type="tel" placeholder="{{LG('Teléfono')}}" [(ngModel)]="dataReserva.telefono">
                        </div>
                        <div class="col-lg-6 col-sm-6 col-xs-12 m-b-10">
                            <label class="hide">{{LG('Email')}}<span class="text-danger">*</span></label>
                            <input class="form-control" type="mail" placeholder="{{LG('Email')}}" [(ngModel)]="dataReserva.email">
                        </div>
                        <div class="col-lg-6 col-sm-6 col-xs-6 m-b-10">
                            <label class="hide">Código postal</label>
                            <input class="form-control" type="number" placeholder="Código postal" [(ngModel)]="dataReserva.cli_codpostal" (change)="SetLocalidad()">
                        </div>
                        <div class="col-lg-6 col-sm-6 col-xs-6 m-b-10 input-cupon" *ngIf="reservar">
                            <label class="hide">{{LG('Cupón de descuento')}}</label>
                            <input class="form-control" type="text" placeholder="{{LG('Cupón de descuento')}}" [(ngModel)]="dataReserva.codigo_cupon" (input)="ComprobarCupon()"
                                [disabled]="dataReserva.email && dataReserva.email.trim() != '' ? false : true">
                            <i class="fa fa-check text-success icon-cupon" *ngIf="dataReserva.mostracuponok"></i>
                            <i class="fa fa-times text-danger icon-cupon" *ngIf="dataReserva.mostracuponko"></i>
                        </div>
                        <div class="col-lg-12 col-xs-12 m-b-10" *ngIf="reservar || canjear">
                            <label class="hide">Notas</label>
                            <textarea class="form-control" placeholder="Notas o comentarios" [(ngModel)]="dataReserva.cli_notas" style="width: 100%;"></textarea>
                        </div>
                        <div class="col-lg-12 col-xs-12 m-b-10" *ngIf="regalar">
                            <label class="hide">Dedicatoria (Opcional)</label>
                            <textarea class="form-control" placeholder="Dedicatoria (Opcional)" [(ngModel)]="dataPersonaRegala.dedicatoria" style="width: 100%;"></textarea>
                        </div>
                        <!-- <div class="col-lg-3 col-xs-3 margin-bottom-10 margin-top-26">
                            <input class="form-control" id="pago-tarjeta" class="fit-content" type="radio" name="pago"><label style="margin-left: 10px;" for="pago-tarjeta">Tarjeta</label>
                        </div>
                        <div class="col-lg-3 col-xs-3 margin-bottom-10 margin-top-26">
                            <input class="form-control" id="pago-paypal" class="fit-content" type="radio" name="pago"><label style="margin-left: 10px;" for="pago-paypal">Paypal</label>
                        </div> -->
                    </div>
                    <h4 style="margin-bottom: 5px;" *ngIf="regalar"><i class="fa fa-pencil"></i> Rellena tus datos</h4>
                    <hr class="separador-general hide-tablet-desktop custom-form-reservar" style="margin-left: 0px !important;" *ngIf="regalar">
                    <div class="form-group row m-b-5" *ngIf="regalar">
                        <div class="col-lg-6 col-sm-6 col-xs-12 m-b-10">
                            <label class="hide">{{LG('Nombre')}}<span class="text-danger">*</span></label>
                            <input class="form-control" type="text" placeholder="{{LG('Nombre')}}" [(ngModel)]="dataPersonaRegala.nombre">
                        </div>
                        <div class="col-lg-6 col-sm-6 col-xs-12 m-b-10">
                            <label class="hide">{{LG('Apellidos')}}<span class="text-danger">*</span></label>
                            <input class="form-control" type="text" placeholder="{{LG('Apellidos')}}" [(ngModel)]="dataPersonaRegala.apellidos">
                        </div>
                        <div class="col-lg-6 col-sm-6 col-xs-12 m-b-10">
                            <label class="hide">{{LG('Teléfono')}}<span class="text-danger">*</span></label>
                            <input class="form-control" type="tel" placeholder="{{LG('Teléfono')}}" [(ngModel)]="dataPersonaRegala.telefono">
                        </div>
                        <div class="col-lg-6 col-sm-6 col-xs-12 m-b-10">
                            <label class="hide">{{LG('Email')}}<span class="text-danger">*</span></label>
                            <input class="form-control" type="mail" placeholder="{{LG('Email')}}" [(ngModel)]="dataPersonaRegala.email">
                        </div>
                        <div class="col-lg-6 col-sm-6 col-xs-12 m-b-10">
                            <label class="hide">Código postal</label>
                            <input class="form-control" type="number" placeholder="Código postal" [(ngModel)]="dataPersonaRegala.codpostal" (change)="SetLocalidadRegalo()">
                        </div>
                        <!-- <div class="col-lg-3 col-xs-3 margin-bottom-10 margin-top-26">
                            <input class="form-control" id="pago-tarjeta" class="fit-content" type="radio" name="pago"><label style="margin-left: 10px;" for="pago-tarjeta">Tarjeta</label>
                        </div>
                        <div class="col-lg-3 col-xs-3 margin-bottom-10 margin-top-26">
                            <input class="form-control" id="pago-paypal" class="fit-content" type="radio" name="pago"><label style="margin-left: 10px;" for="pago-paypal">Paypal</label>
                        </div> -->
                    </div>
                    <div class="clearfix"></div>
                    <div class="row">
                        <!-- <div class="col-lg-6 col-sm-6 col-xs-12">
                            <div class="" *ngIf="!canjear">
                                <label class="col-form-label label-modal-title hide-tablet-desktop label-calendar-title" style="margin-top: 0px;"><i class="icofont-credit-card"></i> Tipos de pago</label>
                                <hr class="separador-general hide-tablet-desktop custom-form-reservar" style="margin-left: 0px !important;">
                                <div *ngFor="let item of formas_pago" class="inline-block m-r-20 formas-pago">
                                    <input id="{{item.slug}}" class="fit-content" type="radio" name="pago" value="{{item.forma_pago}}" (click)="SetFormaPago(item)">
                                    <label style="margin-left: 10px;" for="{{item.slug}}" (click)="SetFormaPago(item)">{{item.forma_pago}}
                                        <i class="icofont-paypal-alt" *ngIf="item.forma_pago == 'Paypal'"></i>
                                        <i class="icofont-credit-card" *ngIf="item.forma_pago == 'Tarjeta bancaria' || item.forma_pago == 'Tarjeta de crédito'"></i>
                                        <i class="icofont-stripe-alt" *ngIf="item.forma_pago == 'Stripe'"></i>
                                    </label>
                                </div>
                            </div>
                            <div id="stripe-form" class="m-t-10 m-b-10" style="display:none;" *ngIf="!canjear">
                                <div id="card-element"></div>
                                <div id="card-errors" role="alert"></div>
                            </div>
                        </div> -->
                        <div class="col-lg-12 col-sm-12 col-xs-12">
                            <div id="content-politicas-privacidad" style="display:flex;align-items:center;">
                                <input id="check-politicas-privacidad" type="checkbox" style="width: fit-content;margin: 0;margin-right: 10px;"/>
                                <label for="check-politicas-privacidad" style="margin: 0 !important;">{{LG('Acepto las')}} <a href="{{url_politicas_privacidad.url_politica_privacidad}}" target="_blank"
                                    style="font-weight: 700;">{{LG('política de privacidad')}}</a> y las <a href="{{url_politicas_privacidad.url_politica_cancelacion}}" target="_blank">políticas de cancelación</a>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div id="content-resumen" class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                    <h4 class="hide-movil">{{LG('Resumen de reserva')}}</h4>
                    <div class="row">
                        <div class="col-lg-12 col-xs-12" *ngIf="reservar || canjear">
                            <p class="no-margin-bottom">{{LG('Fecha')}}: <span>{{dataReserva.fecha}}</span></p>
                        </div>
                        <div class="col-lg-12 col-xs-12" *ngIf="reservar || canjear">
                            <p class="no-margin-bottom">{{LG('Sesión')}}: <span>{{dataReserva.sesion}}</span></p>
                        </div>
                        <div class="col-lg-12 col-xs-12" *ngFor="let tarifa of tarifas">
                            <p class="no-margin-bottom">{{tarifa.nombre}}: <span>{{tarifa.cantidad}}</span></p>
                        </div>
                        <ng-container *ngIf="!dataReserva.cupon">
                            <div class="col-lg-12 col-xs-12">
                                <p class="no-margin-bottom">{{LG('Precio')}}: <span>{{dataReserva.precio}}€</span></p>
                                <ng-container *ngIf="!regalar">
                                    <p class="no-margin-bottom" *ngIf="anticipo.porcentaje_anticipo != 100">*Al hacer la reserva, usted abona el {{anticipo.porcentaje_anticipo}}%. El reseto se abona el día de la actividad</p>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="dataReserva.cupon">
                            <div class="col-lg-12 col-xs-12">
                                <p class="no-margin-bottom">{{LG('Precio')}}: <span>{{dataReserva.precio_original}}€</span></p>
                            </div>
                            <div class="col-lg-12 col-xs-12">
                                <p class="no-margin-bottom">{{LG('Descuento')}}: -<span>{{dataReserva.cupon.descuento}}</span></p>
                                <p class="no-margin-bottom">{{LG('Total')}}: <span>{{globals.FloatES(dataReserva.total)}}€</span></p>
                            </div>
                        </ng-container>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="modal-footer col-md-12 col-xs-12">
            <!-- <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button> -->
            <button type="button" class="btn btn-white" id="btn-anterior" (click)="AnteriorModal2()"><i class="fa fa-arrow-left"></i> {{LG('Volver')}}</button>
            <button type="button" class="btn btn-primary" id="btn-guardar-reserva" *ngIf="reservar || regalar" (click)="SiguienteModalPago()">{{LG('Siguiente')}} <i class="fa fa-arrow-right"></i></button>
        </div>
    </div>
    <div id="content-paso-3-pago" class="" style="display: none;">
        <div class="col-md-12 col-xs-12">
            <label class="col-lg-4 col-form-label label-modal-title hide-tablet-desktop label-calendar-title"><i class="fa fa-ticket"></i> Haz el pago</label>
            <hr class="separador-general hide-tablet-desktop custom-form-reservar paso-2-hr">
            <div class="row display-flex width-fit-auto hide-movil">
                <!-- <div class="col-lg-3"><h3 class="title-paso">1. Fecha y entradas</h3></div>
                <div class="col-lg-3"><h3 class="title-paso">2. Tus datos</h3></div>
                <div class="col-lg-3"><h3 class="title-paso">3. Reserva completada</h3></div> -->
                <h3 class="title-paso anterior">1. {{LG('Elige fecha')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                <h3 class="title-paso anterior">2. {{LG('Actividad y plazas')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                <h3 class="title-paso anterior">3. {{LG('Tus datos')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                <h3 class="title-paso active">4. {{LG('Pagar')}}</h3>
            </div>
            <div id="content-resumen-pay" class="col-lg-12 col-sm-12 col-xs-12">
                <label class="col-form-label label-modal-title hide-tablet-desktop label-calendar-title" style="margin-top: 0px;"><i class="icofont-file-document"></i> Resumen</label>
                <hr class="separador-general hide-tablet-desktop custom-form-reservar" style="margin-left: 0px !important;">
                <div id="content-resumen" class="col-lg-7 col-md-7 col-sm-7 col-xs-7" *ngIf="dataReserva && dataReserva.total">
                    <h4 class="hide-movil">{{LG('Resumen de reserva')}}</h4>
                    <div class="row">
                        <div class="col-lg-12 col-xs-12" *ngIf="reservar || canjear">
                            <p class="no-margin-bottom">{{LG('Fecha')}}: <span>{{dataReserva.fecha}}</span></p>
                        </div>
                        <div class="col-lg-12 col-xs-12" *ngIf="reservar || canjear">
                            <p class="no-margin-bottom">{{LG('Sesión')}}: <span>{{dataReserva.sesion}}</span></p>
                        </div>
                        <div class="col-lg-12 col-xs-12" *ngFor="let tarifa of tarifas">
                            <p class="no-margin-bottom">{{tarifa.nombre}}: <span>{{tarifa.cantidad}}</span></p>
                        </div>
                        <ng-container *ngIf="!dataReserva.tienecupon">
                            <div class="col-lg-12 col-xs-12">
                                <p class="no-margin-bottom">{{LG('Precio')}}: <span>{{dataReserva.precio}}€</span></p>
                                <ng-container *ngIf="!regalar">
                                    <p class="no-margin-bottom" *ngIf="anticipo.porcentaje_anticipo != 100">*Al hacer la reserva, usted abona el {{anticipo.porcentaje_anticipo}}%. El reseto se abona el día de la actividad</p>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="dataReserva.tienecupon">
                            <div class="col-lg-12 col-xs-12">
                                <p class="no-margin-bottom">{{LG('Precio')}}: <span>{{dataReserva.precio_original}}€</span></p>
                            </div>
                            <div class="col-lg-12 col-xs-12">
                                <p class="no-margin-bottom">{{LG('Descuento')}}: -<span>{{dataReserva.cupon.descuento}}</span></p>
                                <p class="no-margin-bottom">{{LG('Total')}}: <span>{{globals.FloatES(dataReserva.total)}}€</span></p>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div id="content-pay" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <ng-container *ngIf="!admin && !colaborador">
                        <div class="row">
                            <div class="" style="width: fit-content; float: unset; margin: auto;">
                                <div class="" *ngIf="!canjear">
                                    <label class="col-form-label label-modal-title hide-tablet-desktop label-calendar-title" style="margin-top: 0px;"><i class="icofont-credit-card"></i> Tipos de pago</label>
                                    <hr class="separador-general hide-tablet-desktop custom-form-reservar" style="margin-left: 0px !important;">

                                    <div *ngFor="let item of formas_pago" class="inline-block m-r-0 formas-pago">
                                        <input id="{{item.slug}}" class="fit-content" type="radio" name="pago" value="{{item.forma_pago}}" (click)="SetFormaPago(item)">
                                        <label style="margin-left: 5px; margin-right: 5px;" for="{{item.slug}}" (click)="SetFormaPago(item)">{{item.forma_pago}}
                                            <i class="icofont-paypal-alt" *ngIf="item.forma_pago == 'Paypal'"></i>
                                            <i class="icofont-credit-card" *ngIf="item.forma_pago == 'Tarjeta bancaria' || item.forma_pago == 'Tarjeta de crédito'"></i>
                                            <i class="icofont-stripe-alt" *ngIf="item.forma_pago == 'Stripe'"></i>
                                        </label>
                                    </div>
                                </div>
                                <div id="stripe-form" class="m-t-10 m-b-10" style="display:none;" *ngIf="!canjear">
                                    <div id="card-element"></div>
                                    <div id="card-errors" role="alert"></div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="admin">
                        <div class="col-lg-12 col-md-12 col-xs-12">
                            <h4>¿Quién realiza la reserva?</h4>
                            <div class="form-group row">
                                <div class="col-lg-12 col-xs-12">
                                    <select class="form-control" id="select-colaboradores-reserva-admin" (change)="SelectReservaProveedor($event)">
                                        <option value="admin" selected>Administrador</option>
                                        <option *ngFor="let colaboradorReserva of colaboradores" [value]="colaboradorReserva.id">{{colaboradorReserva.nombre}} {{colaboradorReserva.apellidos}} ({{colaboradorReserva.empresa}})</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr>
                    </ng-container>
                    <ng-container *ngIf="admin || colaborador">
                        <div class="col-lg-12 col-md-12 col-xs-12">
                            <label for="pago-en-mano" (click)="setTipoPago(1,'pago-mano')"><input id="pago-en-mano" type="radio" name="pago" value="Pago en mano"> Pago en mano</label>
                        </div>
                        <div class="col-lg-12 col-md-12 col-xs-12">
                            <label for="pago-a-colaborador" (click)="setTipoPago(3,'pago-a-colaborador')"><input id="pago-a-colaborador" type="radio" name="pago" value="Pago a colaborador"> Pago a colaborador</label>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="admin && tienealgunapasarela">
                        <hr>
                        <div class="col-lg-12 col-md-12 col-xs-12">
                            <h5>Enviar petición de pago al cliente</h5>
                            <label for="enviar-pago" (click)="setTipoPago(7,'pago-anticipo')"><input id="enviar-pago" type="radio" name="pago" value="Enviar petición de pago al cliente"> Enviar petición de pago al cliente</label>
                            <div class="row row-small">
                                <div class="col-xs-12 noselect">
                                    <div class="form-group">
                                    <label>Establecer importe personalizado
                                        <span class="text-danger">* (dejar en blanco para enviar el total de la reserva)</span>
                                    </label>
                                    <input type="number" class="form-control" [(ngModel)]="dataReserva.anticipo_importe" maxlength="80">
                                    </div>
                                </div>
                            </div>
                            <div class="row row-small">
                                <div class="col-xs-6 noselect">
                                    <div class="form-group">
                                    <label>Fecha expiración</label>
                                    <input id="txt-fecha-expiracion" type="date" class="form-control" maxlength="20">
                                    </div>
                                </div>
                                <div class="col-xs-6 noselect">
                                    <div class="form-group">
                                    <label>Hora expiración</label>
                                    <input id="txt-hora-expiracion" type="time" class="form-control" maxlength="5" step="300">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="modal-footer modal-footer-pago col-md-12 col-xs-12 m-t-20">
            <!-- <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button> -->
            <button type="button" class="btn btn-white" id="btn-anterior" (click)="AnteriorModal3()"><i class="fa fa-arrow-left"></i> {{LG('Volver')}}</button>
            <button type="button" class="btn btn-primary" id="btn-guardar-reserva" *ngIf="reservar" (click)="ComprobarGuardarReserva()">{{LG('Pagar y finalizar')}} <i class="fa fa-arrow-right"></i></button>
            <button type="button" class="btn btn-primary" id="btn-guardar-reserva" *ngIf="regalar" (click)="ComprobarGuardarBono()">{{LG('Pagar y finalizar')}} <i class="fa fa-arrow-right"></i></button>
            <button type="button" class="btn btn-primary" id="btn-guardar-reserva" *ngIf="canjear" (click)="CanjearBono()">{{LG('Finalizar')}} <i class="fa fa-arrow-right"></i></button>
        </div>
        <div class="clearfix"></div>
        <br>
    </div>
    <div id="content-paso-3" class="row" style="display: none;">
        <div class="col-md-12 col-xs-12">
            <div class="row display-flex width-fit-auto">
                <!-- <div class="col-lg-3"><h3 class="title-paso">1. Fecha y entradas</h3></div>
                <div class="col-lg-3"><h3 class="title-paso">2. Tus datos</h3></div>
                <div class="col-lg-3"><h3 class="title-paso">3. Reserva completada</h3></div> -->
                <h3 class="title-paso anterior">1. {{LG('Elige fecha')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                <h3 class="title-paso anterior">2. {{LG('Actividad y plazas')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                <h3 class="title-paso anterior">3. {{LG('Tus datos')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                <h3 class="title-paso active">4. {{LG('Reserva completada')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
            </div>
            <ng-container *ngIf="(!forma_pago_seleccionada || forma_pago_seleccionada.slug != 'stripe') && !canjear">
                <div id="content-resumen-form" class="row" style="text-align: center;">
                    {{LG('Si ya has terminado puedes volver a realizar otra reserva')}}.
                    <ng-container *ngIf="!dataReserva.CompraBono">
                        <div class="row row-small datos-reserva" *ngIf="dataReserva">
                            <div class="col-xs-6">
                            <ul class="list-group">
                                <li class="list-group-item active">{{LG('Tus datos')}}</li>
                                <li class="list-group-item datos">
                                    <div>{{dataReserva.nombre}} {{dataReserva.apellidos}}</div>
                                    <div *ngIf="dataReserva.cli_empresa">{{dataReserva.empresa}}<span *ngIf="dataReserva.cli_nifcif">, {{dataReserva.cli_nifcif}}</span></div>
                                    <div><span *ngIf="dataReserva.cli_codpostal"> {{dataReserva.cli_codpostal}}</span></div>
                                    <div>{{dataReserva.cli_localidad}}<span *ngIf="dataReserva.cli_pais">, {{dataReserva.cli_pais}}</span></div>
                                    <div *ngIf="dataReserva.cli_email"><i class="fa fa-envelope-o"></i> {{dataReserva.email}}</div>
                                    <div *ngIf="dataReserva.cli_telefono"><i class="fa fa-phone"></i> {{dataReserva.telefono}}</div>
                                    <div *ngIf="dataReserva.cli_notas"><br>{{dataReserva.cli_notas}}</div>
                                </li>
                            </ul>
                            <ul class="list-group">
                                <li class="list-group-item active">{{LG('Modalidad de pago')}}</li>
                                <li class="list-group-item datos">
                                    <div class="dato-destacado">{{dataReserva.cli_tipo_pago}}</div>
                                </li>
                            </ul>
                            </div>
                            <div class="col-xs-6">
                            <ul class="list-group">
                                <li class="list-group-item active">{{LG('Datos de la reserva')}}</li>
                                <li class="list-group-item datos">
                                    <div class="dato-destacado">#{{dataReserva.referencia}}</div>
                                    <div class="dato-destacado">{{dataReserva.actividad}}</div>
                                    <div>Fecha del actividad: {{dataReserva.fecha}}</div>
                                    <div>Sesión del actividad: {{dataReserva.sesion}}</div>
                                    <div>Adultos: {{dataReserva.adultos}}</div>
                                    <div>Niños: {{dataReserva.ninios}}</div>
                                    <!-- <div>Niños gratis: {{dataReserva.ninos_gratis}}</div> -->
                                </li>
                                <li class="list-group-item active font-bold font-20">
                                    Total
                                    <div class="pull-right">{{dataReserva.precio}} €</div>
                                </li>
                            </ul>
                            <div class="clearfix"></div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="(forma_pago_seleccionada && forma_pago_seleccionada.slug == 'stripe' && !dataReserva.CompraBono) || canjear">
                <div id="content-resumen-form" class="row" style="text-align: center;">
                    <h1>{{LG('¡Gracias por tu reserva!')}}</h1>
                    <p>{{LG('A continuación te mostramos los detalles de tu reserva')}}:</p>
                    <div class="row row-small datos-reserva" *ngIf="dataReserva && dataReserva.id">
                    <div class="col-xs-6">
                        <ul class="list-group">
                            <li class="list-group-item active">{{LG('Tus datos')}}</li>
                            <li class="list-group-item datos">
                            <div>{{dataReserva.cli_nombre}} {{dataReserva.cli_apellidos}}</div>
                            <div *ngIf="dataReserva.cli_empresa">{{dataReserva.cli_empresa}}<span *ngIf="dataReserva.cli_nifcif">, {{dataReserva.cli_nifcif}}</span></div>
                            <div>
                                <span *ngIf="dataReserva.cli_direccion">{{dataReserva.cli_direccion}}, </span>
                                <span *ngIf="dataReserva.cli_codpostal">{{dataReserva.cli_codpostal}}, </span>
                                <span *ngIf="dataReserva.cli_localidad">{{dataReserva.cli_localidad}}</span>
                            </div>
                            <div><span *ngIf="dataReserva.cli_provincia">{{dataReserva.cli_provincia}}</span></div>
                            <div><span *ngIf="dataReserva.cli_pais">, {{dataReserva.cli_pais}}</span></div>
                            <div *ngIf="dataReserva.cli_email"><i class="fa fa-envelope-o"></i> {{dataReserva.cli_email}}</div>
                            <div *ngIf="dataReserva.cli_telefono"><i class="fa fa-phone"></i> {{dataReserva.cli_telefono}}</div>
                            <div *ngIf="dataReserva.cli_notas"><br>{{dataReserva.cli_notas}}</div>
                            </li>
                        </ul>
                        <ul class="list-group">
                            <li class="list-group-item active">{{LG('Modalidad de pago')}}</li>
                            <li class="list-group-item datos">
                            <div class="dato-destacado">{{dataReserva.cli_tipo_pago}}</div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-xs-6">
                        <ul class="list-group">
                            <li class="list-group-item active">{{LG('Datos de la reserva')}}</li>
                            <li class="list-group-item datos">
                            <div class="dato-destacado">#{{dataReserva.referencia}}</div>
                            <div class="dato-destacado">{{dataReserva.actividad}}</div>
                            <div>Fecha del actividad: {{dataReserva.fecha_format}}</div>
                            <div>Sesión del actividad: {{dataReserva.sesion}}</div>
                            <div>Adultos: {{dataReserva.adultos}}</div>
                            <div>Niños: {{dataReserva.ninos}}</div>
                            <div>Niños gratis: {{dataReserva.ninos_gratis}}</div>
                            </li>
                            <li class="list-group-item active font-bold font-20">
                            Total
                            <div class="pull-right">{{dataReserva.total_format}} €</div>
                            </li>
                        </ul>
                        <div class="clearfix"></div>
                    </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="(forma_pago_seleccionada && forma_pago_seleccionada.slug == 'stripe') && dataReserva.CompraBono">
                <div id="content-resumen-form" class="row" style="text-align: center;">
                    <h1>{{LG('¡Gracias por tu compra!')}}</h1>
                    <p>{{LG('A continuación te mostramos los detalles de tu bono regalo')}}:</p>
                    <div class="row row-small datos-reserva" *ngIf="dataReserva && dataReserva.id">
                    <div class="col-xs-6">
                        <ul class="list-group">
                            <li class="list-group-item active">{{LG('Tus datos')}}</li>
                            <li class="list-group-item datos">
                            <div>{{dataReserva.nombre_regalo}} {{dataReserva.apellidos_regalo}}</div>
                            <div>
                                <span *ngIf="dataReserva.codigo_postal_regalo">{{dataReserva.codigo_postal_regalo}}, </span>
                                <span *ngIf="dataReserva.localidad_regalo">{{dataReserva.localidad_regalo}}</span>
                            </div>
                            <div><span *ngIf="dataReserva.provincia_regalo">{{dataReserva.provincia_regalo}}</span></div>
                            <div *ngIf="dataReserva.email_regalo"><i class="fa fa-envelope-o"></i> {{dataReserva.email_regalo}}</div>
                            <div *ngIf="dataReserva.telefono_regalo"><i class="fa fa-phone"></i> {{dataReserva.telefono_regalo}}</div>
                            </li>
                        </ul>
                        <ul class="list-group" *ngIf="dataReserva.dedicatoria">
                            <li class="list-group-item active">{{LG('Dedicatoria')}}</li>
                            <li class="list-group-item datos">
                            <div class="dato-destacado">{{dataReserva.dedicatoria}}</div>
                            </li>
                        </ul>
                        <ul class="list-group">
                            <li class="list-group-item active">{{LG('Modalidad de pago')}}</li>
                            <li class="list-group-item datos">
                            <div class="dato-destacado">{{dataReserva.cli_tipo_pago}}</div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-xs-6">
                        <ul class="list-group">
                            <li class="list-group-item active">{{LG('Datos del bono regalo')}}</li>
                            <li class="list-group-item datos">
                            <div class="dato-destacado">Código: {{dataReserva.codigo}}</div>
                            <div class="dato-destacado">{{dataReserva.actividad}}</div>
                            <hr style="margin: 4px 0;">
                            <div>{{dataReserva.nombre}} {{dataReserva.apellidos}}</div>
                            <div>
                                <span *ngIf="dataReserva.codigo_postal">{{dataReserva.codigo_postal}}, </span>
                                <span *ngIf="dataReserva.localidad">{{dataReserva.localidad}}</span>
                            </div>
                            <div><span *ngIf="dataReserva.provincia">{{dataReserva.provincia}}</span></div>
                            <div *ngIf="dataReserva.email"><i class="fa fa-envelope-o"></i> {{dataReserva.email}}</div>
                            <div *ngIf="dataReserva.telefono"><i class="fa fa-phone"></i> {{dataReserva.telefono}}</div>
                            <hr style="margin: 4px 0;">
                            <div>Adultos: {{dataReserva.adultos}}</div>
                            <div>Niños: {{dataReserva.ninos}}</div>
                            <div>Niños gratis: {{dataReserva.ninos_gratis}}</div>
                            </li>
                            <li class="list-group-item active font-bold font-20">
                            Total
                            <div class="pull-right">{{dataReserva.total_format}} €</div>
                            </li>
                        </ul>
                        <div class="clearfix"></div>
                    </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="modal-footer col-md-12 col-xs-12">
            <!-- <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button> -->
            <button type="button" class="btn btn-primary" id="btn-guardar-reserva" onclick="window.location.reload()">{{LG('Realizar otra reserva')}} <i class="fa fa-refresh"></i></button>
        </div>
    </div>
    <div id="content-paso-4" class="modal-body" style="display: none;">
      <div class="mensaje-pasarela">
          <img src="assets/credit-card.svg" alt="">
          <p>Por favor, completa el pago en la pasarela online</p>
      </div>
    </div>
    <div id="content-paso-error" class="row" style="display: none;">
        <div class="col-md-12 col-xs-12">
            <div class="row display-flex width-fit-auto">
                <!-- <div class="col-lg-3"><h3 class="title-paso">1. Fecha y entradas</h3></div>
                <div class="col-lg-3"><h3 class="title-paso">2. Tus datos</h3></div>
                <div class="col-lg-3"><h3 class="title-paso">3. Reserva completada</h3></div> -->
                <h3 class="title-paso anterior">1. {{LG('Fecha y entradas')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                <h3 class="title-paso anterior">2. {{LG('Tus datos')}} <i class="fa fa-check" aria-hidden="true"></i></h3>
                <h3 class="title-paso active error">3. {{LG('Reserva completada')}} <i class="fa fa-times" aria-hidden="true"></i></h3>
            </div>
            <div id="content-resumen-form" class="row">
            </div>
        </div>
        <div class="modal-footer col-md-12 col-xs-12">
            <!-- <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button> -->
            <button type="button" class="btn btn-white" id="btn-anterior" (click)="VolverPaso2()"><i class="fa fa-arrow-left"></i> {{LG('Volver')}}</button>
        </div>
    </div>
    <div id="">
        <div class="">
            <div class="">
                <div>

                </div>
            </div>
        </div>
    </div>
</div>