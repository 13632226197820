import { Component, OnInit, NgZone, ApplicationRef, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let toastr: any;
declare let moment: any;
declare let ICAL: any;
declare let Chart: any;
declare let Stripe: any;
// declare let gtag: any;

declare let LANG: any;
declare let LG: any;

@Component({
  selector: 'app-nueva-reserva',
  templateUrl: './nueva-reserva.component.html',
  styleUrls: ['./nueva-reserva.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NuevaReservaComponent implements OnInit {
  public id_cliente:any = this.route.snapshot.paramMap.get('id_cliente');
  public fecha:string = (this.globals.GetQueryString('fecha') ? this.globals.GetQueryString('fecha') : '');
  public css:string = (this.globals.GetQueryString('css') ? this.globals.GetQueryString('css') : '');
  public admin:boolean = (this.globals.GetQueryString('modaladmin') == 'true');
  public colaborador:boolean = (this.globals.GetQueryString('modalcolaborador') == 'true');
  public idColaborador:string = (this.globals.GetQueryString('idcolaborador') ? this.globals.GetQueryString('idcolaborador') : '');
  public estilo:string = (this.globals.GetQueryString('estilo') ? this.globals.GetQueryString('estilo') : '');
  public LG:any = LG;
  public aforo = [];
  public actividad:any = {};
  public eventos:any = [];
  public reservasDias = [];
  public mesesAforo:any = [];
  public historicoReservas:any = [];
  public actividades:any = [];
  public idCalendario:any;
  public primeraCargaCalendario = true;
  public sesiones:any = [];
  public aforoDia:any = [];
  public dataReserva:any = {
    id_actividad:    '',
    fecha:        '',
    sesion:       '',
    adultos:      0,
    ninios:       0,
    niniosGratis: 0,
    nombre:       '',
    apellidos:    '',
    email:        '',
    telefono:     '',
    precio:       0,
    cli_localidad:'',
    tipoPago:     '',
    tipo:         'Web',
    idioma:       'es',
    cupon: null
  };
  public dataPersonaRegala:any = {
    nombre:       '',
    apellidos:    '',
    email:        '',
    telefono:     '',
    codpostal:    '',
    dedicatoria:  ''
  };
  public dataCanjearBono:any = {
    nombre:       '',
    apellidos:    '',
    email:        '',
    telefono:     '',
    codpostal:    '',
    dedicatoria:  ''
  };
  public precioAdulto:any = 0;
  public precioNinio:any = 0;
  public precioCalculoAdulto:any = 0;
  public precioCalculoNinio:any = 0;
  public plazasDisponibles:any = 50;

  public formas_pago:any = [];
  public forma_pago_seleccionada:any = null;
  public tienealgunapasarela:boolean = false;
  public stripe:any = null;
  public stripe_card:any = null;
  public num_reservas_dias_check = 0;

  public url_politicas_privacidad:any = '';
  public anticipo:any = 100;
  public reservar:boolean = true;
  public regalar:boolean = false;
  public canjear:boolean = false;
  public bono_regalo:boolean = false;

  public asientos:any = [];
  public tarifas:any = [];

  public irPaso2 = false;
  public sesionSelect = '';

  public colaboradores:any;
  public permitirDescuentos:boolean = false;
  public tipoDescuento:any = 'fijo';
  public descuento:number;
  public precioSinDescuento: number = 0;
  public descuentoAplicado:boolean = false;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    App.init();
    if (!this.globals.cliente) {
      this.globals.cliente = {id: this.id_cliente};
    }
    this.globals.CargarColorCliente(this.id_cliente);
    setTimeout(() => {
      this.idCalendario = 1;
      this.dataReserva.id_actividad = this.idCalendario;
      this.LoadAforo();
      var formasPago = $('.formas-pago>input');
      $(formasPago[0]).click();
    }, 100);
    LANG = this.globals.GetQueryString('lang');
    if (!LANG || LANG == '') LANG = 'es';
    this.dataReserva.idioma = LANG;
    // console.log(this.idColaborador);
    this.CargarFormasPago();
    this.loadActividades();
    let that = this;
    setTimeout(() => {
      $('#selector-actividades-calendario').val('5');
      $('#selector-actividades-calendario').change(function() {
        $.LoadingOverlay("show", {
          background: 'rgba(255, 255, 255, 0.6)',
          imageResizeFactor: 0.5
        });
        that.idCalendario = $(this).val();
        that.LoadAforo();
      });
    }, 1000);
    $('#modal-reservar-calendar').modal('show');
    this.globals.Api('/pub-politicas-privacidad',{id_cliente: this.id_cliente}).subscribe(data => {
      if (!data || data.error) {
        return;
      }
      this.url_politicas_privacidad = data;
    });
    this.IframePostMessages();
    window.onresize = () => {
      this.IframePostMessages();
    };
    setTimeout(() => {
      if (this.css && this.css != '' && !this.admin) {
        var styleSheet = document.createElement("style");
        styleSheet.innerText = this.css;
        document.body.appendChild(styleSheet);
      }
      if (this.admin) {
        $('#global-styles').remove();
        if (document.querySelector('#global-styles')) document.querySelector('#global-styles').outerHTML = '';
        $(document).ready(function(e) {
          $('#global-styles').remove();
          if (document.querySelector('#global-styles')) document.querySelector('#global-styles').outerHTML = '';
        });
        this.permitirDescuentos = true;
      }
      function inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
      }
      if (inIframe()) {
        var styleSheet = document.createElement("style");
        let css = `body {overflow:hidden}`;
        css = css.replace(/(\r\n|\n|\r)/gm, "");
        styleSheet.innerText = css;
        document.body.appendChild(styleSheet);
      }
      if (this.estilo == 'oscuro') {
        var styleSheet = document.createElement("style");
        let css = `
          body, #content-iframe-frontend {background-color: transparent;}
          #content-iframe-frontend {padding: 0;}
          #content-iframe-frontend h1,
          #content-iframe-frontend h5,
          #content-iframe-frontend > p,
          #content-iframe-frontend label,
          #content-iframe-frontend label a,
          #content-iframe-frontend .legend-text,
          #content-iframe-frontend .label-modal-title i,
          #content-iframe-frontend .precio-calculo-total,
          #content-iframe-frontend .custom-input-number,
          #content-iframe-frontend .precio-calculo,
          #content-iframe-frontend .custom-menos,
          #content-iframe-frontend .custom-mas,
          #content-iframe-frontend #content-datos h4 {
            color: #FFF;
          }
          #content-iframe-frontend .title-paso {color:#EEE;}
          #content-iframe-frontend .title-paso.active {border: 2px solid var(--color-secondary);}
          #content-iframe-frontend .btn.selected {background-color: var(--color-secondary);}
          #content-iframe-frontend .separador-general {background-color:#b1b1b1;}
        `;
        css = css.replace(/(\r\n|\n|\r)/gm, "");
        styleSheet.innerText = css;
        document.body.appendChild(styleSheet);
      }
    }, 100);
    setTimeout(() => {
      if (this.admin || this.colaborador) {
        $('#global-styles').remove();
        if (document.querySelector('#global-styles')) document.querySelector('#global-styles').outerHTML = '';
        $(document).ready(function(e) {
          $('#global-styles').remove();
          if (document.querySelector('#global-styles')) document.querySelector('#global-styles').outerHTML = '';
        });
        console.log(this.idColaborador);
        if(this.idColaborador){
          this.globals.Api('/pub-permitir-reserva-colaborador',{id: this.idColaborador}).subscribe(data => {
            if (!data) return;
            console.log(data);
            this.permitirDescuentos = data.permitir_descuentos;
          });
        }
      }
    }, 600);
    if(this.admin){
      this.globals.Api('/pub-colaboradores',{id_cliente: this.id_cliente}).subscribe(data => {
        if (!data) return;
        this.colaboradores = data;
      });
    }
  }

  IframePostMessages() {
    setTimeout(() => {
      window.parent.postMessage({
        url: window.location.href,
        height: $('#content-iframe-frontend').height()
      }, '*');
      window.postMessage({
        url: window.location.href,
        height: $('#content-iframe-frontend').height()
      }, '*');
    }, 100);
  }

  //Checkeamos si hay aforos configurados
  LoadAforo() {
    this.globals.Api('/pub-config-anticipo').subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar anticipo', 'error');return;
      }
      this.anticipo = data;
    });
    this.globals.Api('/pub-bono-regalo').subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar bonos regalo', 'error');return;
      }
      this.bono_regalo = data.bonos_regalo;
    });
    this.globals.Api('/eve-actividades',{'id': this.idCalendario}).subscribe(data => {
      if (!data || !data.length) {
        // swal('Error', 'Error al validar el aforo', 'error');
        return;
      }
      // this.sesiones = data[0].sesiones.split(',');
      this.sesiones.pop();
      this.precioAdulto = data[0].precio;
      this.precioNinio = data[0].precio_ninios;
    });
    this.globals.Api('/pub-eve-actividades-aforo-v2-calendario',{'id_actividad': this.idCalendario, 'oculto': true}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al validar el aforo', 'error');return;
      }
      this.aforo = data;
      var month = '';
      this.aforo.forEach(item =>{
        item.percentage = (100 * item.aforo_restante) / item.aforo_total;
        var dt = new Date(item.fecha);
        var monthYear:any = {};
        item.mes = dt.toLocaleString('es-ES', { month: 'long' });
        monthYear.month = dt.toLocaleString('es-ES', { month: 'long' });
        monthYear.year = item.anio = dt.getFullYear();
        if (month != monthYear.month) {
          month = monthYear.month;
          this.mesesAforo.push(monthYear);
        }
      });
      if (this.primeraCargaCalendario) {
        this.CargarCalendario();
        this.primeraCargaCalendario = false;
      } else {
        //this.CargarEventosCalendario();
        $.LoadingOverlay("hide");
      }
    });
  }

  CargarFormasPago() {
    if (!this.globals.cliente) return;
    if (!this.globals.cliente.id) return;
    if (this.admin || this.colaborador) {
      this.formas_pago.push({
        id: 1,
        idioma: 'es',
        forma_pago: 'Pago en mano',
        slug: 'pago-mano'
      })
      this.formas_pago.push({
        id: 2,
        idioma: 'es',
        forma_pago: 'Pagado',
        slug: 'pago-a-colaborador'
      })
      this.formas_pago.push({
        id: 3,
        idioma: 'es',
        forma_pago: 'Pago Anticipado',
        slug: 'pago-anticipo'
      })
      this.formas_pago.push({
        id: 4,
        idioma: 'es',
        forma_pago: 'Transferencia bancaria',
        slug: 'transferencia'
      })
      this.globals.Api('/pub-formas-pago', {id_cliente: this.globals.cliente.id, idioma: LANG}).subscribe(data => {
        for (let item of data) {
          if (item.slug != 'pago-mano' && item.slug != 'transferencia') {
            this.tienealgunapasarela = true;
            break;
          }
        }
      });
      return;
    }
    this.globals.Api('/pub-formas-pago', {id_cliente: this.globals.cliente.id, idioma: LANG}).subscribe(data => {
      if (!data || !data.length) return;
      this.formas_pago = data;
      if (this.formas_pago.length) this.SetFormaPago(this.formas_pago[0]);
    });
  }
  CargarCalendario() {
    var date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    let that = this;
    var heightCalendar = 280;
    if ($(window).width() <= 1366 && $(window).width() > 1025) {
      heightCalendar = 325;
    }

    let dayclick = (date, allDay, jsEvent, view) => {
      this.actividad.id = null;
      if (this.canjear && this.dataCanjearBono && this.dataCanjearBono.bono) {
        this.actividad.id = this.dataCanjearBono.bono.id_actividad;
      }
      this.sesiones = [];
      this.dataReserva.id_actividad = '';
      this.actividades = [];
      this.irPaso2 = true;
      this.globals.Api('/pub-check-dias-minimos').subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Error al cargar configuración', 'error');return;
        }
        let dateClick = new Date(date).getTime();
        let dateNow = new Date().getTime();
        var someDate = new Date();
        var numberOfDaysToAdd = data.dias_min_reserva;
        var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
        let dateMin = new Date(result).getTime();
        let horasMinimas = data.dias_min_reserva * 24;
        if (!this.admin && !this.colaborador) {
          if (dateClick < dateNow) {
            return;
          }
          if (dateClick <= dateMin || dateClick == dateNow) {
            this.sesiones = [];
            this.irPaso2 = false;
            swal({
              title: "Información",
              text: "Para reservar con antelación de "+horasMinimas+"h, contacte con nosotros por teléfono "+data.tel_contacto,
              type: "info",
              showCancelButton: true,
              confirmButtonClass: "btn-primary",
              confirmButtonText: "Llamar",
              cancelButtonText: "Cerrar",
              closeOnConfirm: true
            },() => {
              window.open('tel:'+data.tel_contacto);
            });
            return;
          }
          var today = new Date();
          var tempDate = new Date(date);
          if (today > tempDate) {
            return;
          }
        }
        this.SiguienteModalPlazas();
        let params = {
          fecha: date,
          fecha_format: moment(date).format('DD/MM/YYYY'),
          fecha_format_en: moment(date).format('YYYY-MM-DD')
        };
        if ($.editcitashow) $.editcitashow(params);

        this.dataReserva.fecha = moment(date).format('YYYY-MM-DD');
        $('#date-reservar').val(this.dataReserva.fecha);
        setTimeout(() => {
          let params_actividades:any = {web: 1, dia: this.dataReserva.fecha};
          if (this.admin) delete params_actividades.web;
          this.globals.Api('/eve-actividades', params_actividades).subscribe(data => {
            if (!data || data.error) {
              swal('Error', 'Error al cargar datos evento', 'error');return;
            }
            this.actividades = data;
            $('#select-actividad-reservar').off('change');
            $('#select-actividad-reservar').change((e:any) => {
              let idEventoSelect = $(e.target).val();
              this.dataReserva.precio = 0;
              if (this.tarifas && this.tarifas.length) {
                this.tarifas.forEach(element => {
                  element.cantidad = 0;
                  element.total = 0;
                });
              }
              this.calcularPrecioModal();
              $('#custom-menos-adulto').addClass('disabled-custom-input');
              $('#custom-menos-ninio').addClass('disabled-custom-input');
              if (idEventoSelect == 'default') return;
              if (this.canjear) {
                this.dataCanjearBono.fecha = moment(date).format('YYYY-MM-DD');
                this.CargarSesionesCanjear();
                return;
              }
              this.globals.Api('/eve-actividades',{id: idEventoSelect}).subscribe(data => {
                if (!data || data.error) {
                  swal('Error', 'Error al cargar datos evento', 'error');return;
                }
                this.actividad = data[0];
                if (this.actividad.vehiculo) {
                  // Cálculo disponibilidad condicionante vehículos
                  this.sesiones = null;
                  this.globals.Api('/eve-actividades-sesiones-vehiculos-v2',{id: this.actividad.id, fecha: params.fecha_format_en}).subscribe(data => {
                    if (!data || data.error) {
                      swal('Error', 'Error al cargar datos sesiones', 'error');return;
                    }
                    if (data[1] && data[1].length > 0) {
                      this.sesiones = data[1];
                      this.precioAdulto = data[1][0].precio_adultos;
                      this.precioNinio = data[1][0].precio_ninios;

                    } else {
                      this.sesiones = data[0];
                      this.precioAdulto = this.actividad.precio;
                      this.precioNinio = this.actividad.precio_ninios;
                    }
                    this.IframePostMessages();
                    setTimeout(()=>{
                      $('.content-radio-sesion').click(function() {
                        $('.content-radio-sesion').removeClass('active');
                        $(this).addClass('active');
                      });
                    },100);
                    var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
                    var d = new Date(params.fecha_format_en);
                    var dayName = days[d.getDay()];
                    this.globals.Api('/check-dia-disponible-v2',{id: this.actividad.id, dia: dayName, fecha: params.fecha_format_en}).subscribe(data => {
                      if (!data || data.error) {
                        swal('Error', 'Error al cargar datos sesiones', 'error');return;
                      }
                      if (data[0].count == 0 && data[0].dias_especial_disponible == 0) {
                        this.sesiones = null;
                      } else {
                        for(let i = 0; i < this.sesiones.length; i++) {
                          let sesion = this.sesiones[i].sesion;
                          this.globals.Api('/eve-actividades-check-vehiculos-v2',{id: this.actividad.id, fecha: params.fecha_format_en, sesion: sesion}).subscribe(data => {
                            if (!data || data.error) {
                              swal('Error', 'Error al verificar vehículos', 'error');return;
                            }
                            let plazasDisponibles = 0;
                            let plazasDisponiblesSinCoche = that.sesiones[i].aforo + that.sesiones[i].plazas_adicionales;
                            that.sesiones[i].aforo_restante = that.sesiones[i].aforo_restante + that.sesiones[i].plazas_adicionales;
                            data.forEach(vehiculo => {
                              plazasDisponibles += vehiculo.plazas_disponibles;
                            });
                            if(that.sesiones[i].aforo_restante > plazasDisponibles){
                              that.sesiones[i].aforo_restante = plazasDisponibles;
                            }
                            if(plazasDisponibles > plazasDisponiblesSinCoche){
                              that.sesiones[i].aforo_restante = plazasDisponiblesSinCoche;
                            }
                          });
                        }
                      }
                      this.IframePostMessages();
                    });
                  });
                } else {
                  // Cálculo disponibilidad normal sin vehículos
                  this.sesiones = null;
                  this.globals.Api('/pub-eve-actividades-sesiones-v2',{id: this.actividad.id, fecha: params.fecha_format_en}).subscribe(data => {
                    if (!data || data.error) {
                      swal('Error', 'Error al cargar datos sesiones', 'error');return;
                    }
                    if (data[1] && data[1].length > 0) {
                      this.sesiones = data[1];

                    } else {
                      this.sesiones = data[0];
                    }
                    var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
                    var d = new Date(params.fecha_format_en);
                    var dayName = days[d.getDay()];
                    this.globals.Api('/pub-check-dia-disponible-v2',{id: this.actividad.id, dia: dayName, fecha: params.fecha_format_en}).subscribe(data => {
                      if (!data || data.error) {
                        swal('Error', 'Error al cargar datos sesiones', 'error');return;
                      }
                      if (data[0].count == 0 && data[0].dias_especial_disponible == 0) {
                        this.sesiones = null;
                      } else {
                        for(let i = 0; i < this.sesiones.length; i++) {
                          if (this.sesiones[i].plazas_adicionales) {

                          } else {
                            this.sesiones[i].plazas_adicionales = 0;
                          }
                          this.sesiones[i].aforo_restante = this.sesiones[i].aforo_restante + this.sesiones[i].plazas_adicionales;
                          if (!this.actividad.gestion_asientos) {
                            if (this.sesiones[i].aforo_restante < 0) this.sesiones[i].aforo_restante = 0;
                          }
                        }
                      }
                      if (this.actividad.gestion_asientos) {
                        this.sesiones.forEach(item => {
                          item.asientos = item.asientos - item.personas;
                        });
                      }
                      this.IframePostMessages();
                      setTimeout(()=>{
                        $('.content-radio-sesion').click(function() {
                          $('.content-radio-sesion').removeClass('active');
                          $(this).addClass('active');
                        });
                      },100);
                    });
                    setTimeout(()=>{
                      $('.content-radio-sesion').click(function() {
                        $('.content-radio-sesion').removeClass('active');
                        $(this).addClass('active');
                      });
                    },100);
                  });
                }
                if (this.actividad.gestion_asientos) {
                  this.globals.Api('/eve-actividades-asientos-actividad',{id: this.actividad.id}).subscribe(data => {
                    if (!data || data.error) {
                      swal('Error', 'Error al cargar datos asientos', 'error');return;
                    }
                    this.asientos = data;
                    this.asientos.forEach(asiento => {
                      asiento.tarifas.forEach(tarifa => {
                        tarifa.precio = JSON.parse(tarifa.precio);
                      });
                    });
                    // console.log(this.asientos);
                    setTimeout(()=>{
                      $('.content-radio-asiento').click(function() {
                        $('.content-radio-asiento').removeClass('active');
                        $(this).addClass('active');
                      });
                    },100);
                  });
                } else {
                  this.globals.Api('/eve-actividades-tarifas-por-persona-actividad',{id: this.actividad.id, fecha: params.fecha_format_en}).subscribe(data => {
                    this.tarifas = data;
                    this.dataReserva.precio = 0;
                    if (this.tarifas && this.tarifas.length) {
                      this.tarifas.forEach(element => {
                        element.cantidad = 0;
                        element.total = 0;
                      });
                    }
                    this.IframePostMessages();
                  });
                }
              });
            });
            if (this.actividades.length == 1) {
              setTimeout(() => {
                $('#select-actividad-reservar').val(this.actividades[0].id);
                $('#select-actividad-reservar').trigger('change');
                this.appref.tick();
              }, 400);
            }
          });
        }, 100);
      });
    };
    if (this.fecha) {
      date = new Date(this.fecha);
      dayclick(date, null, null, null);
    }
    $('#calendar').fullCalendar({
        header: {
            left: 'title',
            center: '',
            right: 'today month prev,next'
        },
        defaultView: 'month',
        defaultDate: date,
        locale: LANG,
        allDaySlot: false,
        editable: false,
        droppable: false,
        selectable: false,
        duration: false,
        contentHeight: heightCalendar,
        // //timeFormat: 'H:mm',
        // minTime: '08:00:00',
        // maxTime: '22:00:00',
        viewRender: (view, element) => {
          // this.params.fecha_inicio = view.start.format('YYYY-MM-DD');
          // this.params.fecha_fin = view.end.format('YYYY-MM-DD');
          // this.CargarReparaciones();
          var fechaInicioRender = view.start.format('YYYY-MM-DD');
          var fechaFinRender = view.end.format('YYYY-MM-DD');

          /*Aquí para cargar los datos al calendario*/
          that.CargarEventosCalendario(fechaInicioRender, fechaFinRender);
          $('.fc-day-top').mouseenter(function() {
            $('.content-hover-aforo').remove();
            var fechaDiaHover = $(this).attr('data-date');
            var aforoDia = [];
            var bloqueado = true;
            var sesionesDiaSelect = [];
            that.aforo.forEach(element => {
              if (element.ocultar == 1) {
                return;
              }
              if (element.fecha == fechaDiaHover) {
                that.sesiones.forEach(element2 => {
                  if (element.sesion == element2) {
                    aforoDia.push(element.aforo_restante);
                    sesionesDiaSelect.push(element.sesion);
                    bloqueado = false;
                  }
                });
              }
            });
            if (bloqueado) return;

            var htmlAforo = `<div class="content-hover-aforo display-flex style="padding-left: 10px; padding-right: 10px;">`;
            for(var i = 0; i < aforoDia.length; i++) {
              if (aforoDia[i] <= 10) {
                htmlAforo += `<div style="padding-bottom: 10px; width: 50%; padding-top: 10px;">`+sesionesDiaSelect[i]+` <br/><span>`+aforoDia[i]+` plazas</span></div>`;
              } else {
                htmlAforo += `<div style="padding-bottom: 10px; width: 50%; padding-top: 10px;">`+sesionesDiaSelect[i]+`</div>`;
              }
            }
            htmlAforo += `</div>`;
            $(this).append(htmlAforo);
            $('.fc-day-grid.fc-unselectable>div:first-child .content-hover-aforo').addClass('bottom-hover');
            $('.fc-day-grid.fc-unselectable>div:first-child:hover').addClass('z-index-9999');
            $('.fc-day-grid.fc-unselectable>div:nth-child(2) .content-hover-aforo').addClass('bottom-hover');
            $('.fc-day-grid.fc-unselectable>div:nth-child(2):hover').addClass('z-index-9999');
          });
          $('.fc-day-top').mouseleave(function() {
            $('.content-hover-aforo').remove();
            $('.fc-day-grid.fc-unselectable>div:first-child').removeClass('z-index-9999');
            $('.fc-day-grid.fc-unselectable>div:nth-child(2)').removeClass('z-index-9999');
          });
          $('.fc-day-top.fc-today,.fc-day-top.fc-future').click(function() {
            $('.fc-day-top.fc-today,.fc-day-top.fc-future').css('background-color','');
            $('.fc-day-top.fc-today,.fc-day-top.fc-future').css('color','');
            $(this).css('background-color','#7AAC3F');
            $(this).css('color','white');
          });
        },
        eventRender: function(event, element) {
        },
        eventClick: function(info) {
          if (info.disabled) return;
          $('.fc-event').removeClass('checked');
          $(this).addClass('checked');
        },
        dayClick: dayclick
    });
  }

  CargarEventosCalendario(fechaInicio:any, fechaFin:any) {
    $('#calendar').fullCalendar('removeEvents');
    const d = new Date();
    const date1 = new Date(fechaInicio);
    let timestampInicio = Math.floor(date1.getTime()/1000);
    const date2 = new Date(fechaFin);
    const date3 = new Date('2022/12/01');
    let timestampFin = Math.floor(date2.getTime()/1000);
    this.reservasDias = [];

    var dateMs = timestampInicio * 1000;
    var dateOb = new Date(dateMs);
    var year = dateOb.getFullYear();
    var month = ("0" + (dateOb.getMonth() + 1)).slice(-2);
    var date = ("0" + dateOb.getDate()).slice(-2);
    var dateFormattedInicio = year+'-'+month+'-'+date;

    var dateMs2 = timestampFin * 1000;
    var dateOb2 = new Date(dateMs2);
    var year2 = dateOb2.getFullYear();
    var month2 = ("0" + (dateOb2.getMonth() + 1)).slice(-2);
    var dateTwo = ("0" + dateOb2.getDate()).slice(-2);
    var dateFormattedFin = year2+'-'+month2+'-'+dateTwo;

    this.globals.Api('/pub-eve-reservas-calendario-dashboard-v2',{id_actividad: this.idCalendario, fecha_inicio: dateFormattedInicio, fecha_fin: dateFormattedFin}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar reservas del calendario', 'error');return;
      }
      this.reservasDias = data;

      this.reservasDias.forEach(reserva => {
        let aforoTotalRestante = parseInt(reserva.aforo_total_actividades) - parseInt(reserva.personas);
        let sumPorcentajeMismaFecha = (aforoTotalRestante * 100) / parseInt(reserva.aforo_total_actividades);

        let start = reserva.fecha;
        let end = reserva.fecha;
        let color = '#5da84e';
        let cursor = 'pointer';
        let textColor = '#FFF';
        let display = 'none';
        let className = 'day-green';
        if (sumPorcentajeMismaFecha < 99) {
          color = '#e5b437';
          cursor = 'pointer';
          // className = 'day-yellow';
          if (sumPorcentajeMismaFecha <= 0) {
            color = '#a30404';
            cursor = 'default';
            textColor = '#FFF';
            // className = 'day-red';
          }
        }
        let event = {
          id: reserva.fecha,
          className: 'day-reserva',
          title: `${aforoTotalRestante}/${reserva.aforo_total_actividades}`,
          duration: false,
          description: `
            <div style="cursor: ${cursor}">
              <div><b><i class="fa fa-lock" style="display: ${display}; float: left; margin-right: 5px;"></i> Disponiblidad</b><br/><i class="fa fa-user"></i> ${aforoTotalRestante}/${reserva.aforo_total_actividades} <span style="color: white;"><i class="icofont-check-circled"></i></span></div>
            </div>
          `,
          start: start,
          end: end,
          data: reserva.personas,
          //color: color,
          color: color,
          // textColor: textColor,
          textColor: 'transparent',
          disabled: false,
          tipo: 'documento'
        };
        $('#calendar').fullCalendar('renderEvent', event, true);
      });
      $('.fc-row.fc-week.fc-widget-content .fc-content-skeleton tbody td:not(.fc-event-container)').html(`
        <a class="fc-day-grid-event fc-h-event fc-event fc-start fc-end day-reserva" style="background-color:#5da84e;border-color:#5da84e;color:transparent">
          <div class="fc-content">
            <span class="fc-title">0000</span>
          </div>
        </a>
      `);
    this.appref.tick();
    });
  }
  ComprobarGuardarReserva() {
    $.buttonloading('btn-guardar-reserva', 'Realizando pago ...');
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.6)',
      imageResizeFactor: 0.5,
      text: 'Realizando pago ...'
    });
    //let that = this;
    if (this.dataReserva.cli_tipo_pago == undefined) {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', 'Selecciona un tipo de pago', 'warning');return;
    }
    // console.log(this.idColaborador);
    // console.log(this.colaborador);
    if(this.colaborador)this.dataReserva.id_colaborador = this.idColaborador;
    // return;
    this.GuardarReserva();
  }
  GuardarReserva() {
    // this.dataReserva.cli_tipo_pago = $('input[type="radio"][name="pago"]:checked').val();
    // this.dataReserva.cli_tipo_pago_slug = $('input[type="radio"][name="pago"]:checked').attr('id');
    // this.dataReserva.pagos = [{
    //   cli_tipo_pago: this.dataReserva.cli_tipo_pago,
    //   cli_tipo_pago_slug: this.dataReserva.cli_tipo_pago_slug
    // }];
    if (this.dataReserva.cupon) {
      this.dataReserva.precio = this.dataReserva.total;
    }

    if(this.descuento || this.descuento != 0){
      this.dataReserva.total_sin_descuento = this.precioSinDescuento;
      if(this.tipoDescuento == 'fijo'){
        this.dataReserva.descuento = this.descuento + '€';
      }else{
        this.dataReserva.descuento = this.descuento + '%';
      }
    }

    // if (gtag) {
    //   gtag('event', 'conversion', {'send_to': 'AW-10882867819/poZJCL_e-LUDEOvErcUo',
    //     'value': this.dataReserva.precio,
    //     'currency': 'EUR'
    //   });
    // }

    if (this.dataReserva.anticipo_importe && this.dataReserva.anticipo_importe > 0) {
      let fecha = $('#txt-fecha-expiracion').val();
      let hora = $('#txt-hora-expiracion').val();
      if (fecha && fecha != '' && hora && hora != '') {
        this.dataReserva.anticipo_fecha_expiracion = fecha+' '+hora;
      }
    }
    if (this.admin) this.dataReserva.tipo = 'Admin';
    if (this.colaborador) this.dataReserva.tipo = 'Partner';
    this.dataReserva.tarifas = this.tarifas.filter((el:any) => el.cantidad && el.cantidad > 0);

    if (this.forma_pago_seleccionada && this.forma_pago_seleccionada.slug == 'stripe' && this.stripe) {
      let that = this;
      this.stripe.createToken(this.stripe_card).then(function(result) {
        if (result.error) {
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
          $.buttoncancelloading('btn-guardar-reserva');
        } else {
          that.dataReserva.stripe_token = result.token.id;
          that.globals.Api('/pub-stripe-pago', that.dataReserva).subscribe(data => {
            if (!data || !data.value) {
              $.LoadingOverlay("hide", true);
              $.buttoncancelloading('btn-guardar-reserva');
              swal('Error en el pago', 'Revisa los datos de tu tarjeta', 'error');return;
            }
            setTimeout(() => {
              $.LoadingOverlay("hide", true);
              $.buttoncancelloading('btn-guardar-reserva');
              that.dataReserva.stripe_order = data.value;
              that.EnviarDatosReserva();
            }, 200);
          }, error => {
            $.LoadingOverlay("hide", true);
            $.buttoncancelloading('btn-guardar-reserva');
            swal('Error en el pago', 'Revisa los datos de tu tarjeta', 'error');
          });
        }
      });
      return;
    }
    this.EnviarDatosReserva();
  }
  EnviarDatosReserva() {
    if (!this.dataReserva.anticipo_importe || this.dataReserva.anticipo_importe == 0){
      this.dataReserva.anticipo_importe = this.dataReserva.precio;
    }
    $.LoadingOverlay("hide", true);
    setTimeout(() => {
      $.LoadingOverlay("show", {
        background: 'rgba(255, 255, 255, 0.6)',
        imageResizeFactor: 0.5,
        text: 'Enviando datos ...'
      });
    }, 10);
    $.buttonloading('btn-guardar-reserva', 'Enviando datos ...');
    this.globals.Api('/pub-reservar-actividad',this.dataReserva).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al hacer la reserva', 'error');
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('btn-guardar-reserva');
        return;
      }

      this.dataReserva.id = data.id;
      this.dataReserva.referencia = data.referencia;
      this.dataReserva.token_reserva = data.token;

      if (data.form && data.form != '') {
        $('body').append(data.form);
        setTimeout(function() {
            if ($('#paypal-form').length) {
              $('#paypal-form').submit();
              $('#paypal-form').remove();
            }
            if ($('#redsys-form').length) {
              $('#redsys-form').submit();
              $('#redsys-form').remove();
            }
            $.LoadingOverlay("hide", true);
        }, 100);
      } else {
        $.LoadingOverlay("hide", true);
        if (this.canjear) {
          this.globals.Api('/pub-get-reserva', {id: data.value}).subscribe((data:any) => {
            if (!data || !data.id) return;
            this.dataReserva = data;
            this.appref.tick();
            this.IframePostMessages();
          });
          return;
        }
        this.ngZone.run(() => this.router.navigateByUrl('/confirmacion-reserva?cliente='+this.id_cliente+'&token='+this.dataReserva.token_reserva)).then();
      }
    }, error => {
      swal('Error', 'Error al hacer la reserva', 'error');
      $.LoadingOverlay("hide", true);
      $.buttoncancelloading('btn-guardar-reserva');
    });
  }
  AnteriorModalInicial() {
    $('#content-paso-1').fadeIn();
    $('#content-paso-2').hide();
    $('#content-paso-2-plazas').hide();
    this.dataReserva.codigo_cupon = null;
    this.dataReserva.cupon = null;
    this.dataReserva.total = null;
    this.dataReserva.mostracuponok = null;
    this.dataReserva.mostracuponko = null;
    this.tarifas = [];
    this.asientos = [];
    this.sesiones = [];
    this.dataReserva.precio = 0;
    this.IframePostMessages();
  }
  AnteriorModal() {
    $('#content-paso-1').fadeIn();
    $('#content-paso-2').hide();
    this.dataReserva.codigo_cupon = null;
    this.dataReserva.cupon = null;
    this.dataReserva.total = null;
    this.dataReserva.mostracuponok = null;
    this.dataReserva.mostracuponko = null;
    this.tarifas = [];
    this.asientos = [];
    this.sesiones = [];
    this.dataReserva.precio = 0;
    this.IframePostMessages();
  }
  AnteriorModal2() {
    $('#content-paso-2-plazas').fadeIn();
    $('#content-paso-2').hide();
    this.IframePostMessages();
  }
  AnteriorModal3() {
    $('#content-paso-2').fadeIn();
    $('#content-paso-3-pago').hide();
    this.IframePostMessages();
  }
  SiguienteModalPlazas() {
    $('#content-paso-1').hide();
    $('#content-paso-2-plazas').fadeIn();
    window.location.hash = '#content-paso-2';
    this.IframePostMessages();
  }
  SiguienteModalPago() {
    /*-------------Validación-------------*/
    if (this.dataReserva.fecha == null || this.dataReserva.fecha == '' || this.dataReserva.fecha == '0000-00-00') {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', LG('Fecha incorrecta'), 'error');
      return;
    }
    if (this.dataReserva.sesion == null || this.dataReserva.sesion == '' && !this.regalar) {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', LG('Sesión incorrecta'), 'error');
      return;
    }
    if (!this.dataReserva.nombre || !this.dataReserva.apellidos || this.dataReserva.nombre == '' || this.dataReserva.apellidos == '') {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', LG('Rellena el nombre y los apellidos'), 'error');
      return;
    }
    if (!this.dataReserva.telefono || this.dataReserva.telefono == '') {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', LG('Rellena el teléfono'), 'error');
      return;
    }
    if (!this.dataReserva.cli_codpostal || this.dataReserva.cli_codpostal == '') {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', LG('Rellena el código postal'), 'error');
      return;
    }
    if (!this.dataReserva.email || this.dataReserva.email == '') {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', LG('Rellena el email'), 'error');
      return false;
    }
    if (!this.globals.ValidateEmail(this.dataReserva.email)) {
      swal('Error', 'Email incorrecto', 'error');return;
    }
    if (
      !$('#check-politicas-privacidad').is(':checked')
    ) {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Política de privacidad', 'Debe marcar que ha leído y acepta la política de privacidad para continuar', 'error');
      return;
    }
    /*-------------End Validación-------------*/
    $('#content-paso-1').hide();
    $('#content-paso-2-plazas').hide();
    $('#content-paso-2').hide();
    $('#content-paso-3-pago').fadeIn();
    setTimeout(()=>{
      $('.formas-pago').click(function() {
        $('.formas-pago').removeClass('active');
        $(this).addClass('active');
      });
    },100);
    if (this.admin || this.colaborador) {
      let formapago:any = this.formas_pago.find((el:any) => el.slug == 'pago-mano' && el.idioma == 'es');
      this.SetFormaPago(formapago);
      this.appref.tick();
    }
    this.IframePostMessages();
    setTimeout(() => {this.IframePostMessages();}, 300);
  }
  SiguienteModal() {
    if (this.num_reservas_dias_check == 0) {
      let contTotalPlazas = 0;
      if (this.tarifas && this.tarifas.length) {
        this.tarifas.forEach(item => {
          contTotalPlazas += item.cantidad;
        });
      }
      if (this.actividad.aforo_minimo > contTotalPlazas) {
        swal('¡Atención!', 'Debes de seleccionar un mínimo de '+this.actividad.aforo_minimo+' plazas para esta sesión', 'warning');
        return;
      }
    }
    var radiosSeleccion = $('.radios-sesion');
    var checkRadio = false;
    for(var i = 0; i < radiosSeleccion.length; i++) {
      if ($(radiosSeleccion[i]).is(':checked')) {
        checkRadio = true;
      }
    }
    this.dataReserva.id_actividad = $('#select-actividad-reservar').val();
    if (this.dataReserva.id_actividad == '') {
      swal(LG('¡Atención!'), LG('Debe seleccionar una actividad'), 'error');
      return;
    }
    if (!checkRadio) {
      swal(LG('¡Atención!'), LG('Debe seleccionar una sesión'), 'error');
      return;
    }
    let adulto = false;
    if (this.tarifas && this.tarifas.length) {
      this.tarifas.forEach(element => {
        if (element.nombre.search('Niño') == -1 && element.nombre.search('Niños') == -1 && element.nombre.search('niño') == -1 && element.nombre.search('niños') == -1) {
          if (element.cantidad > 0) {
            adulto = true;
          }
        }
      });
    }
    if (!adulto) {
      swal(LG('¡Atención!'), 'Los niños menores deben de ir acompañados al menos de un adulto', 'warning');
      return;
    }

    $('#content-paso-1').hide();
    $('#content-paso-2-plazas').hide();
    $('#content-paso-2').fadeIn();
    window.location.hash = '#content-paso-2';
    this.dataReserva.tarifas = this.tarifas;
    this.IframePostMessages();
  }
  calcularPrecioModal() {
    if(!this.descuentoAplicado){
      this.precioSinDescuento = this.dataReserva.precio;
      this.descuentoAplicado = true;
    }
    this.dataReserva.precio = this.precioSinDescuento;
    if(!this.descuento || this.descuento == 0){
      this.descuentoAplicado = false;
      return;
    }
    if(this.dataReserva.precio <= 0) return;
    if(this.tipoDescuento == 'fijo'){
      this.dataReserva.precio = this.dataReserva.precio - this.descuento;
    }else{
      this.dataReserva.precio = this.dataReserva.precio - (this.dataReserva.precio * this.descuento / 100);
    }
  }
  VolverPaso1() {
    $('#content-paso-3').hide();
    $('#content-paso-1').fadeIn();
    this.dataReserva.codigo_cupon = null;
    this.dataReserva.cupon = null;
    this.dataReserva.total = null;
    this.dataReserva.mostracuponok = null;
    this.dataReserva.mostracuponko = null;
    this.tarifas = [];
    this.asientos = [];
    this.dataReserva.precio = 0;
    this.IframePostMessages();
  }
  VolverPaso2Plazas() {
    $('#content-paso-error').hide();
    $('#content-paso-2').hide();
    $('#content-paso-2-plazas').fadeIn();
    this.IframePostMessages();
  }
  VolverPaso2() {
    $('#content-paso-error').hide();
    $('#content-paso-2').fadeIn();
    this.IframePostMessages();
  }
  SetFormaPago(item:any) {
    this.forma_pago_seleccionada = item;
    this.dataReserva.cli_tipo_pago = item.forma_pago;
    this.dataReserva.cli_tipo_pago_slug = item.slug;
    this.dataReserva.pagos = [{
      cli_tipo_pago: this.dataReserva.cli_tipo_pago,
      cli_tipo_pago_slug: this.dataReserva.cli_tipo_pago_slug
    }];
    $('#stripe-form #card-element').html('');
    $('#stripe-form #card-errors').html('');
    $('#stripe-form').hide();
    if (!item) return;
    if (item.slug == 'stripe') {
      if (!this.globals.config.pk_test || this.globals.config.pk_test == '') return;
      if (!this.globals.config.pk_live || this.globals.config.pk_live == '') return;
      let that = this;
      if (this.globals.config.st_entorno_pruebas) {
        this.stripe = Stripe(this.globals.config.pk_test);
      } else {
        this.stripe = Stripe(this.globals.config.pk_live);
      }
      var elements = this.stripe.elements();
      $('#stripe-form').show();
      var style = {
        base: {
          fontSize: '16px',
          color: 'var(--color-primary)',
        },
      };
      this.stripe_card = elements.create('card', {style: style, hidePostalCode: true});
      this.stripe_card.mount('#card-element');
    }
  }
  ComprobarCupon() {
    if (!this.dataReserva.codigo_cupon || this.dataReserva.codigo_cupon.trim() == '') return;
    if (!this.dataReserva.email || this.dataReserva.email.trim() == '') return;
    if (!this.dataReserva.precio) return;
    this.dataReserva.cupon = null;
    this.dataReserva.total = null;
    this.dataReserva.precio_original = this.dataReserva.precio;
    clearTimeout($.idtimecupon);
    $.idtimecupon = setTimeout(() => {
      this.globals.Api('/pub-check-cupon', {
        codigo: this.dataReserva.codigo_cupon.trim(),
        cli_email: this.dataReserva.email,
        total: this.dataReserva.precio,
        id_actividad: this.dataReserva.id_actividad,
        id_sesion: this.dataReserva.id_sesion,
        fecha: this.dataReserva.fecha
      }).subscribe((data:any) => {
        if (!data) return;
        if (data.valido) {
          this.dataReserva.cupon = data.datos;
          this.dataReserva.tienecupon = true;
          this.dataReserva.mostracuponok = true;
          this.dataReserva.mostracuponko = false;
          if (this.dataReserva.cupon.tipo == 'porcentaje') {
            this.dataReserva.total = this.dataReserva.precio - (this.dataReserva.precio * this.dataReserva.cupon.importe / 100);
          } else {
            this.dataReserva.total = this.dataReserva.precio - this.dataReserva.cupon.importe;
          }
        } else {
          this.dataReserva.mostracuponok = false;
          this.dataReserva.mostracuponko = true;
        }
      });
    }, 400);
  }
  setSesionCheck(sesion, aforo_restante, id) {
    this.dataReserva.precio = 0;
    this.sesionSelect = sesion;
    $('.content-radio-asiento').removeClass('active');
    //Solo para Canjear
    if (this.canjear) {
      this.dataCanjearBono.puedepaso2 = true;
      this.dataCanjearBono.sesion = sesion;
      let plazasBono:number = this.dataCanjearBono.bono.adultos + this.dataCanjearBono.bono.ninios;
      this.plazasDisponibles = parseInt(aforo_restante);
      if (this.plazasDisponibles < plazasBono) {
        this.dataCanjearBono.puedepaso2 = false;
        this.dataCanjearBono.mensajesesiones = '* No ha plazas disponibles para esta sesión';
      }
      return;
    }
    //****************/
    this.globals.Api('/pub-check-hay-reservas',{id_actividad: this.dataReserva.id_actividad, sesion: sesion, fecha: this.dataReserva.fecha}).subscribe(data => {
      if (!data || data == '') {
        return;
      }
      this.num_reservas_dias_check = data.num_reservas;
    });
    delete this.dataReserva.codigo_cupon;
    delete this.dataReserva.cupon;
    delete this.dataReserva.tienecupon;
    delete this.dataReserva.mostracuponok;
    delete this.dataReserva.mostracuponko;
    this.dataReserva.sesion = sesion;
    this.dataReserva.id_sesion = id;
    this.plazasDisponibles = parseInt(aforo_restante);
    let contTotalPlazas = 0;
    for (let item of this.tarifas) {
      item.cantidad = 0;
      item.total = 0;
    }
    if (this.plazasDisponibles <= contTotalPlazas) {
      if (this.tarifas && this.tarifas.length) {
        this.tarifas.forEach(item => {
          item.cantidad = 0;
        });
      }
      this.calcularPrecioModal();
      $('.custom-mas-icon').removeClass('disabled-custom-input');
      $('.custom-menos-icon').addClass('disabled-custom-input');
    } else {
      $('.custom-menos-icon').addClass('disabled-custom-input');
      $('.custom-mas-icon').removeClass('disabled-custom-input');
    }
    if (this.plazasDisponibles == 0) {
      $('.custom-mas-icon').addClass('disabled-custom-input');
      $('.custom-menos-icon').addClass('disabled-custom-input');
    }
    if (this.actividad.gestion_asientos) {
      this.globals.Api('/pub-check-asientos-adicionales',{id_actividad: this.dataReserva.id_actividad, sesion: sesion, fecha: this.dataReserva.fecha}).subscribe(data => {
        if (!data || data == '') {
          this.tarifas = [];
          $('.radios-asientos').prop('checked', false);
          this.sesiones.forEach(item => {
            if (item.sesion == sesion) {
              if (item.asientoPersonas) {
                item.asientoPersonas.forEach(asientoPersona => {
                    this.asientos.forEach(asiento => {
                      if (asiento.nombre == asientoPersona.asiento) {
                        asiento.plazas = asientoPersona.total_asientos - asientoPersona.personas;
                        asiento.num_asientos = asientoPersona.total_asientos;
                      }
                    });
                });
              }
            }
          });
          return;
        }
        this.tarifas = [];
        $('.radios-asientos').prop('checked', false);
        this.sesiones.forEach(item => {
          if (item.sesion == sesion) {
            if (item.asientoPersonas) {
              item.asientoPersonas.forEach(asientoPersona => {
                  this.asientos.forEach(asiento => {
                    if (asiento.nombre == asientoPersona.asiento) {
                      asiento.plazas = asientoPersona.total_asientos - asientoPersona.personas;
                      if(asiento.id == data.id_asiento){
                        asiento.num_asientos = asientoPersona.total_asientos;
                        asiento.num_asientos += data.plazas_asiento_adicionales;
                        asiento.plazas += data.plazas_asiento_adicionales;
                      }
                    }
                  });
              });
            }
          }
        });
      });
    }
    this.IframePostMessages();
  }
  SeleccionarActividad() {
    this.tarifas = [];
    this.dataReserva.id_actividad = $('#select-actividad-reservar').val();
    if (this.canjear && this.dataReserva.id_actividad != '') {
      this.dataCanjearBono.bono.adultos = 0;
      this.dataCanjearBono.bono.ninios = 0;
      $('.custom-mas-icon').removeClass('disabled-custom-input');
      $('.custom-menos-icon').removeClass('disabled-custom-input');
    }
    this.asientos = [];
  }
  loadActividades() {
    this.globals.Api('/pub-eve-actividades-dashboard').subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar actividades', 'error');return;
      }
      this.actividades = data;
      this.IframePostMessages();
    });
  }
  CheckActividadSelect() {
    if (this.dataReserva.fecha == null || this.dataReserva.fecha == '' || this.dataReserva.fecha == '0000-00-00') {
      swal('Aviso', 'Debe seleccionar previamente una fecha', 'warning');return;
    }
  }
  SetLocalidad() {
    this.globals.Api('/localidad', {codpostal: this.dataReserva.cli_codpostal}).subscribe(data => {
      if (!data || data.error) {
        // swal('Error', 'Se ha producido un error', 'error');return;
      }
      this.dataReserva.cli_localidad = data.localidad;
      this.dataReserva.cli_provincia = data.provincia;
    }, error => {
      // toastr.error(`Al bloquear sesión`, 'Error');
    });
  }
  setReservaRegaloCanjear(idButton) {
    this.actividad.id = null;
    this.sesiones = [];
    this.dataReserva.id_actividad = '';
    this.actividades = [];
    this.precioAdulto = null;
    this.precioNinio = null;
    this.dataReserva.precio = 0;
    this.dataReserva.fecha = null;
    switch(idButton) {
      case 'reservar':{
        this.reservar = true;
        this.regalar = false;
        this.canjear = false;
        $('#calendar').show();
        $('#btn-regalar').removeClass('selected');
        $('#btn-canjear').removeClass('selected');
        $('#btn-reservar').addClass('selected');
        $('#btn-regalar-movil').removeClass('selected');
        $('#btn-canjear-movil').removeClass('selected');
        $('#btn-reservar-movil').addClass('selected');
        $('#custom-mas-adulto').addClass('disabled-custom-input');
        $('#custom-mas-ninio').addClass('disabled-custom-input');
        $('#custom-menos-adulto').addClass('disabled-custom-input');
        $('#custom-menos-ninio').addClass('disabled-custom-input');
        break;
      }
      case 'regalar':{
        this.reservar = false;
        this.regalar = true;
        this.canjear = false;
        $('#calendar').hide();
        $('#btn-reservar').removeClass('selected');
        $('#btn-canjear').removeClass('selected');
        $('#btn-regalar').addClass('selected');
        $('#btn-reservar-movil').removeClass('selected');
        $('#btn-canjear-movil').removeClass('selected');
        $('#btn-regalar-movil').addClass('selected');
        this.dataReserva.fecha = this.globals.DateStringEN(new Date().toDateString());
        $('#date-reservar').val(this.dataReserva.fecha);
        this.globals.Api('/eve-actividades',{web: 1, dia: this.dataReserva.fecha}).subscribe(data => {
          if (!data || data.error) {
            swal('Error', 'Error al cargar datos evento', 'error');return;
          }
          this.actividades = data;
          $('#select-actividad-reservar').off('change');
          let that = this;
          $('#select-actividad-reservar').change(function() {
            let idEventoSelect = $(this).val();
            that.dataReserva.precio = 0;
            that.tarifas.forEach(element => {
              element.cantidad = 0;
              element.total = 0;
            });
            that.plazasDisponibles = -1;
            that.calcularPrecioModal();
            $('#custom-menos-adulto').addClass('disabled-custom-input');
            $('#custom-menos-ninio').addClass('disabled-custom-input');
            if (idEventoSelect == 'default') return;
            that.tarifas = [];
            that.globals.Api('/eve-actividades',{id: idEventoSelect}).subscribe(data => {
              if (!data || data.error) {
                swal('Error', 'Error al cargar datos evento', 'error');return;
              }
              that.actividad = data[0];
              if (that.actividad.vehiculo) {
                // Cálculo disponibilidad condicionante vehículos
                that.sesiones = null;
                that.globals.Api('/eve-actividades-sesiones-vehiculos-v2',{id: that.actividad.id, fecha: that.dataReserva.fecha}).subscribe(data => {
                  if (!data || data.error) {
                    swal('Error', 'Error al cargar datos sesiones', 'error');return;
                  }
                  if (data[1].length > 0) {
                    that.sesiones = data[1];
                    that.precioAdulto = data[1][0].precio_adultos;
                    that.precioNinio = data[1][0].precio_ninios;

                  } else {
                    that.sesiones = data[0];
                    that.precioAdulto = that.actividad.precio;
                    that.precioNinio = that.actividad.precio_ninios;
                  }
                  $('.content-radio-sesion>label').click(function() {
                    $('.content-radio-sesion>label').removeClass('active');
                    $(this).addClass('active');
                  });
                  var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
                  var d = new Date(that.dataReserva.fecha);
                  var dayName = days[d.getDay()];
                  that.globals.Api('/check-dia-disponible-v2',{id: that.actividad.id, dia: dayName, fecha: that.dataReserva.fecha}).subscribe(data => {
                    if (!data || data.error) {
                      swal('Error', 'Error al cargar datos sesiones', 'error');return;
                    }
                    if (data[0].count == 0 && data[0].dias_especial_disponible == 0) {
                      that.sesiones = null;
                    } else {
                      for(let i = 0; i < that.sesiones.length; i++) {
                        let sesion = that.sesiones[i].sesion;
                        that.globals.Api('/eve-actividades-check-vehiculos-v2',{id: that.actividad.id, fecha: that.dataReserva.fecha, sesion: sesion}).subscribe(data => {
                          if (!data || data.error) {
                            swal('Error', 'Error al verificar vehículos', 'error');return;
                          }
                          let plazasDisponibles = 0;
                          let plazasDisponiblesSinCoche = that.sesiones[i].aforo + that.sesiones[i].plazas_adicionales;
                          that.sesiones[i].aforo_restante = that.sesiones[i].aforo_restante + that.sesiones[i].plazas_adicionales;
                          data.forEach(vehiculo => {
                            plazasDisponibles += vehiculo.plazas_disponibles;
                          });
                          if(that.sesiones[i].aforo_restante > plazasDisponibles){
                            that.sesiones[i].aforo_restante = plazasDisponibles;
                          }
                          if(plazasDisponibles > plazasDisponiblesSinCoche){
                            that.sesiones[i].aforo_restante = plazasDisponiblesSinCoche;
                          }
                        });
                      }
                    }
                  });
                });
              } else {
                // Cálculo disponibilidad normal sin vehículos
                that.sesiones = null;
                that.globals.Api('/pub-eve-actividades-sesiones-v2',{id: that.actividad.id, fecha: that.dataReserva.fecha}).subscribe(data => {
                  if (!data || data.error) {
                    swal('Error', 'Error al cargar datos sesiones', 'error');return;
                  }
                  if (data[1] && data[1].length > 0) {
                    that.sesiones = data[1];
                    that.precioAdulto = data[1][0].precio_adultos;
                    that.precioNinio = data[1][0].precio_ninios;

                  } else {
                    that.sesiones = data[0];
                    that.precioAdulto = that.actividad.precio;
                    that.precioNinio = that.actividad.precio_ninios;
                  }
                  $('.content-radio-sesion>label').click(function() {
                    $('.content-radio-sesion>label').removeClass('active');
                    $(this).addClass('active');
                  });
                  var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
                  var d = new Date(that.dataReserva.fecha);
                  var dayName = days[d.getDay()];
                  that.globals.Api('/pub-check-dia-disponible-v2',{id: that.actividad.id, dia: dayName, fecha: that.dataReserva.fecha}).subscribe(data => {
                    if (!data || data.error) {
                      swal('Error', 'Error al cargar datos sesiones', 'error');return;
                    }
                    if (data[0].count == 0 && data[0].dias_especial_disponible == 0) {
                      that.sesiones = null;
                    } else {
                      if (!that.regalar) {
                        for(let i = 0; i < that.sesiones.length; i++) {
                          that.sesiones[i].aforo_restante = that.sesiones[i].aforo_restante + that.sesiones[i].plazas_adicionales;
                          if (that.sesiones[i].aforo_restante < 0) that.sesiones[i].aforo_restante = 0;
                        }
                      } else {
                        if (that.actividad.gestion_asientos) {
                          that.globals.Api('/eve-actividades-asientos-actividad',{id: that.actividad.id}).subscribe(data => {
                            if (!data || data.error) {
                              swal('Error', 'Error al cargar datos sesiones', 'error');return;
                            }
                            that.asientos = data;
                            console.log(that.sesionSelect);
                            setTimeout(()=>{
                              $('.content-radio-asiento').click(function() {
                                $('.content-radio-asiento').removeClass('active');
                                $(this).addClass('active');
                              });
                            },100);
                          });
                        } else {
                          that.globals.Api('/eve-actividades-tarifas-por-persona-actividad',{id: that.actividad.id, fecha: that.dataReserva.fecha}).subscribe(data => {
                            that.tarifas = data;
                            that.dataReserva.precio = 0;
                            that.tarifas.forEach(element => {
                              element.cantidad = 0;
                              element.total = 0;
                            });
                          });
                        }
                      }
                    }
                  });
                });
              }
            });
          });
          if (this.actividades.length == 1) {
            setTimeout(() => {
              $('#select-actividad-reservar').val(this.actividades[0].id);
              $('#select-actividad-reservar').trigger('change');
              this.appref.tick();
            }, 400);
          }
        });
        break;
      }
      case 'canjear':{
        this.reservar = false;
        this.regalar = false;
        this.canjear = true;
        $('#calendar').show();
        $('#btn-reservar').removeClass('selected');
        $('#btn-regalar').removeClass('selected');
        $('#btn-canjear').addClass('selected');
        $('#btn-reservar-movil').removeClass('selected');
        $('#btn-regalar-movil').removeClass('selected');
        $('#btn-canjear-movil').addClass('selected');
        $('#custom-mas-adulto').addClass('disabled-custom-input');
        $('#custom-mas-ninio').addClass('disabled-custom-input');
        $('#custom-menos-adulto').addClass('disabled-custom-input');
        $('#custom-menos-ninio').addClass('disabled-custom-input');
        break;
      }
      default:{
        break;
      }
    }
    this.appref.tick();
  }
  SiguienteModalRegalar() {
    if (this.dataReserva.id_actividad == '') {
      swal(LG('¡Atención!'), LG('Debe seleccionar una actividad'), 'warning');
      return;
    }
    let adulto = false;
    if (this.tarifas && this.tarifas.length) {
      this.tarifas.forEach(element => {
        if (element.nombre.search('Niño') == -1 && element.nombre.search('Niños') == -1 && element.nombre.search('niño') == -1 && element.nombre.search('niños') == -1) {
          if (element.cantidad > 0) {
            adulto = true;
          }
        }
      });
    }
    if (!adulto) {
      swal(LG('¡Atención!'), 'Los niños menores deben de ir acompañados al menos de un adulto', 'warning');
      return;
    }
    $('#content-paso-1').hide();
    $('#content-paso-2-plazas').hide();
    $('#content-paso-2').fadeIn();
    window.location.hash = '#content-paso-2';
    this.IframePostMessages();
  }
  SetLocalidadRegalo() {
    this.globals.Api('/localidad', {codpostal: this.dataPersonaRegala.codpostal}).subscribe(data => {
      if (!data || data.error) {
        // swal('Error', 'Se ha producido un error', 'error');return;
      }
      this.dataPersonaRegala.localidad = data.localidad;
      this.dataPersonaRegala.provincia = data.provincia;
    }, error => {
      // toastr.error(`Al bloquear sesión`, 'Error');
    });
  }
  ComprobarGuardarBono() {
    if (
      this.dataReserva.adulto == 0 || this.dataReserva.precio == 0 ||
      this.dataReserva.nombre == '' || this.dataReserva.apellidos == '' || this.dataReserva.email == '' || this.dataReserva.telefono == ''||
      this.dataPersonaRegala.nombre == '' || this.dataPersonaRegala.apellidos == '' || this.dataPersonaRegala.email == '' || this.dataPersonaRegala.telefono == '' ||
      !this.dataPersonaRegala.codpostal || this.dataPersonaRegala.codpostal == '' || !this.dataPersonaRegala.localidad || this.dataPersonaRegala.localidad == ''
      ) {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', LG('Revisa los datos'), 'error');
      return;
    }
    if (!this.globals.ValidateEmail(this.dataReserva.email)) {
      swal('Error', 'Email incorrecto', 'error');return;
    }
    if (!this.globals.ValidateEmail(this.dataPersonaRegala.email)) {
      swal('Error', 'Email incorrecto', 'error');return;
    }
    if (
      !$('#check-politicas-privacidad').is(':checked')
    ) {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Política de privacidad', 'Debe marcar que ha leído y acepta la política de privacidad para continuar', 'error');
      return;
    }
    $.buttonloading('btn-guardar-reserva', 'Realizando pago ...');
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.6)',
      imageResizeFactor: 0.5,
      text: 'Realizando pago ...'
    });
    if (this.dataReserva.cli_tipo_pago == undefined) {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', 'Selecciona un tipo de pago', 'warning');return;
    }
    this.GuardarBono();
  }
  GuardarBono() {
    this.dataReserva.pagos = [{
      cli_tipo_pago: this.dataReserva.cli_tipo_pago,
      cli_tipo_pago_slug: this.dataReserva.cli_tipo_pago_slug
    }];
    if (this.dataReserva.cupon) {
      this.dataReserva.precio = this.dataReserva.total;
    }

    // if (gtag) {
    //   gtag('event', 'conversion', {'send_to': 'AW-10882867819/poZJCL_e-LUDEOvErcUo',
    //     'value': this.dataReserva.precio,
    //     'currency': 'EUR'
    //   });
    // }
    this.dataReserva.id_pago = 1;
    this.dataReserva.id_estado_bono = 1;
    if (this.forma_pago_seleccionada && this.forma_pago_seleccionada.slug == 'stripe' && this.stripe) {
      let that = this;
      this.stripe.createToken(this.stripe_card).then(function(result) {
        if (result.error) {
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
          $.buttoncancelloading('btn-guardar-reserva');
        } else {
          that.dataReserva.stripe_token = result.token.id;
          that.globals.Api('/pub-stripe-pago-bono', {data: that.dataReserva, data_regala: that.dataPersonaRegala}).subscribe(data => {
            if (!data || !data.value) {
              $.LoadingOverlay("hide", true);
              $.buttoncancelloading('btn-guardar-reserva');
              swal('Error en el pago', 'Revisa los datos de tu tarjeta', 'error');return;
            }
            setTimeout(() => {
              $.LoadingOverlay("hide", true);
              $.buttoncancelloading('btn-guardar-reserva');
              that.dataReserva.stripe_order = data.value;
              that.dataReserva.id_pago = 3;
              that.dataReserva.id_estado_bono = 2;
              that.EnviarDatosBono();
            }, 200);
          }, error => {
            $.LoadingOverlay("hide", true);
            $.buttoncancelloading('btn-guardar-reserva');
            swal('Error en el pago', 'Revisa los datos de tu tarjeta', 'error');
          });
        }
      });
      return;
    }
    this.EnviarDatosBono();
  }

  EnviarDatosBono() {
    $.LoadingOverlay("hide", true);
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.6)',
      imageResizeFactor: 0.5,
      text: 'Enviando datos ...'
    });
    $.buttonloading('btn-guardar-reserva', 'Enviando datos ...');
    this.dataReserva.codigo = this.dataReserva.id_actividad +''+this.dataReserva.email.substring(0,2).toUpperCase()+''+this.getRandomInt(10000,99999)+''+this.dataPersonaRegala.email.substring(0,2).toUpperCase();
    this.globals.Api('/eve-bonos-calendar-add',{datos: this.dataReserva, datos_persona_regala: this.dataPersonaRegala, id_actividad: this.dataReserva.id_actividad}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al hacer la reserva', 'error');
        $('#content-paso-2').hide();
        $('#content-paso-error').fadeIn();
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('btn-guardar-reserva');
        return;
      }

      if (data.form && data.form != '') {
        $('body').append(data.form);
        setTimeout(function() {
            if ($('#paypal-form').length) {
              $('#content-paso-2').hide();
              $('#content-paso-4').fadeIn();
              $('#paypal-form').submit();
              $('#paypal-form').remove();
            }
            if ($('#redsys-form').length) {
              $('#content-paso-2').hide();
              $('#content-paso-4').fadeIn();
              $('#redsys-form').submit();
              $('#redsys-form').remove();
              $.LoadingOverlay("hide", true);
            }
        }, 100);
      } else {
        $.LoadingOverlay("hide", true);
        // swal('¡Reserva realizada con éxito!', 'Recibirá un correo a la dirección facilitada con la confirmación de la reserva.', 'success');
        $('#content-paso-2').hide();
        $('#content-paso-3').fadeIn();
        this.IframePostMessages();
        if (this.forma_pago_seleccionada && this.forma_pago_seleccionada.slug == 'stripe' && this.dataReserva.stripe_order) {
          this.globals.Api('/pub-get-reserva-bono', {stripe_order: this.dataReserva.stripe_order}).subscribe(data => {
            if (!data || !data.id) return;
            this.dataReserva = data;
            this.dataReserva.CompraBono = true;
            this.appref.tick();
          });
        }
      }
    }, error => {
      $.LoadingOverlay("hide", true);
      $.buttoncancelloading('btn-guardar-reserva');
    });
  }
  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  //Canjear
  InputCodigoCanjear() {
    this.dataCanjearBono.selsesion = null;
    this.dataCanjearBono.bono = null;
    if (!this.dataCanjearBono.codigo || this.dataCanjearBono.codigo == '' || this.dataCanjearBono.codigo.length < 4) {
      return;
    }
    clearTimeout($.IDTimeInputCodigo);
    $.IDTimeInputCodigo = setTimeout(() => {
      this.globals.Api('/pub-check-bono-regalo', {codigo: this.dataCanjearBono.codigo}).subscribe((data:any) => {
        if (!data) return;
        this.dataCanjearBono.bono = data;
        this.dataCanjearBono.selsesion = true;
        this.actividad.id = data.id_actividad;
        this.dataCanjearBono.fecha = moment().format('YYYY-MM-DD');
        this.CargarSesionesCanjear();
        this.appref.tick();
      });
    }, 400);
  }
  CargarSesionesCanjear() {
    let params = {
      fecha: moment(this.dataCanjearBono.fecha),
      fecha_format: moment(this.dataCanjearBono.fecha).format('DD/MM/YYYY'),
      fecha_format_en: moment(this.dataCanjearBono.fecha).format('YYYY-MM-DD')
    };
    this.dataCanjearBono.mensajesesiones = null;
    $('#custom-menos-adulto').addClass('disabled-custom-input');
    $('#custom-menos-ninio').addClass('disabled-custom-input');
    this.globals.Api('/eve-actividades',{id: this.dataCanjearBono.bono.id_actividad}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al cargar datos evento', 'error');return;
      }
      this.actividad = data[0];
      if (this.actividad.vehiculo) {
        // Cálculo disponibilidad condicionante vehículos
        this.sesiones = null;
        this.globals.Api('/eve-actividades-sesiones-vehiculos-v2',{id: this.actividad.id, fecha: params.fecha_format_en}).subscribe(data => {
          if (!data || data.error) {
            swal('Error', 'Error al cargar datos sesiones', 'error');return;
          }
          if (data[1].length > 0) {
            this.sesiones = data[1];
          } else {
            this.sesiones = data[0];
          }
          var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
          var d = new Date(params.fecha_format_en);
          var dayName = days[d.getDay()];
          this.globals.Api('/check-dia-disponible-v2',{id: this.actividad.id, dia: dayName, fecha: params.fecha_format_en}).subscribe(data => {
            if (!data || data.error) {
              swal('Error', 'Error al cargar datos sesiones', 'error');return;
            }
            if (data[0].count == 0 && data[0].dias_especial_disponible == 0) {
              this.sesiones = null;
            } else {
              for(let i = 0; i < this.sesiones.length; i++) {
                let sesion = this.sesiones[i].sesion;
                this.globals.Api('/eve-actividades-check-vehiculos-v2',{id: this.actividad.id, fecha: params.fecha_format_en, sesion: sesion}).subscribe(data => {
                  if (!data || data.error) {
                    swal('Error', 'Error al verificar vehículos', 'error');return;
                  }
                  let plazasDisponibles = 0;
                  let plazasDisponiblesSinCoche = this.sesiones[i].aforo + this.sesiones[i].plazas_adicionales;
                  this.sesiones[i].aforo_restante = this.sesiones[i].aforo_restante + this.sesiones[i].plazas_adicionales;
                  data.forEach(vehiculo => {
                    plazasDisponibles += vehiculo.plazas_disponibles;
                  });
                  if(this.sesiones[i].aforo_restante > plazasDisponibles){
                    this.sesiones[i].aforo_restante = plazasDisponibles;
                  }
                  if(plazasDisponibles > plazasDisponiblesSinCoche){
                    this.sesiones[i].aforo_restante = plazasDisponiblesSinCoche;
                  }
                });
              }
            }
          });
        });
      } else {
        // Cálculo disponibilidad normal sin vehículos
        this.sesiones = null;
        this.globals.Api('/pub-eve-actividades-sesiones-v2',{id: this.actividad.id, fecha: params.fecha_format_en}).subscribe(data => {
          if (!data || data.error) {
            swal('Error', 'Error al cargar datos sesiones', 'error');return;
          }
          if (data[1] && data[1].length > 0) {
            this.sesiones = data[1];

          } else {
            this.sesiones = data[0];
          }
          var days = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
          var d = new Date(params.fecha_format_en);
          var dayName = days[d.getDay()];
          this.globals.Api('/pub-check-dia-disponible-v2',{id: this.actividad.id, dia: dayName, fecha: params.fecha_format_en}).subscribe(data => {
            if (!data || data.error) {
              swal('Error', 'Error al cargar datos sesiones', 'error');return;
            }
            if (data[0].count == 0 && data[0].dias_especial_disponible == 0) {
              this.sesiones = null;
            } else {
              for(let i = 0; i < this.sesiones.length; i++) {
                this.sesiones[i].aforo_restante = this.sesiones[i].aforo_restante + this.sesiones[i].plazas_adicionales;
                if (this.sesiones[i].aforo_restante < 0) this.sesiones[i].aforo_restante = 0;
              }
            }
          });
        });
      }
    });
  }
  SiguienteModalCanjear() {
    let plazasBono:number = this.dataCanjearBono.bono.adultos + this.dataCanjearBono.bono.ninios;
    if (this.plazasDisponibles < plazasBono) return;
    this.dataReserva.id_actividad = this.dataCanjearBono.bono.id_actividad;
    this.dataReserva.fecha = this.dataCanjearBono.fecha;
    this.dataReserva.sesion = this.dataCanjearBono.sesion;
    this.dataReserva.adultos = this.dataCanjearBono.bono.adultos;
    this.dataReserva.ninios = this.dataCanjearBono.bono.ninios;
    this.dataReserva.precio = this.dataCanjearBono.bono.total;
    this.dataReserva.total = this.dataCanjearBono.bono.total;
    this.dataReserva.nombre = this.dataCanjearBono.bono.nombre;
    this.dataReserva.apellidos = this.dataCanjearBono.bono.apellidos;
    this.dataReserva.cli_codpostal = this.dataCanjearBono.bono.codigo_postal;
    this.dataReserva.cli_localidad = this.dataCanjearBono.bono.localidad;
    this.dataReserva.cli_provincia = this.dataCanjearBono.bono.provincia;
    this.dataReserva.telefono = this.dataCanjearBono.bono.telefono;
    this.dataReserva.email = this.dataCanjearBono.bono.email;
    this.dataReserva.bono = this.dataCanjearBono.bono;
    $('#content-paso-1').hide();
    $('#content-paso-2').fadeIn();
    window.location.hash = '#content-paso-2';
    this.IframePostMessages();
  }
  CanjearBono() {
    if (
      this.dataReserva.fecha == null || this.dataReserva.fecha == '' || this.dataReserva.fecha == '0000-00-00' ||
      this.dataReserva.sesion == null || this.dataReserva.sesion == '' ||
      this.dataReserva.adulto == 0 || this.dataReserva.precio == 0 ||
      this.dataReserva.nombre == '' || this.dataReserva.apellidos == '' || this.dataReserva.email == '' || this.dataReserva.telefono == ''
      ) {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Error', LG('Revisa los datos'), 'error');
      return;
    }
    if (!this.globals.ValidateEmail(this.dataReserva.email)) {
      swal('Error', 'Email incorrecto', 'error');return;
    }
    if (
      !$('#check-politicas-privacidad').is(':checked')
    ) {
      $.buttoncancelloading('btn-guardar-reserva');
      $.LoadingOverlay("hide", true);
      swal('Política de privacidad', 'Debe marcar que ha leído y acepta la política de privacidad para continuar', 'error');
      return;
    }
    this.dataReserva.id_estado_bono = 3;
    this.dataReserva.cli_tipo_pago = 'Bono canjeado';
    this.dataReserva.cli_tipo_pago_slug = 'bono';
    this.dataReserva.pagos = [{
      cli_tipo_pago: this.dataReserva.cli_tipo_pago,
      cli_tipo_pago_slug: this.dataReserva.cli_tipo_pago_slug
    }];
    this.EnviarDatosReserva();
  }

  setAsientoCheck(item:any) {
    this.dataReserva.precio = 0;
    this.dataReserva.asiento = item;
    this.tarifas = item.tarifas;
    for (let item of this.tarifas) {
      item.cantidad = 0;
      item.total = 0;
    }
    this.plazasDisponibles = item.num_asientos;
    this.sesiones.forEach(sesion => {
      if (sesion.sesion == this.dataReserva.sesion) {
        if (sesion.asientoPersonas) {
          sesion.asientoPersonas.forEach(item2 => {
            if (item2.asiento == item.nombre) {
              this.plazasDisponibles = this.plazasDisponibles - item2.personas;
            }
          });
        }
      }
    });
    if (this.plazasDisponibles == 0) {
      setTimeout(()=>{
        $('.custom-mas-icon').addClass('disabled-custom-input');
      },500);
    }
    this.appref.tick();
  }

  sumCantidadTarifa(item:any, precio:any) {
    if (this.plazasDisponibles == 0) return;
    if (this.tarifas && this.tarifas.length) {
      this.tarifas.forEach(element => {
        let contTotalPlazas = 0;
        this.tarifas.forEach(item => {
          contTotalPlazas += item.cantidad;
        });
        if (this.plazasDisponibles <= contTotalPlazas) {
          $('.custom-mas-icon').addClass('disabled-custom-input');
          return;
        }
        if (element.id == item.id) {
          element.cantidad++;
          element.total += parseInt(precio);
          this.dataReserva.precio += parseInt(precio);
          $('#menos-'+item.id).removeClass('disabled-custom-input');
        }
        contTotalPlazas = 0;
        this.tarifas.forEach(item => {
          contTotalPlazas += item.cantidad;
        });
        if (this.plazasDisponibles <= contTotalPlazas) {
          $('.custom-mas-icon').addClass('disabled-custom-input');
          return;
        }
      });
    }
  }
  resCantidadTarifa(item:any, precio:any) {
    if (this.plazasDisponibles == 0) return;
    if (this.tarifas && this.tarifas.length) {
      this.tarifas.forEach(element => {
        if (element.id == item.id) {
          if (element.cantidad == 0) {
            $('#menos-'+item.id).addClass('disabled-custom-input');
            return;
          }
          if ($('#menos-'+item.id).hasClass('disabled-custom-input')) return;
          element.cantidad--;
          element.total -= parseInt(precio);
          this.dataReserva.precio -= parseInt(precio);
          if (element.cantidad <= 0) {
            $('#menos-'+item.id).addClass('disabled-custom-input');
          }
        }
      });
    }
    $('.custom-mas-icon').removeClass('disabled-custom-input');
  }
  setTipoPago(idEstado: any, tipoPago:any){
    this.dataReserva.id_estado = idEstado;
    let formapago:any = this.formas_pago.find((el:any) => el.slug == tipoPago && el.idioma == 'es');
    this.SetFormaPago(formapago);
  }
  SelectReservaProveedor(event){
    if($('#select-colaboradores-reserva-admin').val() != 'admin'){
      this.dataReserva.id_colaborador = $('#select-colaboradores-reserva-admin').val();
      //console.log($('#select-colaboradores-reserva-admin').val());
      this.dataReserva.tipo = 'Partner';
    }else{
      this.dataReserva.id_colaborador = '';
      this.dataReserva.tipo = 'Admin';
    }
  }
}
