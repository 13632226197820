import { Component, OnInit, NgZone, ApplicationRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-reservas',
  templateUrl: './reservas.component.html',
  styleUrls: ['./reservas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReservasComponent implements OnInit {
  public misreservas:boolean = (window.location.pathname.indexOf('mis-reservas') != -1);
  public width:number = null;
  public reservas:any = [];
  public reservas_listado:any = [];
  public reservas_dia:any = [];
  public reservas_calendario:any = [];
  public params:any = {
    page_index: 1,
    page_size: 25,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
  };
  public params_dia:any = {};
  public params_calendario:any = {};
  public sesiones:any = [];
  public idCalendario:any;
  public aforo = [];
  public mesesAforo:any = [];
  public actividades = [];
  public actividadesSelect = [];
  public guias = [];
  public guiasSelect = [];
  public asignarGuia = false;

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) {
    $.CargarReservasFecha = (fecha:any = null) => {
      this.CargarReservasFecha(fecha);
    }
  }

  ngOnInit(): void {
    App.init();
    this.width = document.body.clientWidth;
    if (!this.globals.cliente && this.globals.me.id_rol == 0) {
      this.ngZone.run(() => this.router.navigateByUrl('/dashboard-superadmin')).then();
    }
    $.disableinputnumberwheel();
    if (!this.misreservas) $.checkrolredirect(3); //No clientes
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
      this.appref.tick();
    });
    $('thead input:checkbox').click(function() {
      var checkedStatus   = $(this).prop('checked');
      var table           = $(this).closest('table');
      $('tbody input:checkbox', table).each(function() {
          $(this).prop('checked', checkedStatus);
      });
    });
    $.modalreparaciondelete = item => {
      this.Borrar(item);
    };
    $.EditarReserva = (id:string) => {
      this.globals.Api('/eve-reservas', {id: id}).subscribe((data:any) => {
        if (!data || !data.length) return;
        this.Editar(data[0]);
      });
    };
    $.WAReserva = (id:string) => {
      this.globals.Api('/eve-reservas-reenviar-whatsapp-confirmacion', {id: id}).subscribe((data:any) => {
        if (!data || data.error) {
          toastr.error('Error al enviar WhatsApp', 'Error en el envío');
          return;
        }
        toastr.success('Confirmación enviada correctamente por WhatsApp', 'Confirmación enviada');
      });
    }

    // this.LoadAforo();
    // this.CargarReservasFecha();
    this.CargarActividades();
    // this.CargarJSCalendario();
    let fecha = moment(new Date()).format('YYYY-MM-DD');
    this.CargarReservasFecha(fecha);
    $('td.fc-today').click();
    this.LoadGuias();
  }
  LoadGuias() {
    // console.log(this.data.ids_guias);
    this.globals.Api('/get-guias').subscribe(data => {
      if (!data || !data.length) return;
      this.guias = data;
    });
  }
  Actualizar() {
    this.width = document.body.clientWidth;
    if (this.width <= 768) $('.collapse').collapse('hide');
    this.CargarGrid();
    this.CargarReservasFecha(this.params_dia.fecha);
    this.CargarCalendario();
  }
  CargarGrid(paginationload:boolean = true) {
    if (this.globals.me.id_rol == 4) {
      this.params.id_colaborador = this.globals.me.id;
    }
    this.globals.Api('/eve-reservas', this.params).subscribe(data => {
      if (!data || !data.length) {
        this.reservas_listado = [];
        return;
      }
      this.reservas_listado = data;
      this.reservas_listado.forEach(item => {
        if (item.precios != 0) {
          // item.precios = JSON.parse(item.precios);
          item.asiento = item.precios[0].nombre_asiento;
        } else {
          item.precios = [];
        }
      });
      // console.log(this.reservas_listado);
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/eve-reservas/total', this.params).subscribe(data => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    });
  }
  CargarActividades() {
    this.globals.Api('/eve-actividades').subscribe(data => {
      if (!data || !data.length) return;
      this.actividades = data;
      // $('.form-control-chosen-required').chosen();
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    $('.popover').popover('hide');
    this.params.page_index = 1;
    this.params_dia.filtro = this.params.filtro;
    this.params_dia.actividad = $('#select-actividades-header').val();
    this.params_calendario.filtro = this.params.filtro;
    this.CargarGrid();
    this.CargarReservasFecha(this.params_dia.fecha);
  }
  ExportarExcel() {
    let data:any = JSON.parse(JSON.stringify(this.reservas_calendario));
    for (let item of data) {
      if(item.stripe_order){
        item.forma_pago = 'Tarjeta Bancaria';
      }
      if(item.cli_tipo_pago_slug == 'pago-mano'){
        item.forma_pago = 'Pago en mano';
      }
      var preciosTemp = '';
      if(item.precios != 0){
        item.precios.forEach(precio => {
          console.log(precio);
          preciosTemp += precio.nombre + ', Cantidad: ' + precio.cantidad + ' -> Precio: '+ precio.total +' ||  \r';
        });
        item.precios = preciosTemp;
      }
      delete item.id_cliente;
      delete item.id_usuario;
      delete item.id_colaborador;
      delete item.ids_guias;
      delete item.idioma_res;
      delete item.token;
      delete item.anticipo_url_id;
      delete item.image_actividad;
      delete item.id_actividad;
      delete item.sesiones;
      delete item.id_bono;
      delete item.eventos;
      delete item.pagos;
      delete item.id_estado;
      delete item.alta;
      delete item.id;
      delete item.redsys_order;
      delete item.paypal_order;
      delete item.stripe_order;
      delete item.cli_nifcif;
      delete item.cli_empresa;
      delete item.cli_codpostal;
      delete item.cli_direccion;
      delete item.cli_localidad;
      delete item.cli_provincia;
      delete item.cli_pais;
      delete item.cli_tipo_pago;
      delete item.cli_tipo_pago_slug;
      delete item.seo_url;
      delete item.base_imponible;
      delete item.iva;
      delete item.impuestos;
      delete item.total_sin_descuento;
      delete item.confirmada;
      delete item.fallida;
      delete item.validada;
      delete item.anticipo_importe;
      delete item.anticipo_fecha_expiracion;
      delete item.alta_format;
      delete item.fecha_format;
      delete item.fecha_bd;
      delete item.cli_nombre_completo;
      delete item.tipo_reserva;
      delete item.icono_caracteristica_actividad;
      delete item.aforo_predeterminado;
      delete item.aforo_especial;
      delete item.distintivo_color;
      delete item.id_pago;
      delete item.base_imponible;
      delete item.iva_format;
      delete item.impuestos_format;
      delete item.total_format;
      delete item.porcent_anticipo;
      delete item.total_anticipo;
      delete item.restante_anticipo;
      delete item.vehiculo;
      delete item.vehiculo_plazas;
      delete item.vehiculo_2;
      delete item.vehiculo_2_plazas;
      delete item.vehiculo_3;
      delete item.vehiculo_3_plazas;
      delete item.vehiculo_4;
      delete item.vehiculo_4_plazas;
      delete item.plazas_adicionales;
      delete item.codigo_bono;
      delete item.nombre_eventos;
      delete item.image_evento;
      delete item.evento;
      delete item.guias;
      delete item.referencia;
      item.cli_nombre = item.cli_nombre + ' ' + item.cli_apellidos;
      delete item.cli_apellidos;
      delete item.cli_email;
      delete item.codigo_cupon;
      delete item.descuento;
      delete item.alta_hora_format;
      delete item.adultos;
      delete item.ninos;
      delete item.ninos_gratis;
      delete item.base_imponible_format;
      delete item.nombre_actividades;
      delete item.tipo_slug;
      delete item.porcentaje_anticipo;
      item.total = parseFloat(item.total.replace('.',','));
    }
    let nombre:any = 'Reservas '+moment(this.params_dia.fecha).format('DD-MM-YYYY');
    this.globals.ExportarDataExcel(nombre, data);
  }
  ExportarExcelMes() {
    let fecha_inicio = moment(this.globals.firstDateOfMonth(new Date(this.params_dia.fecha))).format('YYYY-MM-DD');
    let fecha_fin = moment(this.globals.lastDateOfMonth(new Date(this.params_dia.fecha))).format('YYYY-MM-DD');
    this.globals.Api('/eve-reservas/',{fecha_inicio:fecha_inicio, fecha_fin:fecha_fin}).subscribe(data => {
      if(!data || data.error) return;
      for (let item of data) {
        if(item.stripe_order){
          item.forma_pago = 'Tarjeta Bancaria';
        }
        if(item.cli_tipo_pago_slug == 'pago-mano'){
          item.forma_pago = 'Pago en mano';
        }
        // console.log(item.precios);
        var preciosTemp = '';
        if(item.precios != 0){
          item.precios.forEach(precio => {
            // console.log(precio);
            preciosTemp += precio.nombre + ', Cantidad: ' + precio.cantidad + ' -> Precio: '+ precio.total +' ||  \r';
          });
          item.precios = preciosTemp;
        }
        delete item.id_cliente;
        delete item.id_usuario;
        delete item.id_colaborador;
        delete item.ids_guias;
        delete item.idioma_res;
        delete item.token;
        delete item.anticipo_url_id;
        delete item.image_actividad;
        delete item.id_actividad;
        delete item.sesiones;
        delete item.id_bono;
        delete item.eventos;
        delete item.pagos;
        delete item.id_estado;
        delete item.alta;
        delete item.id;
        delete item.redsys_order;
        delete item.paypal_order;
        delete item.stripe_order;
        delete item.cli_nifcif;
        delete item.cli_empresa;
        delete item.cli_codpostal;
        delete item.cli_direccion;
        delete item.cli_localidad;
        delete item.cli_provincia;
        delete item.cli_pais;
        delete item.cli_tipo_pago;
        delete item.cli_tipo_pago_slug;
        delete item.seo_url;
        delete item.base_imponible;
        delete item.iva;
        delete item.impuestos;
        delete item.total_sin_descuento;
        delete item.confirmada;
        delete item.fallida;
        delete item.validada;
        delete item.anticipo_importe;
        delete item.anticipo_fecha_expiracion;
        delete item.alta_format;
        delete item.fecha_format;
        delete item.fecha_bd;
        delete item.cli_nombre_completo;
        delete item.tipo_reserva;
        delete item.icono_caracteristica_actividad;
        delete item.aforo_predeterminado;
        delete item.aforo_especial;
        delete item.distintivo_color;
        delete item.id_pago;
        delete item.base_imponible;
        delete item.iva_format;
        delete item.impuestos_format;
        delete item.total_format;
        delete item.porcent_anticipo;
        delete item.total_anticipo;
        delete item.restante_anticipo;
        delete item.vehiculo;
        delete item.vehiculo_plazas;
        delete item.vehiculo_2;
        delete item.vehiculo_2_plazas;
        delete item.vehiculo_3;
        delete item.vehiculo_3_plazas;
        delete item.vehiculo_4;
        delete item.vehiculo_4_plazas;
        delete item.plazas_adicionales;
        delete item.codigo_bono;
        delete item.nombre_eventos;
        delete item.image_evento;
        delete item.evento;
        delete item.guias;
        delete item.referencia;
        item.cli_nombre = item.cli_nombre + ' ' + item.cli_apellidos;
        delete item.cli_apellidos;
        delete item.cli_email;
        delete item.codigo_cupon;
        delete item.descuento;
        delete item.alta_hora_format;
        delete item.adultos;
        delete item.ninos;
        delete item.ninos_gratis;
        delete item.base_imponible_format;
        delete item.porcentaje_anticipo;
        delete item.nombre_actividades;
        delete item.tipo_slug;
        delete item.porcentaje_anticipo;
        item.total = parseFloat(item.total.replace('.',','));
      }
      // return;
      let nombre:any = 'Reservas del '+moment(fecha_inicio).format('DD-MM-YYYY')+' al '+moment(fecha_fin).format('DD-MM-YYYY');
      this.globals.ExportarDataExcel(nombre, data);
    });
  }
  Nuevo() {
    $('.popover').popover('hide');
    this.globals.passData = '';
    if(moment(this.params_dia.fecha).format('YYYY-MM-DD') != moment().format('YYYY-MM-DD')){
      if(moment()>moment(this.params_dia.fecha)){
        swal({
          title: "Vas a añadir una reserva de un día pasado, ¿deseas continuar?",
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "btn-primary",
          confirmButtonText: "Sí",
          cancelButtonText: "No",
          closeOnConfirm: true
        },() => {
          if ($.NuevaReservaGeneral) $.NuevaReservaGeneral(this.params_dia.fecha);
        });
        return;
      };
    }
    if ($.NuevaReservaGeneral) $.NuevaReservaGeneral(this.params_dia.fecha);
    // this.ngZone.run(() => this.router.navigateByUrl('/reservas/add')).then();
  }
  Editar(item:any) {
    if(this.misreservas) return;
    $('.popover').popover('hide');
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/reservas/edit/'+item.id)).then();
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar reserva?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-reservas-del', {id: item.id,fecha: item.fecha, id_evento: item.id_evento, adultos: item.adultos, ninios: item.ninos, niniosGratis: item.ninos_gratis, sesion: item.sesion, fecha_bd: item.fecha_bd}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.reservas.splice(this.reservas.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  VistaPrevia(item:any) {
    $.modalreservadata = item;
    if ($.modalreservashow) $.modalreservashow();
  }


  CalcularPersonas(adultos,ninos,ninosGratis) {
    return (adultos+ninos+ninosGratis);
  }
  EnviarEmail(item:any, event:any) {
    event.stopPropagation();
    if (!item.cli_email || item.cli_email == '') {
      toastr.info('No se ha guardado email', 'Sin email');
      return;
    }
    swal({
      title: "¿Enviar un correo de confirmación?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-reservas-reenviar-email-confirmacion', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        toastr.success('Email enviado correctamente', 'Email enviado');
      });
    });
  }
  EnviarWhatsapp(item:any, event:any) {
    event.stopPropagation();
    if (!item.cli_telefono || item.cli_telefono == '') {
      toastr.info('No se ha guardado número de teléfono', 'Sin número');
      return;
    }
    let fecha = moment(item.alta).locale('es').format('dddd D MMMM');
    let enlace = document.createElement('a');
    let cliente = item.cli_nombre;
    enlace.href = `https://api.whatsapp.com/send?phone=${item.cli_telefono}`;
    enlace.target = '_blank';
    enlace.click();
  }

  CargarReservasFecha(fecha:any = null) {
    if (!fecha) return;
    $('.btn-sesiones .btn').removeClass('active');
    $('.btn-sesiones .btn-todas').addClass('active');
    if (this.globals.me.id_rol == 4) this.params_dia.id_colaborador = this.globals.me.id;
    this.params_dia.fecha = fecha;
    this.params_dia.fecha_format = 'Día '+moment(fecha).format('DD/MM/YYYY');
    if (moment().format('DD-MM-YYYY') == moment(fecha).format('DD-MM-YYYY')) this.params_dia.fecha_format = 'Hoy';
    if (moment().add(1, 'days').format('DD-MM-YYYY') == moment(fecha).format('DD-MM-YYYY')) this.params_dia.fecha_format = 'Mañana';
    this.params_dia.groupBy = 'fecha';
    if (fecha) this.params_dia.groupBy = '';
    this.params_dia.order = 'nombre_eventos, fecha, sesion';
    this.globals.Api('/eve-reservas', this.params_dia).subscribe(data => {
      if (!data || !data.length) {
        this.sesiones = [];
        this.reservas_dia = [];
        this.reservas_calendario = [];
        globalThis.reservas_dia_original = [];
        this.CargarCalendarioDia();
        return;
      }
      this.reservas_calendario = data;
      setTimeout(() => {
        this.sesiones = [];
        this.reservas_dia.forEach(reserva => {
          this.globals.Api('/eve-actividades',{id: reserva.id_actividad}).subscribe(data =>{
            if (this.sesiones.filter((sesion:any) => sesion.id_evento == reserva.id_evento && sesion.sesion == reserva.sesion).length) return;
            let personas = this.GetTotalPersonasSesion(reserva.sesion, reserva.id_evento);
            let sesion:any = {};
            sesion.id_actividad = reserva.id_actividad;
            sesion.sesion = reserva.sesion;
            sesion.personas = personas;
            if (reserva.aforo_especial) {
              sesion.aforo = reserva.aforo_especial;
              sesion.porcentaje = (sesion.personas * 100)/sesion.aforo;
            } else {
              if(data[0].plazas_asientos > 0 || data[0].plazas_asientos){
                sesion.aforo = data[0].plazas_asientos;
              }else{
                sesion.aforo = reserva.aforo_predeterminado;
              }
              sesion.porcentaje = (sesion.personas * 100)/sesion.aforo;
            }
            sesion.color = reserva.distintivo_color;
            this.sesiones.push(sesion);
          });
        });
      }, 100);
      this.reservas_dia = data.filter(el => el.id_estado <= 3);
      globalThis.reservas_dia_original = data;
      for (let item of this.reservas_dia) {
        // console.log(item.tipo);
        if (item.tipo == null) item.tipo = 'Web';
        item.tipo_slug = this.globals.CleanedString(item.tipo);
      }
      this.appref.tick();
      this.CargarCalendarioDia();
    });
  }
  CargarCalendarioDia() {
    var date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    let that = this;
    var heightCalendar = 425;
    if ($(window).width() <= 1366 && $(window).width() > 1025) {
      heightCalendar = 380;
    }
    if ($(window).width() <= 500) {
      heightCalendar = 340;
    }
    this.width = document.body.clientWidth;
    if (this.width <= 768) $('.collapse').collapse('hide');
    // console.log('Calendario cargado');
    $('#calendar').fullCalendar({
        header: {
            left: 'title',
            center: '',
            right: 'today month prev,next'
        },
        defaultView: 'month',
        defaultDate: new Date(),
        locale: 'es',
        allDaySlot: false,
        editable: false,
        droppable: false,
        selectable: false,
        duration: false,
        contentHeight: heightCalendar,
        viewRender: (view, element) => {
          this.CargarEventosCalendario();
          $('.calendario-listado-reservas .fc-day-top').click(function() {
            $('.calendario-listado-reservas .fc-day-top').css('background-color','unset');
            $('.calendario-listado-reservas .fc-day-top').css('color','unset');
            $(this).css('background-color','#DB0033');
            $(this).css('color','white');
          });
        },
        eventRender: function(event, element) {
        },
        eventClick: function(info) {
        },
        dayClick: (date, allDay, jsEvent, view)=>{
          var tempDate = new Date(date).toDateString();
          let fecha = moment(tempDate).format('YYYY-MM-DD');
          this.sesiones = [];
          this.CargarReservasFecha(fecha);
        }
    });
  }
  CargarEventosCalendario() {
    //console.log('Calendario cargado 2');
    $('#calendar').fullCalendar('removeEvents');
    var calendar = $('#calendar').fullCalendar('getCalendar');
    var view = calendar.view;
    var start = view.start._d;
    var end = view.end._d;
    // let params = {
    //   fecha_inicio: moment(start).format('YYYY-MM-DD'),
    //   fecha_fin: moment(end).format('YYYY-MM-DD')
    // };
    let params;
    if (this.globals.me.id_rol == 4) {
      // params.id_colaborador = this.globals.me.id;
      params = {
        fecha_inicio: moment(start).format('YYYY-MM-DD'),
        fecha_fin: moment(end).format('YYYY-MM-DD'),
        id_colaborador: this.globals.me.id
      };
    } else {
      params = {
        fecha_inicio: moment(start).format('YYYY-MM-DD'),
        fecha_fin: moment(end).format('YYYY-MM-DD')
      };
    }

    this.globals.Api('/eve-reservas', params).subscribe(data => {
      if (!data || !data.length) return;
      let mismaFecha = false;
      let fechaTemp = '';
      let sumPorcentajeMismaFecha = 0;
      let events = [];
      if (this.globals.GetData('eventos-calendario-reservas')) {
        events = this.globals.GetData('eventos-calendario-reservas');
        $('#calendar').fullCalendar('renderEvents', events, true);
      }
      for (let item of data) {
        if (fechaTemp != item.fecha) {
          fechaTemp = item.fecha;
          //sumPorcentajeMismaFecha = item.percentage;
          mismaFecha = false;
        } else {
          mismaFecha = true;
          //sumPorcentajeMismaFecha = (sumPorcentajeMismaFecha + item.percentage) / 2;
        }
        let start = new Date(item.fecha);
        let end = moment(start);
        let color = 'transparent';
        let cursor = 'pointer';
        let textColor = '#FFF';
        let display = 'block';
        let className = 'day-green';
        if (!mismaFecha) {
          //className = 'hide';
        }
        let event = {
          id: item.id,
          className: className,
          //title: `${item.aforo_restante}/${item.aforo_total}`,
          title: '',
          duration: false,
          description: `
            <div style="cursor: ${cursor}">
              <div><b><i class="fa fa-lock" style="display: ${display}; float: left; margin-right: 5px;"> <span style="color: white;"><i class="icofont-check-circled"></i></span></div>
            </div>
          `,
          start: start,
          end: end,
          data: item,
          color: color,
          //color: 'transparent',
          textColor: textColor,
          //textColor: 'transparent',
          disabled: false,
          tipo: 'documento'
        };
        events.push(event);
        // $('#calendar').fullCalendar('renderEvent', event, true);
      }
      $('#calendar').fullCalendar('renderEvents', events, true);
      // $('#calendar').fullCalendar('refetchEvents');
    });
    $('.calendario-listado-reservas .fc-content-skeleton td.fc-today').css('background-color','rgb(219, 0, 51)');
    $('.calendario-listado-reservas .fc-content-skeleton td.fc-today').css('color','white');
    // $('.calendario-listado-reservas td.fc-today').click();
    this.appref.tick();
  }
  mostrarTodasSesiones() {
    this.reservas_dia = globalThis.reservas_dia_original.filter(el => el.id_estado <= 3);
    this.reservas_calendario = globalThis.reservas_dia_original.filter(el => el.id_estado <= 3);
    $('.btn-sesiones .btn').removeClass('active');
    $('.btn-sesiones .btn-todas').addClass('active');
  }
  mostrarSesion(sesion:any, id_evento:any, index) {
    this.reservas_dia = globalThis.reservas_dia_original.filter(el => el.id_estado <= 3 && el.sesion == sesion && el.id_evento == id_evento);
    this.reservas_calendario = globalThis.reservas_dia_original.filter(el => el.id_estado <= 3 && el.sesion == sesion && el.id_evento == id_evento);
    $('.btn-sesiones .btn').removeClass('active');
    $('.btn-sesiones .btn.sesion-'+index).addClass('active');
  }
  GetTotalReservas() {
    let total:number = 0;
    if (!globalThis.reservas_dia_original || !globalThis.reservas_dia_original.length) return 0;
    for (let item of globalThis.reservas_dia_original.filter(el => el.id_estado <= 3)) {total += 1;}
    return total;
  }
  GetTotalPersonas() {
    let total:number = 0;
    if (!globalThis.reservas_dia_original || !globalThis.reservas_dia_original.length) return 0;
    for (let item of globalThis.reservas_dia_original.filter(el => el.id_estado <= 3)) {total += item.personas;}
    return total;
  }
  GetTotalPersonasSesion(sesion:any, id_evento:any) {
    let total:number = 0;
    if (!globalThis.reservas_dia_original || !globalThis.reservas_dia_original.length) return 0;
    for (let item of globalThis.reservas_dia_original.filter(el => el.id_estado <= 3 && el.sesion == sesion && el.id_evento == id_evento)) {total += item.personas;}
    return total;
  }
  GetReservasCanceladas() {
    if (!globalThis.reservas_dia_original || !globalThis.reservas_dia_original.length) return [];
    let reservas = globalThis.reservas_dia_original.filter(el => el.id_estado > 3);
    reservas = reservas.filter(el => el.id_estado != 7);
    return reservas;

    //Función antigua
    //return globalThis.reservas_dia_original.filter(el => el.id_estado > 3);
  }

  //Calendario
  SetTodasActividadesCalendario() {
    for (let item of this.actividades) item.active = null;
    $('#todas-actividades').addClass('active');
    this.params_calendario.actividad = null;
    this.CargarCalendario();
  }
  SetActividadCalendario(item:any) {
    for (let el of this.actividades) el.active = null;
    item.active = true;
    $('#todas-actividades').removeClass('active');
    this.params_calendario.actividad = item.id;
    this.CargarCalendario();
  }
  CargarJSCalendario() {
    var date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    let calendar = $('.calendario-actividades').fullCalendar({
        header: {
          left: 'title',
          center: '',
          right: ($(window).width() < 768 ? 'today agendaDay,listMonth prev,next' : 'today month,agendaWeek,agendaDay,listMonth prev,next')
        },
        defaultView: ($(window).width() < 768 ? 'listMonth' : 'month'),
        defaultDate: new Date(),
        locale: 'es',
        allDaySlot: false,
        editable: false,
        droppable: false,
        selectable: true,
        selectHelper: true,
        contentHeight: 'auto',
        axisFormat: 'HH:mm',
        timeFormat: 'HH:mm',
        slotLabelFormat: 'HH:mm',
        // minTime: '08:00:00',
        // maxTime: '22:00:00',
        viewRender: (view, element) => {
          $('.popover').popover('hide');
          this.params_calendario.fecha_inicio = view.start.format('YYYY-MM-DD');
          this.params_calendario.fecha_fin = view.end.format('YYYY-MM-DD');
          this.CargarCalendario();
        },
        eventRender: (event, element) => {
          element.find('.fc-title').append(`<div class="descripcion-evento">${event.description}</div>`);
          element.find('.fc-list-item-title').append(`<div class="evento-listado">${event.description}</div>`);
          if (element.hasClass('fc-time-grid-event')) element.append(`<div class="evento-listado">${event.description}</div>`);
        },
        eventClick: (info) => {
          $('.popover').popover('hide');
          if (info.disabled) return;
          if (!info.data) return;
          // this.Editar(info.data);
          let data = info.data;
          if (data.popover) {
            data.popover = false;
            return;
          }
          function stripHtml(html) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
          }
          if ($.calevents) for (let item of $.calevents) item.data.popover = false;
          data.popover = true;
          $('#reserva-'+data.id).popover({
            html: true,
            title: `Reserva #${data.referencia}`,
            content: `
              <span class="badge-reserva">
                ${data.tipo == 'Web' ? `<img src="assets/icon-ref-web.svg" />` : ''}
                ${data.tipo == 'Civitatis' ? `<img src="assets/icon-ref-c.svg" />` : ''}
                ${data.tipo == 'Partner' ? `<img src="assets/icon-ref-p.svg" />` : ''}
                ${data.tipo == 'GetYourGuide' ? `<img src="assets/icon-ref-g.svg" />` : ''}
                ${data.tipo == 'Admin' ? `<img src="assets/icon-ref-admin.svg" />` : ''}
                ${data.tipo == 'Airbnb' ? `<img src="assets/icon-ref-airbnb.svg" />` : ''}
              </span>
              <div class="popover-body">
                ${data.image_actividad ? `<img src="${data.image_actividad}">` : ''}
                <div class="titulo">${data.nombre_eventos}</div>
                <div class="fecha">${data.fecha_format ? `<i class="fa fa-calendar text-primary"></i> ${data.fecha_format}` : ''}</div>
                <div class="fecha">${data.sesion ? `<i class="fa fa-clock-o text-primary"></i> ${data.sesion}` : ''}</div>
                <hr>
                <div class="cliente"><i class="fa fa-user text-primary"></i> ${data.cli_nombre} ${data.cli_apellidos}</div>
                <div><i class="fa fa-envelope-o text-primary"></i> ${data.cli_email}</div>
                <div><i class="fa fa-phone text-primary"></i> ${data.cli_telefono}</div>
                <div>Adultos: ${data.adultos} <span class="m-l-20">Niños: ${data.ninos}</span></div>
                <hr>
                <div><i class="fa fa-credit-card text-primary"></i> ${data.cli_tipo_pago}</div>
                <div class="pull-right font-bold">${data.total_format}€</div>
                <div>${data.estado}</div>
              </div>
              <ul class="popover-btn">
                <li><a class="btn btn-primary" onclick="$.EditarReserva('${data.id}')"><i class="fa fa-pencil"></i> Editar reserva</a></li>
                <li><a class="btn btn-secondary" onclick="$.WAReserva('${data.id}')"><i class="fa fa-whatsapp"></i> Enviar Confirmación</a></li>
              </ul>
            `,
            trigger: 'focus',
            placement: 'auto',
            container: 'body'
          }).popover('show');
        }
    });
  }
  CargarCalendario() {
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.7)',
      imageResizeFactor: 0.5,
      text: 'Cargando ...'
    });
    this.params_calendario.fecha_inicio = $('.calendario-actividades').fullCalendar('getView').start.format('YYYY-MM-DD');
    this.params_calendario.fecha_fin = $('.calendario-actividades').fullCalendar('getView').end.format('YYYY-MM-DD');
    if (this.globals.me.id_rol == 4) {
        this.params_calendario.id_colaborador = this.globals.me.id
    }
    this.globals.Api('/eve-reservas', this.params_calendario).subscribe(data => {
      $('.calendario-actividades').fullCalendar('removeEvents');
      if (!data || !data.length) {
        $.LoadingOverlay("hide", true);
        return;
      }
      $.LoadingOverlay("hide", true);
      let events:any = [];
      for (let item of data) {
        let guias = '';
        item.guias.forEach(guia => {
          if (guia) {
            guias += '| '+guia.nombre + ' ' + guia.apellidos;
          }
        });
        if (guias == '') {
          guias = '| Sin asignar';
        }
        let start = new Date(item.fecha_bd+' '+item.sesion+':00');
        let end = moment(start).add(1, 'hours');

        let event = {
          id: item.id,
          title: item.reparacion,
          description: `
            <div id="reserva-${item.id}" class="barra-evento-calendario" style="background-color: ${item.distintivo_color};"></div>
            <div class="evento-calendario">
              ${item.image_actividad ? `<img src="${item.image_actividad}">` : ''}
              <div class="evento-calendario-referencia"><b>#${item.referencia}</b></div>
              <div class="evento-mas-datos">
                <div class="evento-calendario-actividad">${item.nombre_eventos}</div>
                <div class="evento-calendario-sesion">${item.sesion}h</div>
                <div class="evento-calendario-nombre">${item.cli_nombre} ${item.cli_apellidos}</div>
                <div class="evento-calendario-guias">Guías asignados ${guias}</div>
              </div>
            </div>
          `,
          start: start,
          end: end,
          data: item,
          color: '#FEFEFE',
          textColor: '#232323',
          disabled: false
        };
        events.push(event);
      }
      $.calevents = events;
      $('.calendario-actividades').fullCalendar('renderEvents', events, true);
      $.LoadingOverlay("hide", true);
      this.appref.tick();
    }, error => {
      $.LoadingOverlay("hide", true);
      toastr.error(`Se ha producido un error`, 'Error');
    });
  }
  AsignarGuias() {
    this.asignarGuia = true;
    $('#guardar-guias').show();
    $('#asignar-guias').hide();
    $('.content-guias input').show();
    $('.checkbox-actividades').show();
  }
  CheckActividad(evento) {
    let id_evento = evento.id;
    if(!this.asignarGuia){
      this.Editar(evento);
      return;
    }
    if ($('#'+id_evento+' input').css('display') == 'none') return;
    $('#'+id_evento+' input').click();
    if ($('#'+id_evento+' input').is(':checked')) {
      //console.log(true);
      let found = this.actividadesSelect.includes(id_evento);
      if (!found) {
        this.actividadesSelect.push(id_evento);
      }
      //console.log(this.actividadesSelect);
    } else {
      //console.log(false);
      var filtered = this.actividadesSelect.filter(function(value, index, arr) {
        return value != id_evento;
      });
      this.actividadesSelect = filtered;
      //console.log(this.actividadesSelect);
    }
  }
  CheckGuia(id_guia) {
    if ($('#'+id_guia+' input').css('display') == 'none') return;
    $('#'+id_guia+' input').click();
    if ($('#'+id_guia+' input').is(':checked')) {
      let found = this.guiasSelect.includes(id_guia);
      if (!found) {
        this.guiasSelect.push(id_guia);
      }
    } else {
      var filtered = this.guiasSelect.filter(function(value, index, arr) {
        return value != id_guia;
      });
      this.guiasSelect = filtered;
    }
  }
  GuardarGuias() {
    this.asignarGuia = false;
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.7)',
      imageResizeFactor: 0.5,
      text: 'Asignando guías ...'
    });
    let ids_guias = [];
    for(var i = 0; i < this.guiasSelect.length; i++) {
      ids_guias.push({id: this.guiasSelect[i]});
    }
    if (this.actividadesSelect.length == 0) {
      $('#guardar-guias').hide();
      $('#asignar-guias').show();
      $('.content-guias input').hide();
      $('.checkbox-actividades').hide();
      $.LoadingOverlay("hide", true);
      return;
    }
    this.actividadesSelect.forEach(actividad => {
      this.globals.Api('/add-guias-evento', {id: actividad, ids_guias: ids_guias}).subscribe(data => {
        if (!data || data.error) {
          $.LoadingOverlay("hide", true);
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-add-guia').modal('hide');
        let str_ids_guias = '';
        ids_guias.forEach(id_guia => {
          str_ids_guias += (id_guia.id + ',');
        });
        $.LoadingOverlay("hide", true);
        this.CargarReservasFecha(this.params_dia.fecha);
      });
    });

    $('#guardar-guias').hide();
    $('#asignar-guias').show();
    $('.content-guias input').hide();
    $('.checkbox-actividades').hide();

  }
}
